/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum Growth {
  EarlyGrowth="EarlyGrowth",
LateGrowth="LateGrowth",
Growth="Growth"
}

/**
* Growth
*/
export const GrowthEnum = types.enumeration("Growth", [
        "EarlyGrowth",
  "LateGrowth",
  "Growth",
      ])
