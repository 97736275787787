import React, { CSSProperties } from "react";
import { useField } from "formik";
import { ImageUpload } from "../Dumb";
import { CLOUDINARY_UPLOAD_PRESET_COMPANY_LOGO, CLOUDINARY_UPLOAD_URL } from "../../utils/variables";

interface ImageUploadFieldProps {
  name: string;
  title?: string;
  width?: number;
  style?: CSSProperties;
  uploadButtonText?: string;
  replaceButtonText?: string;
}

const ImageUploadField = ({ name, style, ...props }: ImageUploadFieldProps) => {
  const [field, meta, helpers] = useField(name);
  return (
    <div style={style}>
      <ImageUpload
        {...props}
        uploadButtonText={props.uploadButtonText}
        replaceButtonText={props.replaceButtonText}
        error={meta.touched && meta.error}
        cloudinaryPreset={CLOUDINARY_UPLOAD_PRESET_COMPANY_LOGO}
        cloudinaryUrl={CLOUDINARY_UPLOAD_URL}
        image={field.value}
        onImageChange={(image) => helpers.setValue(image)}
      />
    </div>
  );
};

export { ImageUploadField };
