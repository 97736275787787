import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { AnimatedLink, Wrapper, AppTitle } from "../UI";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, SECONDARY_FONT } from "../../utils/theme";
import ReactGA from "react-ga";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ContactUsProps {}

type Props = RouteComponentProps<{
  type: string;
}> &
  ContactUsProps;

const ContactUsComponent: FunctionComponent<Props> = (props) => {
  return (
    <FooterContainer>
      <Wrapper>
        <FooterContent>
          <FooterTitle>GET IN TOUCH</FooterTitle>
          <ContactUsText>
            <p>
              {`Let's work together`}{" "}
              <StyledAnimatedLink
                onClick={(e) => {
                  ReactGA.event({
                    category: "Click",
                    action: "Contact Us clicked",
                  });
                }}
                href="mailto:partners@base10.vc"
              >
                Contact us.
              </StyledAnimatedLink>
            </p>
          </ContactUsText>
        </FooterContent>
      </Wrapper>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  background: #ffffff;
`;

const FooterContent = styled.div`
  float: left;
  margin-left: 125px;
  padding-top: 130px;
  padding-bottom: 130px;
  @media ${mobileSize} {
    float: left;
    margin-left: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const FooterTitle = styled(AppTitle)`
  font-size: 12px;
  font-family: ${SECONDARY_FONT};
  line-height: 1.16667;
  letter-spacing: 1px;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
`;

const ContactUsText = styled(AppTitle)`
  margin-top: 15px;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -1px;
  @media ${mobileSize} {
    margin-top: 11px;
  }
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  margin-top: 15px;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -1px;
  color: ${BLACK_COLOR};
  ::after {
    height: 4px;
  }
`;

const ContactUs = withRouter(ContactUsComponent);

export { ContactUs };
