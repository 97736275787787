import React from "react";
import { useField } from "formik";
import { AppInput, AppInputProps } from "../UI";

interface FormInputFieldProps extends AppInputProps {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
}

const FormInputField = ({ style, ...props }: FormInputFieldProps) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppInput {...field} {...props} error={meta.touched && meta.error} />
    </div>
  );
};

export { FormInputField };
