import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../utils/theme";

interface ReplaceCompanyImageButtonProps {
  src?: string;
  width?: number;
  height?: number;
}

export const ReplaceCompanyImageButton = styled.button<ReplaceCompanyImageButtonProps>`
  width: ${(props) => (!!props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => props.height}px;
  border-radius: 4px;
  background-color: ${(props) => (props.src ? "transparant" : BLACK_COLOR)};
  font-family: ${PRIMARY_FONT};
  font-size: 18px;
  border: solid 1px rgba(26, 26, 26, 0.2);
  background-image: ${(props) => (props.src ? `url(${props.src})` : "black")};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: ${(props) => (props.src ? `transparent` : "white")};
  -webkit-transition: 0.4s, color 0.2s;
  -moz-transition: 0.4s, color 0.2s;
  -o-transition: 0.4s, color 0.2s;
  transition: 0.4s, color 0.2s;
  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    opacity: 0.5;
    color: ${(props) => (props.src ? `white` : "white")};
    background-color: ${PRIMARY_COLOR};
  }
`;

ReplaceCompanyImageButton.defaultProps = {
  height: 50,
};
