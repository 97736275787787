import React, { useEffect, FunctionComponent, useState } from "react";
import styled from "styled-components";
import {
  Modal,
  CloseModal,
  AppTitle,
  AppText,
  AppPanelTitle,
  Wrapper,
  ToolTipContainer,
  AnimatedLink,
  Loading,
} from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, PRIMARY_FONT, PRIMARY_COLOR } from "../../utils/theme";
import ReactTooltip from "react-tooltip";
import { VideoModal } from ".";
import { trackPortfolioClicked } from "../../services/mixpanel";

interface CompanyPortfolioProps {
  showModal: boolean;
  toggleModal: () => void;
  companyId: number;
  displayLimitedInfo?: boolean;
}

const CompanyPortfolioComponent: FunctionComponent<CompanyPortfolioProps> = (props) => {
  const [videoLink, setVideoLink] = useState("");
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const { store } = useQuery();

  const selectedFund = store.selected_fund;

  const selectedFundIsBase10PartnersFundIIOrIII = selectedFund?.id === 13 || selectedFund?.id === 19;

  const { data: companyData, loading: companyLoading, error: companyError, setQuery: companyQuery } = useQuery(
    (store) =>
      store.queryFetchFundraisingAndValuationHistoryGraph({ companyId: props.companyId }, (qb) =>
        qb.max_valuation
          .rounds(
            (round) => round.base10_involved.stage.footnote.valuation.previous_valuation.month_year.total_investment,
          )
          .company((company) =>
            company.logo_url.headquarters.founded_date.headline.industry_text.geo_focus.business_model.invested_date.founder_quote.founder_photo_url.founder_name.founder_position.name.industry_text.website.about
              .coinvestors((inv) => inv.name)
              .competitors((comp) => comp.name)
              .key_team_members((mem) => mem.name.role.url)
              .related_news((news) => news.url.title)
              .related_videos((vid) => vid.url.image_url.title),
          ),
      ),
  );

  useEffect(() => {
    const query = store.queryFetchFundraisingAndValuationHistoryGraph({ companyId: props.companyId }, (qb) =>
      qb.max_valuation
        .rounds(
          (round) => round.base10_involved.stage.footnote.valuation.previous_valuation.month_year.total_investment,
        )
        .company((company) =>
          company.logo_url.headquarters.founded_date.invested_date.headline.geo_focus.business_model.customer_text.founder_quote.founder_photo_url.founder_name.founder_position.name.industry_text.website.about.primary_industry_string.primary_trend_string
            .coinvestors((inv) => inv.name)
            .competitors((comp) => comp.name)
            .key_team_members((mem) => mem.name.role.url)
            .related_news((news) => news.url.title)
            .related_videos((vid) => vid.url.image_url.title),
        ),
    );
    companyQuery(query);
  }, [props.companyId]);

  useEffect(() => {
    const name = companyData?.fetchFundraisingAndValuationHistoryGraph?.company?.name;
    if (name) {
      trackPortfolioClicked(name);
    }
  }, [companyData]);

  if (companyLoading) {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        backgroundColor={BLACK_COLOR}
        showModal={props.showModal}
        toggleModal={props.toggleModal}
      >
        <Loading />
      </Modal>
    );
  }
  if (companyError) {
    return <p>Error </p>;
  }
  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const {
    fetchFundraisingAndValuationHistoryGraph: { company, rounds, max_valuation },
  } = companyData;

  return (
    <Modal
      height="fit-content"
      width="fit-content"
      backgroundColor={BLACK_COLOR}
      showModal={props.showModal}
      toggleModal={props.toggleModal}
    >
      <CloseModal onClick={props.toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <VideoModal toggleModal={toggleVideoModal} showModal={videoModalVisible} url={videoLink} />
      <PortfolioContainer>
        {/* <PortfolioModalWrapper> */}
        <Wrapper>
          <PortfolioInnerWrapper>
            <ProfileBox>
              <ProfileSiderBar>
                <ProfileSideBarWrapper>
                  <ProfileSideBarLogoContainer>
                    <ProfileSideBarLogo src={company.logo_url} />
                  </ProfileSideBarLogoContainer>
                  <ProfileSideContentContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>HEADQUARTERS</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.headquarters}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>FOUNDED</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.founded_date}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>INVESTED</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.invested_date}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>BUSINESS MODEL</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.business_model || "N/A"}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>CUSTOMER</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.customer_text || "N/A"}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>GEO FOCUS</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.geo_focus || "N/A"}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>
                    <ProfileSideBarContentItemContainer>
                      <ProfileSideBarTitle>TREND</ProfileSideBarTitle>
                      <ProfileSideBarText>{`${company.industry_text || "N/A"}`}</ProfileSideBarText>
                    </ProfileSideBarContentItemContainer>

                    {!props.displayLimitedInfo && (
                      <>
                        {!selectedFundIsBase10PartnersFundIIOrIII && (
                          <>
                            <ProfileSideBarContentItemContainer>
                              <ProfileSideBarTitle>COINVESTORS</ProfileSideBarTitle>
                              {company.coinvestors.map((coinvestor) => {
                                return <ProfileSideBarText key={coinvestor.name}>{coinvestor.name}</ProfileSideBarText>;
                              })}
                            </ProfileSideBarContentItemContainer>

                            {/* <ProfileSideBarContentItemContainer>
                              <ProfileSideBarTitle>COMPETITORS</ProfileSideBarTitle>
                              {company.competitors.map((competitor) => {
                                return <ProfileSideBarText key={competitor.name}>{competitor.name}</ProfileSideBarText>;
                              })}
                            </ProfileSideBarContentItemContainer> */}
                          </>
                        )}
                        <ProfileSideBarContentItemContainer>
                          <QuoteContainer>
                            {company?.founder_quote && (
                              <ProfileSideBarText>{`"${company.founder_quote}"`}</ProfileSideBarText>
                            )}

                            <QuoteImageContainer>
                              <QuoteImage
                                src={companyData.fetchFundraisingAndValuationHistoryGraph.company.founder_photo_url}
                              />
                            </QuoteImageContainer>
                            <ProfileSideBarContentItemContainer>
                              <AppText>
                                {companyData.fetchFundraisingAndValuationHistoryGraph.company.founder_name}
                              </AppText>
                              <MemberRole>{`  - ${company.founder_position}`}</MemberRole>
                            </ProfileSideBarContentItemContainer>
                          </QuoteContainer>
                        </ProfileSideBarContentItemContainer>
                      </>
                    )}
                  </ProfileSideContentContainer>
                </ProfileSideBarWrapper>
              </ProfileSiderBar>
              <ProfileContent>
                {company.headline ? (
                  <StyledTitle>{company.headline}</StyledTitle>
                ) : (
                  <StyledTitle>
                    <BoldSpan>{`${company.name}`}</BoldSpan> is automating{" "}
                    <BoldSpan>{company.primary_trend_string}</BoldSpan> in the{" "}
                    <BoldSpan>{company.primary_industry_string}</BoldSpan> industry.
                  </StyledTitle>
                )}
                <ProfileLinkContainer>
                  {/* replace animatedtex with animatedlink from another branch */}
                  <StyledAnimatedLink
                    href={companyData.fetchFundraisingAndValuationHistoryGraph.company.website}
                    target="_blank"
                  >
                    Visit website
                  </StyledAnimatedLink>
                </ProfileLinkContainer>
                <MobileSideBarVisible>
                  <MobileContentInfoItem>
                    <ProfileSideBarTitle>HEADQUARTERS</ProfileSideBarTitle>
                    <ProfileSideBarText>{`${company.headquarters}`}</ProfileSideBarText>
                  </MobileContentInfoItem>
                  <MobileContentInfoItem>
                    <ProfileSideBarTitle>FOUNDED</ProfileSideBarTitle>
                    <ProfileSideBarText>{`${company.founded_date}`}</ProfileSideBarText>
                  </MobileContentInfoItem>
                  <MobileContentInfoItem>
                    <ProfileSideBarTitle>INVESTED</ProfileSideBarTitle>
                    <ProfileSideBarText>{`${company.invested_date}`}</ProfileSideBarText>
                  </MobileContentInfoItem>
                </MobileSideBarVisible>
                <ProfileBioSections>
                  <ProfileContentLabel>About</ProfileContentLabel>
                  <div>{`${company.about}`}</div>
                </ProfileBioSections>
                {!props.displayLimitedInfo && (
                  <>
                    <ProfileBioSections>
                      <ProfileContentLabel>FUNDRAISING AND VALUATION HISTORY ($M)</ProfileContentLabel>
                      <ProfileContentTip>
                        Circles indicate post-money valuation.
                        <br />
                        Hover the mouse over circles to see amount raised and series.
                      </ProfileContentTip>
                      <LineChartContainer>
                        <LineChartWrapper>
                          <LineChartLegendContainer>
                            <LineChartLegendItem>
                              <LineChartLegendInnerItem>
                                <LineChartLegendColorContainer>
                                  <LineChartLegendColor base10={false} />
                                </LineChartLegendColorContainer>
                                <LineChartLegendLabelContainer>Funding Round</LineChartLegendLabelContainer>
                              </LineChartLegendInnerItem>
                            </LineChartLegendItem>
                            <LineChartLegendItem>
                              <LineChartLegendInnerItem>
                                <LineChartLegendColorContainer>
                                  <LineChartLegendColor base10={true} />
                                </LineChartLegendColorContainer>
                                <LineChartLegendLabelContainer>Base10 investment</LineChartLegendLabelContainer>
                              </LineChartLegendInnerItem>
                            </LineChartLegendItem>
                          </LineChartLegendContainer>
                          <GraphContainer>
                            {rounds.map((investment: any, index) => {
                              return (
                                <GraphItem key={investment.stage}>
                                  {index === 0 ? (
                                    <GraphItemContent data-tip data-for={`seed_round`}>
                                      <EmptyCircle />
                                      <ReactTooltip id={`seed_round`} effect="solid">
                                        <StyledToolTipContainer>
                                          <ToolTipTitle>Founded</ToolTipTitle>
                                        </StyledToolTipContainer>
                                      </ReactTooltip>
                                    </GraphItemContent>
                                  ) : (
                                    <GraphItemContent data-tip data-for={`portfolio_investment_${index}`}>
                                      <StyledSVGLine preserveAspectRatio="none" viewBox="0 0 100 100">
                                        <StrokeLine
                                          x1="0"
                                          y1={`${
                                            ((max_valuation - investment.previous_valuation) / max_valuation) * 100
                                          }`}
                                          x2="100"
                                          y2={((max_valuation - investment.valuation) / max_valuation) * 100}
                                        />
                                      </StyledSVGLine>
                                      <Circle
                                        height={((max_valuation - investment.valuation) / max_valuation) * 100}
                                        base10={investment.base10_involved}
                                      >
                                        <CircleText>${investment.valuation}</CircleText>
                                      </Circle>
                                      <ReactTooltip id={`portfolio_investment_${index}`} effect="solid">
                                        <StyledToolTipContainer>
                                          <HeadingTitle>${investment.total_investment}</HeadingTitle>
                                          <ToolTipTitle>{investment.stage}</ToolTipTitle>
                                          <ToolTipFooter>{investment.footnote}</ToolTipFooter>
                                        </StyledToolTipContainer>
                                      </ReactTooltip>
                                    </GraphItemContent>
                                  )}
                                </GraphItem>
                              );
                            })}
                          </GraphContainer>
                          <GraphLabels>
                            {rounds.map((investment: any) => {
                              return (
                                <GraphLabelItem key={investment.month_year}>{investment.month_year}</GraphLabelItem>
                              );
                            })}
                          </GraphLabels>
                        </LineChartWrapper>
                      </LineChartContainer>
                    </ProfileBioSections>
                    <ProfileBioSections>
                      <ProfileContentLabel>KEY TEAM MEMBERS </ProfileContentLabel>
                      <ProfileList>
                        {company.key_team_members.map((member) => {
                          return (
                            <MemberListItem key={member.name}>
                              <StyledAnimatedLink
                                href={member.url}
                                target="_blank"
                              >{`${member.name} `}</StyledAnimatedLink>
                              <MemberRole>{`  - ${member.role}`}</MemberRole>
                            </MemberListItem>
                          );
                        })}
                      </ProfileList>
                    </ProfileBioSections>
                    {company.related_videos.length !== 0 && (
                      <ProfileBioSections>
                        <ProfileContentLabel>RELATED VIDEOS</ProfileContentLabel>
                        <VideoListContainer>
                          <VideoList>
                            {company.related_videos.map((video) => {
                              return (
                                <VideoListItem key={video.url}>
                                  <VideoThumbnailContainer>
                                    <VideoThumbnailOverlay
                                      onClick={() => {
                                        toggleVideoModal();
                                        setVideoLink(video.url);
                                      }}
                                    />
                                    <VideoThumbnailImage src={video.image_url} />
                                  </VideoThumbnailContainer>
                                  <VideoTitle>{video.title}</VideoTitle>
                                </VideoListItem>
                              );
                            })}
                          </VideoList>
                        </VideoListContainer>
                      </ProfileBioSections>
                    )}
                    {company.related_news.length !== 0 && (
                      <ProfileBioSections>
                        <ProfileContentLabel>LATEST NEWS</ProfileContentLabel>
                        <ProfileList>
                          {company.related_news.map((news) => {
                            return (
                              <NewsListItem key={news.url}>
                                <NewsListItemContent>
                                  {/* <NewsListItemDate>Sept 12, 2019</NewsListItemDate> */}
                                  <NewsListItemTitle>
                                    <StyledAnimatedLink href={news.url} target="_blank">
                                      {news.title}
                                    </StyledAnimatedLink>
                                  </NewsListItemTitle>
                                </NewsListItemContent>
                              </NewsListItem>
                            );
                          })}
                        </ProfileList>
                      </ProfileBioSections>
                    )}
                  </>
                )}
              </ProfileContent>
            </ProfileBox>
          </PortfolioInnerWrapper>
        </Wrapper>
        {/* </PortfolioModalWrapper> */}
      </PortfolioContainer>
    </Modal>
  );
};

const PortfolioContainer = styled.div`
  position: absolute;
  top: 240px;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  @media ${mobileSize} {
    top: 0;
  }
`;

const PortfolioInnerWrapper = styled.div`
  width: calc(100% - 60px);
  float: left;
  margin-left: 30px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #fff;
  @media ${mobileSize} {
    width: auto;
    margin-left: 0;
    padding: 20px 20px 80px;
  }
`;

const ProfileBox = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px);
  text-align: left;
  @media ${mobileSize} {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
`;

const ProfileSiderBar = styled.div`
  position: relative;
  width: calc(33.33333% - 40px);
  float: left;
  margin-left: 30px;
  min-height: 1px;
  @media ${mobileSize} {
    display: none !important;
  }
`;

const ProfileSideBarWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px);
`;

const ProfileSideBarLogoContainer = styled.div`
  position: absolute;
  top: -150px;
  width: calc(75% - 52px);
  margin-left: 30px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  display: inline-block;
  vertical-align: middle;
`;

const QuoteContainer = styled.div`
  margin-top: 60px;
`;

const QuoteImageContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin-top: 24px;
`;

const QuoteImage = styled.img`
  display: block;
  width: 100%;
`;

const ProfileSideBarLogo = styled.img`
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 120px;
`;

const ProfileSideBarContentItemContainer = styled.div`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0;
`;

const ProfileSideContentContainer = styled.div`
    width: calc(75% - 52px);
    float: left;
    margin-left: calc(25% - 38px + 60px);
    ${ProfileSideBarContentItemContainer}+${ProfileSideBarContentItemContainer} {
      margin-top: 24px;
    }
`;

const MobileContentInfoItem = styled.div`
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  @media screen {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
`;

const ProfileSideBarTitle = styled(AppPanelTitle)`
  margin-top: 0;
  margin-bottom: 7px;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 1px;
`;

const ProfileSideBarText = styled(AppText)`
  font-weight: 300;
  margin-bottom: 5px;
`;

const MobileSideBarVisible = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block !important;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    margin-top: 19px;
  }
  ${MobileContentInfoItem}:first-child {
    @media ${mobileSize} {
      width: calc(50% - 30px);
      float: left;
      margin-left: 20px;
    }
  }
`;

const ProfileList = styled.ul`
  padding: 0;
  list-style: none;
`;
const MemberListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MemberRole = styled(AppText)`
  color: rgba(26, 26, 26, 0.4);
`;

const ProfileContent = styled.div`
  width: calc(58.33333% - 48px);
  float: left;
  margin-left: 30px;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 40px;
  }
`;

const ProfileContentLabel = styled(AppPanelTitle)`
  margin-bottom: 19px;
  @media ${mobileSize} {
    margin-bottom: 19px;
  }
`;

const ProfileContentTip = styled(AppText)`
  margin-top: -14px;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
`;

const BoldSpan = styled.span`
  font-weight: 600;
`;

const StyledTitle = styled(AppTitle)`
  margin-top: 0;
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -1px;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 1.125;
  }
`;

const ProfileLinkContainer = styled.div`
  margin-top: 42px;
  @media ${mobileSize} {
    margin-top: 30px;
  }
`;

const ProfileBioSections = styled.div`
  font-family: ${PRIMARY_FONT};
  margin-top: 42px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0;
  @media ${mobileSize} {
    margin-top: 29px;
    font-size: 18px;
    line-height: 1.33333;
    letter-spacing: 0;
  }
`;

const NewsListItem = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const NewsListItemContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const NewsListItemTitle = styled.div`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  padding-bottom: 1px;
`;

const LineChartContainer = styled.div`
  display: block;
  height: 290px;
`;

const LineChartWrapper = styled.div`
  margin-top: 30px;
  margin-right: -15px;
  margin-left: -15px;
`;

const LineChartLegendContainer = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
`;

const LineChartLegendItem = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  margin-right: 16px;
`;

const LineChartLegendInnerItem = styled.div`
  display: table;
  width: 100%;
`;

const LineChartLegendColorContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  width: 10px;
  padding-top: 2px;
`;
interface Base10 {
  base10: boolean;
}

const LineChartLegendColor = styled.div<Base10>`
  background-color: ${PRIMARY_COLOR};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  box-shadow: ${(props) => (props.base10 ? `inset 0 0 0 2px ${BLACK_COLOR}` : "none")};
`;

const LineChartLegendLabelContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  height: 32px;
  max-height: 32px;
  padding-left: 10px;
  font-size: 12px;
  line-height: 1.25;
  color: rgba(26, 26, 26, 0.8);
`;

const GraphContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 30px;
  padding-left: 0;
  padding-bottom: 30px;
`;

const GraphItem = styled.div`
  display: table-cell;
  text-align: center;
`;

const GraphItemContent = styled.div`
  position: relative;
  height: 138px;
`;

interface GraphProps {
  height: number;
  base10?: boolean;
  previousHeight?: number;
}

const EmptyCircle = styled.div`
  position: absolute;
  left: 50%;
  z-index: 2;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${PRIMARY_COLOR};
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 100%;
`;

const Circle = styled.div<GraphProps>`
  position: absolute;
  left: 50%;
  z-index: 2;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: ${PRIMARY_COLOR};
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: ${(props) => (props.base10 ? `inset 0 0 0 2px ${BLACK_COLOR}` : "none")};
  top: ${(props) => `${props.height}%`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleText = styled(AppText)`
  color: #ffffff;
  font-size: 16px;
`;

const StyledSVGLine = styled.svg`
  position: relative;
  left: 0;
  z-index: 1;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const StrokeLine = styled.line`
  stroke: ${PRIMARY_COLOR};
  stroke-width: 2px;
`;

const GraphLabels = styled.ul`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 15px;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
`;

const GraphLabelItem = styled.li`
  display: table-cell;
  border-top: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 15px;
  padding-top: 7px;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
  text-align: center;
`;

const StyledToolTipContainer = styled(ToolTipContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
`;

const ToolTipTitle = styled(AppText)`
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
`;

const HeadingTitle = styled(AppTitle)`
  font-weight: 300;
  font-size: 28px;
  line-height: 1.28571;
  margin: 0;
`;

const ToolTipFooter = styled.div`
  margin-top: 21px;
  font-size: 12px;
  line-height: 1.25;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
`;

const VideoListContainer = styled.div``;

const VideoListItem = styled.li`
  display: list-item;
  width: calc(50% - 30px);
  margin-right: 15px;
  margin-left: 15px;
`;

const VideoList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-right: -15px;
  margin-left: -15px;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  ${VideoListItem}:nth-child(n+3) {
    margin-top: 30px;
  }
`;

const VideoThumbnailContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const VideoThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: background-color 0.25s;
  transition: background-color 0.25s;
  :hover {
    background-color: rgba(255, 255, 255, 0.6);
  }
  ::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border: 3px solid rgba(26, 26, 26, 0.6);
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: border-color 0.25s;
    transition: border-color 0.25s;
    height: 40px;
    width: 40px;
    border-width: 2px;
    :hover {
      border-color: ${BLACK_COLOR};
    }
  }
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent transparent rgba(26, 26, 26, 0.6);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: border-left-color 0.25s;
    transition: border-left-color 0.25s;
    border-width: 9px 0 9px 18px;
    margin-left: 2px;
    :hover {
      border-left-color: #1a1a1a;
    }
  }
`;

const VideoThumbnailImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
  border-style: none;
  :hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

const VideoTitle = styled(AppTitle)`
  margin-top: 11px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0;
  font-weight: 300;
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  color: ${BLACK_COLOR};
  font-family: ${PRIMARY_FONT};
  font-size: 20px;
  @media screen {
    font-size: 18px;
  }
`;

const CompanyPortfolioModal = observer(CompanyPortfolioComponent);

export { CompanyPortfolioModal };
