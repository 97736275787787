import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Modal, CloseModal, Wrapper } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { BLACK_COLOR } from "../../utils/theme";
import ReactPlayer from "react-player";

interface VideoModalProps {
  showModal: boolean;
  toggleModal: () => void;
  url: string;
}

const VideoModal: FunctionComponent<VideoModalProps> = (props) => {
  return (
    <Modal
      height="fit-content"
      width="fit-content"
      backgroundColor={BLACK_COLOR}
      showModal={props.showModal}
      toggleModal={props.toggleModal}
    >
      <CloseModal onClick={props.toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <VideoContainer>
        <VideoWrapper>
          <Wrapper>
            <InnerWrapper>
              <VideoPlayerContainer>
                <ReactPlayer
                  controls={true}
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: true,
                      },
                    },
                  }}
                  url={props.url}
                />
              </VideoPlayerContainer>
            </InnerWrapper>
          </Wrapper>
        </VideoWrapper>
      </VideoContainer>
    </Modal>
  );
};

export { VideoModal };

const VideoContainer = styled.div`
  position: absolute;
  top: 15rem;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const VideoWrapper = styled.div`
  position: relative;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
`;

const InnerWrapper = styled.div`
  width: calc(100% - 3.75rem);
  float: left;
  margin-left: 1.875rem;
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${BLACK_COLOR};
`;

const VideoPlayerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
