import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { Modal, CloseModal, AppTitle, AppButton, AnimatedText, Loading, AppErrorText } from "../../UI";
import { AiOutlineClose } from "react-icons/ai";
import { BLACK_COLOR, SECONDARY_FONT } from "../../../utils/theme";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import chart1 from "../../../data/chart1.png";

interface AddIndustryModalProps {
  showModal: boolean;
  toggleModal: () => void;
}

const ImageExpanedModalComponent: FunctionComponent<AddIndustryModalProps> = ({ showModal, toggleModal }) => {
  return (
    <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
      <CloseModal onClick={toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <AddIndustryContainer>
        <ModalWrapper>
          <ChartImg src={chart1} />
        </ModalWrapper>
      </AddIndustryContainer>
    </Modal>
  );
};

export const ModalWrapper = styled.div`
  width: 90vw;
  /* width: 90vw; */
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ChartImg = styled.img`
  width: 90vw;
  height: auto;
`;

const AddIndustryContainer = styled.div`
  width: 95vw;
  overflow: hidden;
`;

const ImageExpanedModal = observer(ImageExpanedModalComponent);

export { ImageExpanedModal };
