/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum SortOrder {
  asc="asc",
desc="desc"
}

/**
* SortOrder
*/
export const SortOrderEnum = types.enumeration("SortOrder", [
        "asc",
  "desc",
      ])
