import styled, { keyframes } from "styled-components";
import { PRIMARY_COLOR } from "../../utils/theme";

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

export const AnimatedLink = styled.a`
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  border-bottom: 0;
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: 10;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;
