import { createGlobalStyle } from "styled-components";

import ApercuMono from "./Apercu-Mono.otf";
import CircularStdBlack from "./CircularStd-Black.otf";
import CircularStdBlackItalic from "./CircularStd-BlackItalic.otf";
import CircularStdBold from "./CircularStd-Bold.otf";
import CircularStdBoldItalic from "./CircularStd-BoldItalic.otf";
import CircularStdBook from "./CircularStd-Book.otf";
import CircularStdBookItalic from "./CircularStd-BookItalic.otf";
import CircularStdMedium from "./CircularStd-Medium.otf";
import CircularStdMediumItalic from "./CircularStd-MediumItalic.otf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Apercu-Mono';
        src: local('Apercu-Mono'), url(${ApercuMono}) format('opentype');
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBook}) format('opentype');
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBookItalic}) format('opentype');
        font-style: italic;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdMedium}) format('opentype');
        font-weight: 500;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdMediumItalic}) format('opentype');
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBlack}) format('opentype');
        font-weight: 600;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBlackItalic}) format('opentype');
        font-style: italic;
        font-weight: 600;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBold}) format('opentype');
        font-weight: bold;
    }
    @font-face {
        font-family: 'CircularStd';
        src: local('CircularStd'), url(${CircularStdBoldItalic}) format('opentype');
        font-weight: bold;
        font-style: italic;
    }
`;
