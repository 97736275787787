import Prismic from "prismic-javascript";
import { Date, Link, RichText } from "prismic-reactjs";
import { PRISMIC_BASE_URL, PRISMIC_ACCESS_TOKEN } from "../utils/variables";

const prismicClient = Prismic.client(PRISMIC_BASE_URL, {
  accessToken: PRISMIC_ACCESS_TOKEN,
});

interface PrismicItem {
  type:
    | "paragraph"
    | "heading1"
    | "heading2"
    | "heading3"
    | "heading4"
    | "heading5"
    | "paragraph"
    | "list-item"
    | "image"
    | "o-list-item";
  text?: string;
  image?: string;
}

export { prismicClient, Prismic, Date, Link, RichText };
