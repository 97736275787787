export const PRIMARY_COLOR = "#45aeeb";
export const SECONDARY_COLOR = "#58b6ed";
export const BACKGROUND_COLOR = "#fff";
export const BLACK_COLOR = "#1a1a1a";
export const WHITE_COLOR = "#fff";
export const RED_COLOR = "red";
export const GREY_COLOR = "grey";
export const OFF_WHITE_BACKGROUND = "#f7f9fa";
export const DARK_BLUE_COLOR = "#2e709d";

export const PRIMARY_FONT = "CircularStd";
export const SECONDARY_FONT = "Apercu-Mono";
