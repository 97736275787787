import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { PRIMARY_COLOR, BLACK_COLOR, RED_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "../../utils/theme";

interface OptionItem {
  label: string;
  value: number | string;
  data?: any;
}

export interface AppSelectProps {
  /**
   * If true, allows the user to select the placeholder after selecting another item.
   */
  allowSelectPlaceholder?: boolean;
  /**
   * Indicates an error strong
   */
  error?: string;
  /**
   * A list of options that can be selected.
   */
  options: OptionItem[];
  /**
   * True if placeholder is currently selected. Determines opacity
   */
  isPlaceholder?: boolean;
  fontSize?: number;
  width?: number;
  /**
   * By default, the placeholder has a lower opacity. This parameter overrides that.
   */
  ignorePlaceholderOpacity?: boolean;
  /**
   * If true, do not render underline
   */
  noUnderline?: boolean;
}

export const StyledAppSelect = styled.select<AppSelectProps & React.HTMLProps<HTMLInputElement>>`
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  height: 50px;
  padding-left: 5px;
  padding-right: 24px;
  border-width: 0;
  border-color: transparent;
  background-color: transparent;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-bottom-color: ${(props) => (props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  border-bottom-width: ${(props) => (props.noUnderline ? 0 : 2)}px;
  opacity: ${(props) => (props.isPlaceholder ? (props.ignorePlaceholderOpacity ? 1.0 : 0.4) : 1.0)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "36")}px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  box-sizing: border-box;
  color: ${(props) => (props.color ? props.color : BLACK_COLOR)};
  caret-color: ${PRIMARY_COLOR};
  :focus {
    border-bottom-color: ${PRIMARY_COLOR};
    outline: none;
  }
  ::placeholder {
    opacity: 0.4;
  }
  option {
    color: ${BLACK_COLOR};
  }
`;

StyledAppSelect.defaultProps = {
  ignorePlaceholderOpacity: false,
  noUnderline: false,
  allowSelectPlaceholder: false,
};

export const AppSelect = ({ title, error, options, ...props }: AppSelectProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const [isPlaceholder, setIsPlaceholder] = useState(true);
  const toggleFocus = () => setFocus(!isFocused);
  const onChangeOverride = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    //@ts-ignore
    setIsPlaceholder(!event.target.value);
    !!props.onChange && props.onChange(event);
  };
  return (
    <Div width={props.width}>
      {!!title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      <IconContainer>
        <FaAngleDown size={24} color={PRIMARY_COLOR} />
      </IconContainer>
      <StyledAppSelect
        {...(props as any)}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        title={title}
        error={error}
        isPlaceholder={props.value === 0 || props.value === "0"}
        onChange={onChangeOverride}
      >
        <>
          <option
            selected
            onClick={() => setIsPlaceholder(true)}
            value={0}
            key="placeholder-base10"
            disabled={!props.allowSelectPlaceholder}
          >
            {props.placeholder}
          </option>
          {options.map((o) => (
            <option value={o.value} key={o.value} onClick={() => setIsPlaceholder(true)}>
              {o.label}
            </option>
          ))}
        </>
      </StyledAppSelect>
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

interface DivProps {
  width?: number;
}

const Div = styled.div<DivProps>`
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  position: relative;
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 24px;
`;

// TODO: Add font to theme

const InputTitle = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const InputErrorText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${RED_COLOR};
  margin: 0;
`;
