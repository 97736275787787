import React, { SFC, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { AppText, Loading } from "../../UI";
import { SmartListComponent } from "../../Smart";
import { ModalContext } from "../../../context";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";

interface Props {
  fund_id: number;
}

const TrendComponent: SFC<Props> = ({ fund_id }) => {
  const { toggleTrendModal, addTrendModalVisible } = useContext(ModalContext);
  const {
    store: { trends, trendsError, trendsLoading, fetchTrends },
  } = useQuery();

  useEffect(() => {
    fetchTrends();
  }, [fund_id]);

  if (trendsLoading) {
    return <Loading />;
  }
  if (trendsError) {
    return <p>Could not fetch trends</p>;
  }
  return (
    <TrendContainer modalActive={addTrendModalVisible}>
      <TrendHeader>
        <TrendHeaderTitle>{`${trends.length} Trends`}</TrendHeaderTitle>
        <AddTrend onClick={toggleTrendModal}>+ Add Trend</AddTrend>
      </TrendHeader>
      <ListContainer>
        {!!trends.length ? (
          <SmartListComponent type={"trend"} data={trends} />
        ) : (
          <NoTrendNoticeContainer>
            <NoTrendNoticeText>{`You haven't created trends for this fund yet. Start creating now!`}</NoTrendNoticeText>
          </NoTrendNoticeContainer>
        )}
      </ListContainer>
    </TrendContainer>
  );
};

interface ContainerState {
  modalActive: boolean;
}

const TrendContainer = styled.div<ContainerState>`
  min-height: 500px;
  overflow: ${(props) => (props.modalActive ? "hidden" : "visible")};
  height: ${(props) => (props.modalActive ? "80vh" : "100%")};
`;

const TrendHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TrendHeaderTitle = styled(AppText)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const AddTrend = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  position: relative;
  cursor: pointer;
  border-bottom: none;
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    /* margin-top: 0.1875rem; */
    background-color: #45aeeb;
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  min-height: 133px;
  display: flex;
  justify-content: center;
`;

const NoTrendNoticeContainer = styled.div`
  width: 550px;
`;

const NoTrendNoticeText = styled(AppText)`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.4;
  text-align: center;
`;

const Trend = observer(TrendComponent);

export { Trend };
