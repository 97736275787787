/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum UrlType {
  LINK="LINK",
PDF="PDF",
VIDEO="VIDEO"
}

/**
* UrlType
*/
export const UrlTypeEnum = types.enumeration("UrlType", [
        "LINK",
  "PDF",
  "VIDEO",
      ])
