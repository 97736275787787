import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { CompanyListComponent, ResearchListComponent, TrendListComponent } from "../Dumb";
import { BACKGROUND_COLOR } from "../../utils/theme";
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from "react-sortable-hoc";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { MdMenu } from "react-icons/md";

interface SmartListComponentProps {
  data: any[];
  type: string;
}

const SmartList: FunctionComponent<SmartListComponentProps> = (props) => {
  const [stateData, setStateData] = useState(props.data);
  const { store } = useQuery();
  const [count, setCount] = useState(0);

  const DragHandle = SortableHandle(() => (
    <DragableSpan>
      <MdMenu size={24} color="rgba(0,0,0,0.6)" />
    </DragableSpan>
  ));

  useEffect(() => {
    if (props.type !== "company") {
      return;
    }
    // short term hack
    if (count === 0) {
      setCount(1);
      return;
    }
    const CompanyIds = stateData.map((company) => company.id);
    store.mutateSortCompanyOrdinal({ objectIds: CompanyIds });
  }, [stateData]);

  useEffect(() => {
    if (props.type !== "research") {
      return;
    }
    // short term hack
    if (count === 0) {
      setCount(1);
      return;
    }
    const objectIds = stateData.map((industry) => industry.id);
    store.mutateSortIndustryOrdinal({ objectIds });
  }, [stateData]);

  useEffect(() => {
    if (props.type !== "trend") {
      return;
    }
    // short term hack
    if (count === 0) {
      setCount(1);
      return;
    }
    const objectIds = stateData.map((trend) => trend.id);
    store.mutateSortTrendOrdinal({ objectIds });
  }, [stateData]);

  const SortableCompanyItem = SortableElement((props: any) => {
    return (
      <CompanyDiv key={`company_${props.data.name}`}>
        <DragHandle />
        <CompanyListComponent data={props.data} />
      </CompanyDiv>
    );
  });

  const SortableTrendItem = SortableElement((props: any) => {
    return (
      <CompanyDiv key={`trend_${props.data.name}`}>
        <DragHandle />
        <TrendListComponent data={props.data} />
      </CompanyDiv>
    );
  });

  const SortableIndustryItem = SortableElement((props: any) => {
    return (
      <CompanyDiv key={`industry_${props.data.name}`}>
        <DragHandle />
        <ResearchListComponent data={props.data} />
      </CompanyDiv>
    );
  });

  const SortableCompanyList = SortableContainer((props: any) => (
    <div>
      {props.data.map((item, index) => {
        return <SortableCompanyItem key={item.id} data={item} index={index} style={{ zIndex: 100000 }} />;
      })}
    </div>
  ));

  const SortableIndustryList = SortableContainer((props: any) => (
    <div>
      {props.data.map((item, index) => {
        return <SortableIndustryItem key={item.id} data={item} index={index} style={{ zIndex: 100000 }} />;
      })}
    </div>
  ));

  const SortableTrendList = SortableContainer((props: any) => {
    return (
      <div>
        {props.data.map((item, index) => {
          return <SortableTrendItem key={item.id} data={item} index={index} style={{ zIndex: 100000 }} />;
        })}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    setStateData(arrayMove(stateData, oldIndex, newIndex));
  };

  const renderListItem = (type: string, data: any) => {
    switch (type) {
      case "company":
        return <SortableCompanyList data={stateData} type={type} onSortEnd={onSortEnd} useDragHandle={true} />;
      case "research":
        return <SortableIndustryList data={stateData} type={type} onSortEnd={onSortEnd} useDragHandle={true} />;
      case "trend":
        return <SortableTrendList data={stateData} type={type} onSortEnd={onSortEnd} useDragHandle={true} />;
      default:
        return <SortableCompanyList data={stateData} type={type} onSortEnd={onSortEnd} useDragHandle={true} />;
    }
  };

  const { type } = props;

  return <ListContainer>{renderListItem(type, stateData)}</ListContainer>;
};

const ListContainer = styled.div`
  width: 100%;
  box-shadow: 0 8px 24px 0 rgba(26, 26, 26, 0.1);
  margin-bottom: 50px;
`;

const CompanyDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const DragableSpan = styled.span`
  position: absolute;
  left: 30px;
  :hover {
    cursor: pointer;
  }
`;

const SmartListComponent = observer(SmartList);
export { SmartListComponent };
