import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import { AppPanelTitle, AppText, AppFormTitle, ToolTipContainer, AnimatedLink, Loading, AppButton } from "../../UI";
import { PanelFooter, PanelWrapper, PanelContent, PanelContainer } from "../../Dumb";
import { useState, FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";
import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { returnIfNormalFund } from "../../Dumb/utils";

interface ResearchProcessPipeProps {
  fund_id: number;
}

const ResearchProcessPipePanel: FunctionComponent<ResearchProcessPipeProps> = (props) => {
  const { store } = useQuery();
  const [sector, changeSector] = useState("industry");
  const toggleSector = () => changeSector(sector === "industry" ? "trend" : "industry");

  const { data: industryData, loading: industryLoading, error: industryError, setQuery: industrySetQuery } = useQuery(
    (store) =>
      store.queryFetchIndustries({ fundId: props.fund_id }, (qb) =>
        qb.id.name.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count
          .fund_I_to_III_portoflio_companies((b) => b.id.logo_url.name.website)
          .portfolio_companies((b) => b.id.logo_url.name.website)
          .fund((fund) => fund.portfolio_companies),
      ),
  );

  const { data: trendData, loading: trendLoading, error: trendError, setQuery: trendSetQuery } = useQuery((store) =>
    store.queryFetchTrends({ fundId: props.fund_id }, (qb) =>
      qb.id.name.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count
        .fund_I_to_III_portoflio_companies((b) => b.id.logo_url.name.website)
        .portfolio_companies((b) => b.id.logo_url.name.website)
        .fund((fund) => fund.portfolio_companies),
    ),
  );

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.research_process_pipeline_footnote),
  );

  useEffect(() => {
    const query = store.queryFetchIndustries({ fundId: props.fund_id }, (qb) =>
      qb.id.name.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count
        .fund_I_to_III_portoflio_companies((b) => b.id.logo_url.name.website)
        .portfolio_companies((b) => b.id.logo_url.name.website)
        .fund((fund) => fund.portfolio_companies),
    );
    industrySetQuery(query);
    const query2 = store.queryFetchTrends({ fundId: props.fund_id }, (qb) =>
      qb.id.name.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count
        .fund_I_to_III_portoflio_companies((b) => b.id.logo_url.name.website)
        .portfolio_companies((b) => b.id.logo_url.name.website)
        .fund((fund) => fund.portfolio_companies),
    );
    trendSetQuery(query2);
    const query3 = store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.research_process_pipeline_footnote);
    fundQuery(query3);
  }, [props.fund_id]);

  if (industryLoading || trendLoading || fundLoading) {
    return <Loading />;
  }

  if (!industryData.fetchIndustries.length || !trendData.fetchTrends.length) {
    return null;
  }

  if (industryError || !industryData || !industryData.fetchIndustries || !industryData.fetchIndustries.length) {
    return <p>Could not fetch industrys</p>;
  }
  if (trendError || !trendData || !trendData.fetchTrends || !trendData.fetchTrends.length) {
    return <p>Could not fetch trends</p>;
  }
  if (fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  const industry_list = industryData.fetchIndustries;
  const trend_list = trendData.fetchTrends;

  const {
    fund: { research_process_pipeline_footnote },
  } = fundData;

  return (
    <PanelContainer>
      <PanelWrapper>
        <TitleContainer>
          <div>
            <AppPanelTitle>RESEARCH PROCESS & PIPELINE</AppPanelTitle>
            <PanelDescription>
              Use the toggle on the right to switch between pipeline view by industry or by trend
            </PanelDescription>
          </div>
          <SwitchButton width={180} onClick={toggleSector}>{`Switch to ${
            sector === "industry" ? "Trend" : "Industry"
          }`}</SwitchButton>
        </TitleContainer>
        <PanelContent>
          <TableDiv>
            <TableContainer>
              <thead>
                <tr>
                  <TableHeaderCell>{sector === "industry" ? "Industry" : "Trend"}</TableHeaderCell>
                  <TableHeaderCell>Companies Identified </TableHeaderCell>
                  <TableHeaderCell>Initial Calls </TableHeaderCell>
                  {returnIfNormalFund(props.fund_id) && <TableHeaderCell>Secondary Diligence </TableHeaderCell>}
                  {returnIfNormalFund(props.fund_id) && <TableHeaderCell>Final Diligence</TableHeaderCell>}
                  <TableHeaderCell>Portfolio Companies</TableHeaderCell>
                  {!returnIfNormalFund(props.fund_id) && (
                    <TableHeaderCell>Fund I - III Portfolio Companies</TableHeaderCell>
                  )}
                </tr>
              </thead>
              <tbody
                style={{
                  display: sector === "industry" ? "table-row-group" : "none",
                }}
              >
                {industry_list.map((industry, index) => {
                  return (
                    <tr key={industry.id}>
                      <TableCell>
                        <TableCellItem>{industry.name}</TableCellItem>
                      </TableCell>
                      <TableCell>{Number(industry.companies_identified_count).toLocaleString()}</TableCell>
                      <TableCell>{industry.initial_calls_count}</TableCell>
                      {returnIfNormalFund(props.fund_id) && (
                        <TableCell>{industry.secondary_dilligence_count}</TableCell>
                      )}
                      {returnIfNormalFund(props.fund_id) && (
                        <TableCell>
                          <TableCellItem>
                            <div data-tip data-for={`final_dilligence_${index}`}>
                              {industry.final_dilligence_count}
                            </div>
                          </TableCellItem>
                        </TableCell>
                      )}
                      <TableCell>
                        <TableCellLogoContainer>
                          {industry.portfolio_companies &&
                            industry.portfolio_companies.map((company) => {
                              return <TabelCellLogo key={company.id} src={company.logo_url} />;
                            })}
                        </TableCellLogoContainer>
                      </TableCell>

                      {!returnIfNormalFund(props.fund_id) && (
                        <TableCell>
                          <TableCellLogoContainer>
                            {industry.fund_I_to_III_portoflio_companies &&
                              industry.fund_I_to_III_portoflio_companies.map((company: any) => {
                                return <TabelCellLogo key={company.id} src={company.logo_url} />;
                              })}
                            {/* {JSON.stringify(industry.fund_I_to_III_portoflio_companies)} */}
                          </TableCellLogoContainer>
                        </TableCell>
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <TableCell>
                    <strong>TOTAL</strong>
                  </TableCell>
                  <TableCell>
                    <TotalCircle>
                      <InnerTotalCircle>
                        {industry_list
                          .reduce((acc, currentValue) => {
                            return acc + currentValue.companies_identified_count;
                          }, 0)
                          .toLocaleString()}
                        <CircleLabel>Companies Identified</CircleLabel>
                      </InnerTotalCircle>
                    </TotalCircle>
                  </TableCell>

                  <TableCell>
                    <TotalCircle>
                      <InnerTotalCircle>
                        {industry_list
                          .reduce((acc, currentValue) => {
                            return acc + currentValue.initial_calls_count;
                          }, 0)
                          .toLocaleString()}
                        <CircleLabel>Initial Calls</CircleLabel>
                      </InnerTotalCircle>
                    </TotalCircle>
                  </TableCell>
                  {returnIfNormalFund(props.fund_id) ? (
                    <>
                      <TableCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {industry_list
                              .reduce((acc, currentValue) => {
                                return acc + currentValue.secondary_dilligence_count;
                              }, 0)
                              .toLocaleString()}
                            <CircleLabel>Secondary Diligence</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </TableCell>
                      <TableCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {industry_list.reduce((acc, currentValue) => {
                              return acc + currentValue.final_dilligence_count;
                            }, 0)}
                            <CircleLabel>Final Diligence</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </TableCell>
                      <PortfolioCompanyTotalCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {industry_list[0].fund.portfolio_companies}
                            <CircleLabel>Portfolio Companies*</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </PortfolioCompanyTotalCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                </tr>
              </tbody>
              <tbody
                style={{
                  display: sector === "trend" ? "table-row-group" : "none",
                }}
              >
                {trend_list.map((trend, index) => {
                  return (
                    <tr key={trend.id}>
                      <TableCell>
                        <TableCellItem>{trend.name}</TableCellItem>
                      </TableCell>
                      <TableCell>{trend.companies_identified_count}</TableCell>
                      <TableCell>{trend.initial_calls_count}</TableCell>
                      <TableCell>{trend.secondary_dilligence_count}</TableCell>
                      <TableCell>
                        <TableCellItem>
                          <div data-tip data-for={`final_dilligence_${index}`}>
                            {trend.final_dilligence_count}
                          </div>
                        </TableCellItem>
                      </TableCell>
                      <TableCell>
                        <TableCellLogoContainer>
                          {trend.portfolio_companies &&
                            trend.portfolio_companies.map((company) => {
                              return <TabelCellLogo key={company.id} src={company.logo_url} />;
                            })}
                        </TableCellLogoContainer>
                      </TableCell>
                    </tr>
                  );
                })}
                <tr>
                  <TableCell>
                    <strong>TOTAL</strong>
                  </TableCell>
                  <TableCell>
                    <TotalCircle>
                      <InnerTotalCircle>
                        {trend_list.reduce((acc, currentValue) => {
                          return acc + currentValue.companies_identified_count;
                        }, 0)}
                        <CircleLabel>Companies Identified</CircleLabel>
                      </InnerTotalCircle>
                    </TotalCircle>
                  </TableCell>
                  <TableCell>
                    <TotalCircle>
                      <InnerTotalCircle>
                        {trend_list.reduce((acc, currentValue) => {
                          return acc + currentValue.initial_calls_count;
                        }, 0)}
                        <CircleLabel>Initial Calls</CircleLabel>
                      </InnerTotalCircle>
                    </TotalCircle>
                  </TableCell>
                  {returnIfNormalFund(props.fund_id) ? (
                    <>
                      <TableCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {trend_list.reduce((acc, currentValue) => {
                              return acc + currentValue.secondary_dilligence_count;
                            }, 0)}
                            <CircleLabel>Secondary Diligence</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </TableCell>
                      <TableCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {trend_list.reduce((acc, currentValue) => {
                              return acc + currentValue.final_dilligence_count;
                            }, 0)}
                            <CircleLabel>Final Diligence</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </TableCell>
                      <PortfolioCompanyTotalCell>
                        <TotalCircle>
                          <InnerTotalCircle>
                            {trend_list[0].fund.portfolio_companies}
                            <CircleLabel>Portfolio Companies*</CircleLabel>
                          </InnerTotalCircle>
                        </TotalCircle>
                      </PortfolioCompanyTotalCell>
                    </>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                </tr>
              </tbody>
            </TableContainer>
          </TableDiv>
          <PanelFooter>{research_process_pipeline_footnote}</PanelFooter>
        </PanelContent>
      </PanelWrapper>
    </PanelContainer>
  );
};

const TableDiv = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

const TableHeaderCell = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  padding: 1.25rem 0.625rem;
  color: ${BLACK_COLOR};
  font-size: 14px;
  @media ${mobileSize} {
    padding: 5px;
  }
`;
const TableCellItem = styled.div`
  display: block;
  white-space: nowrap;
`;

const TableCellLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 350px;
`;

const TabelCellLogo = styled.img`
  display: inline-block;
  vertical-align: middle;
  max-height: 60px;
  max-width: 96px;
  border-style: none;
  margin-left: 5px;
  margin-right: 5px;
`;

const TableCell = styled.td`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px;
  text-align: center;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  color: ${BLACK_COLOR};
  font-weight: 300;
  font-size: 28px;
  @media ${mobileSize} {
    padding: 5px;
  }
  ${TableCellItem}+${TableCellItem} {
    margin-top: 8px;
  }
`;

const PortfolioCompanyTotalCell = styled(TableCell)`
  display: flex;
  justify-content: center;
`;

const TableLogoCell = styled.th`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px;
  padding-right: 60px;
  text-align: left;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  color: rgba(26, 26, 26, 0.8);
  width: 100%;
  @media ${mobileSize} {
    padding-right: 20px;
  }
`;

const TableContainer = styled.table`
  font-family: ${PRIMARY_FONT};
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  ${TableCell}:first-child {
    padding-left: 0 !important;
    padding-right: 1.25rem;
    color: rgba(26, 26, 26, 0.8);
    font-size: 14px;
    text-align: left;
  }
  ${TableLogoCell}:first-child {
    padding-left: 0 !important;
    text-align: left;
  }
  ${TableCell}:last-child {
    padding-right: 0 !important;
    text-align: left;
  }
  ${TableHeaderCell}:first-child {
    padding-left: 0 !important;
    text-align: left;
  }
  ${TableHeaderCell}:last-child {
    padding-right: 0 !important;
  }
`;

const TotalCircle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: ${PRIMARY_COLOR};
`;

const InnerTotalCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  font-size: 28px;
  line-height: 1.28571;
  letter-spacing: 0;
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 1.41667;
    letter-spacing: 0;
  }
`;

const PanelDescription = styled(AppFormTitle)`
  font-family: ${PRIMARY_FONT};
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
  letter-spacing: 0;
`;

const CircleLabel = styled(AppText)`
  font-size: 14px;
`;

const StyledToolTips = styled(ToolTipContainer)`
  text-align: left;
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

const StyledToolTipHeader = styled(AppFormTitle)`
  font-family: ${PRIMARY_FONT};
  font-size: 18px;
  color: #fff;
`;

const StyledToolTop = styled(ReactTooltip)`
  pointer-events: auto !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  margin-top: 10px;
  color: #fff;
`;

const LinkRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwitchButton = styled(AppButton)`
  display: none;
  background-color: ${PRIMARY_COLOR};
  color: #fff;
  opacity: 1;
`;

const ResearchProcessPipeComponent = observer(ResearchProcessPipePanel);

export { ResearchProcessPipeComponent };
