import styled from "styled-components";
import { PRIMARY_COLOR, RED_COLOR, PRIMARY_FONT } from "../../utils/theme";

export const AppText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  width: fit-content;
  margin: 0;
  border-bottom: ${(props) => (!!props.onClick ? 4 : 0)}px solid ${PRIMARY_COLOR};
  :hover {
    cursor: ${(props) => (!!props.onClick ? "pointer" : "default")};
  }
`;

export const AppErrorText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${RED_COLOR};
  margin: 0;
`;

export const AppH1 = styled.h1``;
export const AppH2 = styled.h2``;
export const AppH3 = styled.h3``;
export const AppH4 = styled.h4``;
