import styled from "styled-components";
import { mobileSize } from "../../utils/breakpoints";

export const ModalWrapper = styled.div`
  max-width: 940px;
  padding: 0px 100px;
  padding-top: 50px;
  margin-right: auto;
  margin-left: auto;
`;

export const PortfolioModalWrapper = styled.div`
  position: absolute;
  top: 250px;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  @media ${mobileSize} {
    top: 0px;
  }
`;
