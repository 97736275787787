import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { AppTitle, AnimatedText, Loading, Wrapper, AnimatedLink, AppSelect } from "../UI";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, PRIMARY_FONT, PRIMARY_COLOR, SECONDARY_FONT, OFF_WHITE_BACKGROUND } from "../../utils/theme";
import { DisclaimerPopupComponent, VideoModal } from "../Modals";
import { ContactUs, Footer } from "../Segments";
import ReactGA from "react-ga";
import { HBCUDR_DISCLAIMER_KEY } from "../../utils/variables";
import moment from "moment";

interface ItemType {
  label: string;
  type: string;
  url: string;
}

const HBCUDataRoomComponent: FunctionComponent = () => {
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };
  const {
    store,
    store: { selected_fund, selectFund, fund_list },
  } = useQuery();

  const {
    data: dataroomData,
    loading: dataroomLoading,
    error: dataroomError,
    setQuery: dataroomQuery,
    // setQuery: dataroomSetQuery,
  } = useQuery((store) => {
    return store.queryDataRoomTopics({ where: { hbcu: { equals: true } } }, (qb) =>
      qb.topic_name.ordinal.id.items((items) => items.id.label.type.url.ordinal),
    );
  });

  // Runs on mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (store.user.role === "HBCUDR") {
      setShowModal(true);
    }
    return () => {
      store.mutateUpdateTimeOnPage({});
    };
  }, []);

  useEffect(() => {
    if (!selected_fund) {
      return;
    }
    ReactGA.event({
      category: `Dataroom`,
      action: `${store.user.username}`,
      label: `Fund clicked: ${selected_fund.name}}” at ${moment().toLocaleString()}`,
    });
  }, [selected_fund.name]);

  useEffect(() => {
    const query = store.queryDataRoomTopics({ where: { hbcu: { equals: true } } }, (qb) =>
      qb.topic_name.id.ordinal.items((items) => items.id.ordinal.label.type.url),
    );
    dataroomQuery(query);
    return;
  }, [selected_fund.id]);

  const handleSelectFund = (event: any) => {
    event.preventDefault();
    selectFund(event.target.value);
    // changeSelectedFund(event.target.value);
  };

  if (dataroomLoading && !dataroomData) {
    return <Loading />;
  }

  if (dataroomError) {
    return <p>Error</p>;
  }

  console.log("dataRoomdata", dataroomData);
  const { dataRoomTopics } = dataroomData;

  return (
    <DataRoomcontainer>
      <VideoModal toggleModal={toggleVideoModal} showModal={videoModalVisible} url={videoLink} />
      {showModal && <DisclaimerPopupComponent showModal={showModal} toggleModal={() => setShowModal(false)} />}
      <Wrapper>
        <FundText>Advancement Initiative Fund Data Room</FundText>
        {dataRoomTopics
          .slice()
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((topics) => {
            return (
              <DataContentSegment key={topics.topic_name}>
                <DataRoomSegmentTitle>{topics.topic_name}</DataRoomSegmentTitle>
                <DataListContainer>
                  <DataList>
                    {topics.items
                      .slice()
                      .sort((a, b) => a.ordinal - b.ordinal)
                      .map((item: ItemType) => {
                        if (item.type === "PDF") {
                          return (
                            <DataListItem>
                              <StyledAnimatedLink
                                onClick={() => {
                                  ReactGA.event({
                                    category: "Dataroom click",
                                    action: `${store.user.username}`,
                                    label: `PDF clicked: ${item.label} at ${moment().toLocaleString()}`,
                                  });
                                }}
                                href={item.url}
                                target="_blank"
                              >
                                {item.label}
                              </StyledAnimatedLink>
                            </DataListItem>
                          );
                        }
                        if (item.type === "LINK") {
                          return (
                            <DataListItem>
                              <StyledAnimatedLink
                                onClick={() => {
                                  ReactGA.event({
                                    category: "Dataroom click",
                                    action: `${store.user.username}`,
                                    label: `Link clicked: ${item.label} at ${moment().toLocaleString()}`,
                                  });
                                }}
                                href={item.url}
                                target="_blank"
                              >
                                {item.label}
                              </StyledAnimatedLink>
                            </DataListItem>
                          );
                        }
                        if (item.type === "VIDEO") {
                          return (
                            <DataListItem>
                              <StyledAnimatedText
                                onClick={() => {
                                  ReactGA.event({
                                    category: "Dataroom click",
                                    action: `${store.user.username}`,
                                    label: `Video clicked: ${item.label} at ${moment().toLocaleString()}`,
                                  });
                                  toggleVideoModal();
                                  setVideoLink(item.url);
                                }}
                              >
                                {item.label}
                              </StyledAnimatedText>
                            </DataListItem>
                          );
                        }
                      })}
                  </DataList>
                </DataListContainer>
              </DataContentSegment>
            );
          })}
      </Wrapper>
      <ContactUs />
      <Footer />
    </DataRoomcontainer>
  );
};

const DataContentSegment = styled.div`
  width: calc(83.33333% - 55px);
  float: left;
  margin-left: 30px;
  margin-left: calc(8.33333% - 33px + 60px);
  padding-top: 120px;
  padding-bottom: 30px;
  background: ${OFF_WHITE_BACKGROUND};
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const DataRoomcontainer = styled.div`
  padding-top: 120px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  background-color: ${OFF_WHITE_BACKGROUND};
  ${DataContentSegment}+${DataContentSegment}{
    padding-top: 0;
  }
  ${DataContentSegment}:last-child{
    padding-bottom: 120px;
  }
`;

const DataRoomSegmentTitle = styled(AppTitle)`
  font-family: ${SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 2px;
`;

const DataListContainer = styled.div`
  margin-top: 11px;
`;

const DataList = styled.ul`
  list-style: none;
  padding-left: 30px;
  @media ${mobileSize} {
    padding-left: 20px;
  }
`;

const FundText = styled.div`
  height: 50px;
  width: auto;
  font-family: ${PRIMARY_FONT};
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  color: #000;
  margin-left: 30px;
  @media ${mobileSize} {
    flex-direction: column;
    margin-left: 20px;
  }
`;

const DataListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  position: relative;
  color: rgba(26, 26, 26, 0.6);
  ::before {
    content: "·";
    position: absolute;
    color: ${PRIMARY_COLOR};
    top: -8px;
    right: auto;
    left: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    font-size: 36px;
    line-height: 1.38889;
    letter-spacing: -1px;
  }
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  font-family: ${PRIMARY_FONT};
  color: ${BLACK_COLOR};
  font-size: 20px;
  line-height: 1.6;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 1.33333;
  }
`;

const StyledAnimatedText = styled(AnimatedText)`
  font-family: ${PRIMARY_FONT};
  color: ${BLACK_COLOR};
  font-size: 20px;
  line-height: 1.6;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 1.33333;
  }
  ::after {
    height: 2px;
    bottom: -2px;
  }
`;

const SelectContainer = styled.div`
  margin-left: 15px;
`;

const HBCUDataRoom = observer(HBCUDataRoomComponent);

export { HBCUDataRoom };
