/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum Role {
  ADMIN="ADMIN",
INVESTOR="INVESTOR",
HBCU="HBCU",
HBCUDR="HBCUDR"
}

/**
* Role
*/
export const RoleEnum = types.enumeration("Role", [
        "ADMIN",
  "INVESTOR",
  "HBCU",
  "HBCUDR",
      ])
