/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DataRoomTopicModel, DataRoomTopicModelType } from "./DataRoomTopicModel"
import { DataRoomTopicModelSelector } from "./DataRoomTopicModel.base"
import { UrlTypeEnum } from "./UrlTypeEnum"
import { RootStoreType } from "./index"


/**
 * DataRoomItemBase
 * auto generated base class for the model DataRoomItemModel.
 */
export const DataRoomItemModelBase = ModelBase
  .named('DataRoomItem')
  .props({
    __typename: types.optional(types.literal("DataRoomItem"), "DataRoomItem"),
    id: types.union(types.undefined, types.integer),
    ordinal: types.union(types.undefined, types.integer),
    label: types.union(types.undefined, types.string),
    url: types.union(types.undefined, types.string),
    type: types.union(types.undefined, UrlTypeEnum),
    topic_id: types.union(types.undefined, types.null, types.integer),
    topic: types.union(types.undefined, types.null, types.late((): any => DataRoomTopicModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DataRoomItemModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get label() { return this.__attr(`label`) }
  get url() { return this.__attr(`url`) }
  get type() { return this.__attr(`type`) }
  get topic_id() { return this.__attr(`topic_id`) }
  topic(builder?: string | DataRoomTopicModelSelector | ((selector: DataRoomTopicModelSelector) => DataRoomTopicModelSelector)) { return this.__child(`topic`, DataRoomTopicModelSelector, builder) }
}
export function selectFromDataRoomItem() {
  return new DataRoomItemModelSelector()
}

export const dataRoomItemModelPrimitives = selectFromDataRoomItem().ordinal.label.url.type.topic_id
