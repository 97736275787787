/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { FundraisingAndValuationHistoryGraphRoundModel, FundraisingAndValuationHistoryGraphRoundModelType } from "./FundraisingAndValuationHistoryGraphRoundModel"
import { FundraisingAndValuationHistoryGraphRoundModelSelector } from "./FundraisingAndValuationHistoryGraphRoundModel.base"
import { RootStoreType } from "./index"


/**
 * FundraisingAndValuationHistoryGraphBase
 * auto generated base class for the model FundraisingAndValuationHistoryGraphModel.
 */
export const FundraisingAndValuationHistoryGraphModelBase = ModelBase
  .named('FundraisingAndValuationHistoryGraph')
  .props({
    __typename: types.optional(types.literal("FundraisingAndValuationHistoryGraph"), "FundraisingAndValuationHistoryGraph"),
    max_valuation: types.union(types.undefined, types.null, types.number),
    company: types.union(types.undefined, types.null, types.late((): any => CompanyModel)),
    rounds: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => FundraisingAndValuationHistoryGraphRoundModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FundraisingAndValuationHistoryGraphModelSelector extends QueryBuilder {
  get max_valuation() { return this.__attr(`max_valuation`) }
  company(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`company`, CompanyModelSelector, builder) }
  rounds(builder?: string | FundraisingAndValuationHistoryGraphRoundModelSelector | ((selector: FundraisingAndValuationHistoryGraphRoundModelSelector) => FundraisingAndValuationHistoryGraphRoundModelSelector)) { return this.__child(`rounds`, FundraisingAndValuationHistoryGraphRoundModelSelector, builder) }
}
export function selectFromFundraisingAndValuationHistoryGraph() {
  return new FundraisingAndValuationHistoryGraphModelSelector()
}

export const fundraisingAndValuationHistoryGraphModelPrimitives = selectFromFundraisingAndValuationHistoryGraph().max_valuation
