import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "../../utils/theme";

export const AppTitle = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: 20px;
  font-weight: 600;
`;

export const AppSubTitle = styled.h2`
  font-family: ${PRIMARY_FONT};
  font-size: 20px;
  font-weight: 600;
  scroll-margin-top: 70px;
  @supports (-webkit-hyphens: none) {
    padding-top: 16px;
    margin-top: -16px;
  }
`;

export const AppFormTitle = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
`;

export const AppPanelTitle = styled.h2`
  font-family: ${SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
`;
