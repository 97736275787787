import * as React from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import request from "superagent";
import { ReplaceCompanyImageButton, AppErrorText, AppFormTitle } from "../UI";

// TODO: control image size
// TODO: Compress images?

export interface ImageUploadProps {
  image: string | undefined;
  error?: string;
  uploadButtonText?: string;
  replaceButtonText?: string;
  title?: string;
  width?: number;
  onImageChange: (url: string) => void;
  cloudinaryUrl: string;
  cloudinaryPreset: string;
}

interface ImageUploadState {
  uploadedFile: any;
}

class ImageUpload extends React.Component<ImageUploadProps, ImageUploadState> {
  constructor(props: ImageUploadProps) {
    super(props);

    this.state = {
      uploadedFile: "",
    };
  }

  // Only update if value is changed
  shouldComponentUpdate(nextProps: ImageUploadProps) {
    if (nextProps.image !== this.props.image) {
      return true;
    }
    return false;
  }

  onImageDrop = (files: any) => {
    this.setState({
      uploadedFile: files[0],
    });

    this.handleImageUpload(files[0]);
  };

  handleImageUpload = (file: any) => {
    const upload = request
      .post(this.props.cloudinaryUrl)
      .field("upload_preset", this.props.cloudinaryPreset)
      .field("file", file);
    upload.end((err: any, response: any) => {
      if (err) {
        console.error(err);
      }

      if (response.body.secure_url !== "") {
        this.props.onImageChange(response.body.secure_url);
      }
    });
  };

  render(): JSX.Element {
    const { image, error, title, uploadButtonText, replaceButtonText, ...props } = this.props;
    return (
      <Div>
        {!!title && <ThisAppFormTitle>{title.toUpperCase()}</ThisAppFormTitle>}
        <Dropzone onDrop={this.onImageDrop} accept="image/*" multiple={false}>
          {({ getRootProps, getInputProps }) => {
            return (
              <DropArea {...getRootProps()}>
                <input {...(getInputProps() as any)} />
                {
                  <>
                    {!!image ? (
                      <ReplaceCompanyImageButton src={image} width={props.width ? props.width : 200} type="button">
                        {replaceButtonText ? replaceButtonText : `Replace`}
                      </ReplaceCompanyImageButton>
                    ) : (
                      <ReplaceCompanyImageButton width={props.width ? props.width : 200} type="button">
                        {uploadButtonText ? uploadButtonText : `Upload`}
                      </ReplaceCompanyImageButton>
                    )}
                  </>
                }
              </DropArea>
            );
          }}
        </Dropzone>
        {!!error ? <AppErrorText>{error}</AppErrorText> : <AppErrorText>&nbsp;</AppErrorText>}
      </Div>
    );
  }
}

const DropArea = styled.div`
  height: auto;
  width: fit-content;
  text-align: center;
  border: 0px solid black;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-radius: 2px;
  cursor: pointer;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const ThisAppFormTitle = styled(AppFormTitle)`
  margin: 0;
`;

export { ImageUpload };
