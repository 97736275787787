import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import { CapitalDeployedProgressBar, AppPanelTitle, Loading, AppText, AppTitle } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect, useState } from "react";
import { mobileSize } from "../../../utils/breakpoints";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import chart1 from "../../../data/chart1.png";
import { ImageExpanedModal } from "./ImageExpandedModal";

const AdvancementInitiative1Chart1Panel: FunctionComponent = (props) => {
  const [modalOn, setModalOn] = useState(false);
  return (
    <MostOfPanelContainer>
      <ImageExpanedModal showModal={modalOn} toggleModal={() => setModalOn((modalOn) => !modalOn)} />
      <PanelWrapper>
        <AppPanelTitle>
          Follow-on rounds and secondary pricing information in Advancement Initiative portfolio
        </AppPanelTitle>
        <PanelContent>
          <ChartTitle></ChartTitle>
          <ClickableDiv onClick={() => setModalOn(true)}>
            <ChartImg src={chart1} />
          </ClickableDiv>
          <FooterText>
            Sources: Merrill Lynch, ParkLane
            <br />
            Methodology: Secondary market valuation is based on the average price per share between the bid and ask.
            Where valuations from multiple providers existed, return was calculated on an average between the two
            valuations. Return was calculated as secondary market valuation divided by Base10’s entry valuation, as of
            3/31/2022.
          </FooterText>
        </PanelContent>
      </PanelWrapper>
    </MostOfPanelContainer>
  );
};

const ClickableDiv = styled.div`
  cursor: pointer;
`;

const MostOfPanelContainer = styled(HalfPanelContainer)`
  width: calc(75% - 45px);
`;

const ChartTitle = styled(AppTitle)`
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 70px;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 17px;
`;

const ChartImg = styled.img`
  width: 100%;
  height: auto;
`;

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 60px;
`;

interface CapitalDeployedTextProps {
  unallocated?: boolean;
}

const CapitalDeployedText = styled.h3<CapitalDeployedTextProps>`
  font-family: ${PRIMARY_FONT};
  font-weight: normal;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -2px;
  margin: 0;
  color: ${(props) => (props.unallocated ? PRIMARY_COLOR : BLACK_COLOR)};
  @media ${mobileSize} {
    font-size: 40px;
    letter-spacing: -1px;
  }
`;

const CapitalDeployedLabel = styled.h4<CapitalDeployedTextProps>`
  font-family: ${PRIMARY_FONT};
  margin: 0;
  margin-top: -1px;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  font-weight: normal;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
  }
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AdvancementInitiative1Chart1 = observer(AdvancementInitiative1Chart1Panel);

export { AdvancementInitiative1Chart1 };
