import React from "react";

export const Base10Logo = ({ color }: { color?: string }) => (
  <svg className="logo" width="119" height="28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        className="logo__base"
        d="M.24.73h9.893c5.227 0 8.251 3.025 8.251 7.094 0 2.725-1.717 4.965-3.883 5.675 2.39.56 4.704 2.762 4.704 6.197 0 4.33-3.285 7.504-8.288 7.504H.24V.73zm5.75 10.603H9.2c2.053 0 3.397-1.082 3.397-2.874 0-1.718-1.157-2.838-3.472-2.838H5.99v5.712zm0 11.051h3.695c2.278 0 3.659-1.195 3.659-3.099 0-1.829-1.344-3.098-3.659-3.098H5.99v6.197zm14.874-.299c0-3.285 2.427-5.152 5.488-5.6l4.368-.672c1.008-.149 1.344-.634 1.344-1.269 0-1.083-.933-2.016-2.725-2.016-1.979 0-3.062 1.344-3.174 2.725l-4.928-1.008c.224-2.65 2.688-6.16 8.139-6.16 6.01 0 8.213 3.36 8.213 7.168v9.072c0 1.456.187 2.688.224 2.875H32.7c-.038-.15-.187-.821-.187-2.09-.97 1.567-2.763 2.613-5.227 2.613-4.069 0-6.421-2.688-6.421-5.638zm7.803 1.531c1.68 0 3.397-.821 3.397-3.696v-.821l-3.51.56c-1.194.186-2.09.746-2.09 2.053 0 .97.635 1.904 2.203 1.904zm11.104-1.68l4.816-.859c.074 1.27.97 2.502 2.912 2.502 1.456 0 2.165-.784 2.165-1.606 0-.672-.448-1.232-1.83-1.53l-2.127-.486c-3.958-.858-5.526-3.098-5.526-5.712 0-3.397 2.987-6.16 7.131-6.16 5.376 0 7.243 3.36 7.43 5.526l-4.705.858c-.149-1.232-.933-2.277-2.65-2.277-1.083 0-2.016.635-2.016 1.605 0 .784.634 1.232 1.456 1.382l2.464.485c3.845.784 5.712 3.099 5.712 5.824 0 3.173-2.427 6.272-7.392 6.272-5.824 0-7.691-3.77-7.84-5.824zm22.154-6.235h7.43c-.075-1.418-1.046-3.136-3.734-3.136-2.389 0-3.584 1.755-3.696 3.136zm7.84 4.816l4.742 1.344c-.896 3.211-3.846 5.899-8.55 5.899-5.114 0-9.669-3.659-9.669-9.893 0-5.974 4.443-9.782 9.259-9.782 5.749 0 9.296 3.547 9.296 9.52 0 .784-.075 1.643-.112 1.755H61.813c.112 2.09 2.016 3.584 4.219 3.584 2.053 0 3.21-.97 3.733-2.427z"
        fill={`${color ? color : "#FFF"}`}
      ></path>
      <path
        className="logo__10"
        d="M86.293 17.867h-4.186V6.613h-4.134v-2.8C80.56 3.787 82.4 2.32 82.693.56h3.6v17.307zm7.094-8.64c0 1.386.16 2.746.64 3.76.48.96 1.253 1.6 2.533 1.6 1.307 0 2.053-.64 2.533-1.6.507-1.014.667-2.374.667-3.76 0-1.387-.16-2.747-.667-3.76-.48-.96-1.226-1.6-2.533-1.6-1.28 0-2.053.64-2.533 1.6-.48 1.013-.64 2.373-.64 3.76zm-4.214 0c0-2.134.32-4.294 1.52-6.107C91.84 1.387 93.76.16 96.56.16c2.827 0 4.747 1.227 5.893 2.96 1.2 1.813 1.52 3.973 1.52 6.107 0 2.133-.32 4.293-1.52 6.106-1.146 1.707-3.066 2.96-5.893 2.96-2.8 0-4.72-1.253-5.867-2.96-1.2-1.813-1.52-3.973-1.52-6.106z"
        fill={`${color ? color : "#FFF"}`}
      ></path>
      <path className="logo__line" d="M79.4 26h23" stroke="#45AEEB" strokeWidth="4" strokeLinecap="square"></path>
    </g>
  </svg>
);
