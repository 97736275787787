import { createMuiTheme } from "@material-ui/core/styles";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./theme";

export const GlobalTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: `${PRIMARY_COLOR}`,
      dark: `${SECONDARY_COLOR}`,
      contrastText: "#fff",
    },
  },
});

export const appTheme = {
  primaryColor: "blue",
  secondaryColor: "lightblue",
};
