import * as React from "react";
import styled from "styled-components";
import { AppPanelTitle, AppText, AppFormTitle, AppTitle, ToolTipContainer, Loading } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";

import { PRIMARY_FONT, BLACK_COLOR, PRIMARY_COLOR } from "../../../utils/theme";

import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { returnIfNormalFund } from "../../Dumb/utils";
import { PanelFooter } from "../../Dumb/PanelContent";

interface CapitalDeployedByQuarterProps {
  fund_id: number;
  halfWidth?: boolean;
}

const CapitalDeployedByQuarterPanel: FunctionComponent<CapitalDeployedByQuarterProps> = (props) => {
  const { store } = useQuery();

  const {
    data: quarterData,
    loading: quarterLoading,
    error: quarterError,
    setQuery: quarterQuery,
  } = useQuery((store) =>
    store.queryFetchCapitalDeployedByQuarter(
      { fundId: props.fund_id },
      (qb) => qb.companies((company) => company.amount.color.name.logo_url).primary.followup.quarter,
    ),
  );

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.capital_deployed_by_quarter_footnote),
  );

  useEffect(() => {
    const query = store.queryFetchCapitalDeployedByQuarter(
      { fundId: props.fund_id },
      (qb) => qb.companies((company) => company.amount.color.name.logo_url).primary.followup.quarter,
    );
    quarterQuery(query);

    const query2 = store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.capital_deployed_by_quarter_footnote);
    fundQuery(query2);
  }, [props.fund_id]);

  if (quarterLoading || fundLoading || fundError || !fundData || !fundData.fund) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (quarterError || !quarterData || !quarterData.fetchCapitalDeployedByQuarter) {
    return <p>Error</p>;
  }

  const { fetchCapitalDeployedByQuarter } = quarterData;

  const {
    fund: { capital_deployed_by_quarter_footnote },
  } = fundData;

  return (
    <HalfPanelContainer halfWidth={false}>
      <PanelWrapper>
        {returnIfNormalFund(props.fund_id) && (
          <>
            <AppPanelTitle>CAPITAL DEPLOYED BY QUARTER ($M)</AppPanelTitle>
            <PanelDescription>
              Hover the mouse over columns to identify portfolio companies and investment amounts.
            </PanelDescription>
            <PanelContent>
              <GraphContentContainer>
                <GraphContentWrapper>
                  <GraphContentTitle>Capital Deployed</GraphContentTitle>
                  <LegendContainer>
                    <LegendItem>
                      <LegendInnerContainer>
                        <LegendColorContainer>
                          <LegendColor color={BLACK_COLOR} />
                        </LegendColorContainer>
                        <LegendItemLabel>
                          <LegendLabelText>Primary</LegendLabelText>
                        </LegendItemLabel>
                      </LegendInnerContainer>
                    </LegendItem>
                    <LegendSecondItem>
                      <LegendInnerContainer>
                        <LegendColorContainer>
                          <LegendColor color={PRIMARY_COLOR} />
                        </LegendColorContainer>
                        <LegendItemLabel>
                          <LegendLabelText>Follow-on</LegendLabelText>
                        </LegendItemLabel>
                      </LegendInnerContainer>
                    </LegendSecondItem>
                  </LegendContainer>
                  <GraphContainer>
                    <BarListContainer>
                      {fetchCapitalDeployedByQuarter.map((quarter, index) => {
                        return (
                          <GraphBars
                            key={`bar_${quarter.quarter}`}
                            data-tip
                            data-for={`captial_deployed_by_quarter_${index}`}
                          >
                            <GraphBarsWrapper>
                              <GraphBarItem height={quarter.primary}>
                                <GraphBarItemBar color={BLACK_COLOR} />
                              </GraphBarItem>
                              <GraphBarItem height={quarter.followup}>
                                <GraphBarItemBar color={PRIMARY_COLOR} />
                              </GraphBarItem>
                            </GraphBarsWrapper>
                            <ReactTooltip id={`captial_deployed_by_quarter_${index}`}>
                              <ToolTipContainer>
                                {quarter.companies.map((company: any) => {
                                  return (
                                    <TooltipBrandContainer key={quarter.quarter} color={company.color}>
                                      <TooltipLogo src={company.logo_url} />
                                      <TooltipValueText>${company.amount}</TooltipValueText>
                                    </TooltipBrandContainer>
                                  );
                                })}
                              </ToolTipContainer>
                            </ReactTooltip>
                          </GraphBars>
                        );
                      })}
                    </BarListContainer>
                  </GraphContainer>
                  <GraphItemLabels>
                    {fetchCapitalDeployedByQuarter.map((quarter) => {
                      return <GraphItemLabelsItem key={quarter.quarter}>{quarter.quarter}</GraphItemLabelsItem>;
                    })}
                  </GraphItemLabels>
                </GraphContentWrapper>
              </GraphContentContainer>
            </PanelContent>
            <FooterText>{capital_deployed_by_quarter_footnote}</FooterText>
          </>
        )}
      </PanelWrapper>
    </HalfPanelContainer>
  );
};

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.5;
  margin-top: 80px;
`;

interface Color {
  color: string;
}

const PanelDescription = styled(AppFormTitle)`
  font-family: ${PRIMARY_FONT};
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
  letter-spacing: 0;
`;

const GraphContentContainer = styled.div`
  margin-left: -60px;
  margin-right: -60px;
  width: calc(100% + 120px);
  list-style: none;
  margin-top: -30px;
  @media ${mobileSize} {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

const GraphContentWrapper = styled.div`
  width: calc(100% - 120px);
  float: left;
  margin-left: 60px;
  min-height: 254px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media ${mobileSize} {
    width: 100%;
    margin-left: 0;
  }
`;

const GraphContentTitle = styled(AppTitle)`
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 16px;
  @media ${mobileSize} {
    font-size: 12px;
  }
`;

const GraphContainer = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  min-height: 360px;
  white-space: nowrap;
  @media ${mobileSize} {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 13px);
  }
`;

const LegendContainer = styled.div`
  display: block;
`;

const LegendItem = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
`;

const LegendSecondItem = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  margin-left: 15px;
  @media ${mobileSize} {
    margin-left: 13px;
  }
`;
const LegendInnerContainer = styled.div`
  display: table;
  width: 100%;
`;

const LegendColorContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  width: 10px;
  padding-top: 2px;
  @media ${mobileSize} {
    padding-top: 2px;
  }
`;
const LegendColor = styled.div<Color>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  @media ${mobileSize} {
    height: 10px;
    width: 10px;
  }
`;

const LegendItemLabel = styled.div`
  display: table-cell;
  vertical-align: top;
  height: 32px;
  max-height: 32px;
  padding-left: 10px;
  padding-left: 7px;
`;

const LegendLabelText = styled(AppText)`
  font-size: 12px;
  line-height: 1.25;
  color: rgba(26, 26, 26, 0.8);
`;

const GraphBars = styled.div`
  display: inline-block;
  width: calc(12.5% - 11px);
  float: left;
  margin-left: 8px;
  text-align: center;
  @media ${mobileSize} {
    width: calc(12.5% - 7.5px);
    float: left;
    margin-left: 7px;
  }
`;

const GraphBarsWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  background-color: rgba(26, 26, 26, 0.05);
  height: 360px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
`;

interface BarItemHeight {
  height: number;
}

const GraphBarItem = styled.div<BarItemHeight>`
  display: block;
  width: 100%;
  height: ${(props) => `${props.height}%`};
`;

const GraphBarItemBar = styled.div<Color>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.color};
`;

const GraphItemLabels = styled.ul`
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 20px);
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  @media ${mobileSize} {
    margin-left: -7px;
    margin-right: -7px;
    width: calc(100% + 13px);
  }
`;

const GraphItemLabelsItem = styled.li`
  font-family: ${PRIMARY_FONT};
  width: calc(12.5% - 8px);
  float: left;
  margin-left: 8px;
  border-top: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 15px;
  padding-top: 7px;
  color: rgba(26, 26, 26, 0.4);
  text-align: center;
  @media ${mobileSize} {
    width: calc(12.5% - 7.5px);
    float: left;
    margin-left: 7px;
    margin-top: 7px;
    padding-top: 4px;
    font-size: 11px;
  }
`;

const TooltipBrandContainer = styled.div<Color>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  padding-bottom: 4px;
  border-bottom-color: ${(props) => props.color};
  margin-top: 5px;
`;

const TooltipLogoContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 70%;
  padding-right: 30px;
`;

const TooltipLogo = styled.img`
  display: block;
  max-width: 70%;
  max-height: 30px;
  -webkit-filter: grayscale(1) brightness(2);
  filter: grayscale(1) brightness(2);
`;

const TooltipValueContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 30%;
  text-align: left;
  float: right;
`;

const TooltipValueText = styled(AppText)`
  font-size: 14px;
`;

const BarListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
`;

const CapitalDeployedByQuarterComponent = observer(CapitalDeployedByQuarterPanel);

export { CapitalDeployedByQuarterComponent };
