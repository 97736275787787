import { Instance, applySnapshot } from "mobx-state-tree";
import { CompanyModelBase } from "./CompanyModel.base";
import { FundingRoundModelType } from ".";
import _ from "lodash";

/* The TypeScript type of an instance of CompanyModel */
export type CompanyModelType = Instance<typeof CompanyModel.Type>;

/* A graphql query fragment builders for CompanyModel */
export { selectFromCompany, companyModelPrimitives, CompanyModelSelector } from "./CompanyModel.base";

/**
 * CompanyModel
 */
export const CompanyModel = CompanyModelBase.actions((self) => {
  function localUpdateFund(params: FundingRoundModelType) {
    console.log("params: ", params);
    applySnapshot(self, {
      ...self,
      funding_rounds: self.funding_rounds.map((fr) => {
        if (fr.id === params.id) {
          return _.cloneDeep(params);
        }
        return _.cloneDeep(fr);
      }),
    });
  }
  function localAddFund(params: FundingRoundModelType) {
    const funding_rounds = _.cloneDeep(self.funding_rounds);
    funding_rounds.push(_.cloneDeep(params));
    try {
      applySnapshot(self, {
        ...self,
        funding_rounds,
      });
    } catch (e) {
      console.log("error adding fund to company: ", e);
    }
  }

  function localDeleteFund(id: number) {
    applySnapshot(self, {
      ...self,
      // eslint-disable-next-line eqeqeq
      funding_rounds: self.funding_rounds.filter((fr) => fr.id != id),
    });
  }

  return {
    localUpdateFund,
    localAddFund,
    localDeleteFund,
  };
});
