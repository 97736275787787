/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { RootStoreType } from "./index"


/**
 * CompanyVideoBase
 * auto generated base class for the model CompanyVideoModel.
 */
export const CompanyVideoModelBase = ModelBase
  .named('CompanyVideo')
  .props({
    __typename: types.optional(types.literal("CompanyVideo"), "CompanyVideo"),
    id: types.union(types.undefined, types.integer),
    title: types.union(types.undefined, types.null, types.string),
    url: types.union(types.undefined, types.string),
    image_url: types.union(types.undefined, types.null, types.string),
    company_id: types.union(types.undefined, types.null, types.integer),
    company: types.union(types.undefined, types.null, types.late((): any => CompanyModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompanyVideoModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get title() { return this.__attr(`title`) }
  get url() { return this.__attr(`url`) }
  get image_url() { return this.__attr(`image_url`) }
  get company_id() { return this.__attr(`company_id`) }
  company(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`company`, CompanyModelSelector, builder) }
}
export function selectFromCompanyVideo() {
  return new CompanyVideoModelSelector()
}

export const companyVideoModelPrimitives = selectFromCompanyVideo().title.url.image_url.company_id
