import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, AnimatedText, Loading, AppErrorText } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { FormInputField, FormSelectField, IconPickerField } from "../Forms";
import { TrashIcon } from "../Dumb";
import { FinalDilligenceCompanies } from "../../types";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { BLACK_COLOR } from "../../utils/theme";

interface AddTrendModalProps {
  showModal: boolean;
  toggleModal: () => void;
  fund_id: number;
}

interface MyFormikProps {
  name: string;
  icon: string;
  companies_identified_count: number;
  initial_calls_count: number;
  secondary_dilligence_count: number;
  final_dilligence_count: number;
  final_dilligence_companies: FinalDilligenceCompanies[];
  portfolio_companies: number[];
}
const addTrendSchema = Yup.object().shape({
  name: Yup.string().required("* Field is required"),
  icon: Yup.string().notRequired(),
  companies_identified_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  initial_calls_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  secondary_dilligence_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  final_dilligence_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
});

const AddTrendModalComponent: FunctionComponent<AddTrendModalProps> = ({ showModal, toggleModal, fund_id }) => {
  const { setQuery, loading, error, store } = useQuery();
  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
    setQuery: companySetQuery,
  } = useQuery((store) => store.queryCompaniesByFund({ fundId: fund_id }, (qb) => qb.id.name));

  useEffect(() => {
    console.log("fund_id change... refetching: ", fund_id);
    const query = store.queryCompaniesByFund({ fundId: fund_id }, (qb) => qb.id.name.name);
    companySetQuery(query);
  }, [fund_id]);
  console.log("companyData: ", companyData);
  console.log("companyLoading: ", companyLoading);
  console.log("companyError: ", companyError);

  return (
    <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
      <CloseModal onClick={toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <AddTrendContainer>
        <ModalWrapper>
          <Formik
            initialValues={{
              name: "",
              icon: undefined,
              companies_identified_count: undefined,
              initial_calls_count: undefined,
              secondary_dilligence_count: undefined,
              final_dilligence_count: undefined,
              final_dilligence_companies: [{ name: "", url: "" }],
              portfolio_companies: [],
            }}
            validationSchema={addTrendSchema}
            onSubmit={async (values) => {
              console.log("Add Trend form submission", values);
              console.log("fund: ", store.selected_fund.id);
              const mutation = store.mutateCreateOneTrend({
                data: {
                  ...values,
                  final_dilligence_companies: {
                    create: values.final_dilligence_companies
                      .filter((c) => c.name)
                      .map((c) => ({
                        name: c.name,
                        url: c.url,
                      })),
                  },
                  portfolio_companies: {
                    connect: values.portfolio_companies
                      .filter((c) => c)
                      .map((c) => ({
                        // @ts-ignore
                        id: parseInt(c.id),
                      })),
                  },
                  ordinal: store.trend_max_ordinal + 1,
                  fund: {
                    connect: {
                      id: store.selected_fund.id,
                    },
                  },
                },
              });
              mutation.then(
                (v) => {
                  toast("Trend Created!");
                  store.localAddTrend({
                    id: v.createOneTrend.id,
                    name: v.createOneTrend.name,
                    ordinal: v.createOneTrend.ordinal,
                    final_dilligence_count: v.createOneTrend.final_dilligence_count,
                  });
                  toggleModal();
                },
                (e) => {
                  toast("Could not add trend!");
                },
              );
              setQuery(mutation);
            }}
          >
            {({ submitForm, values }: FormikProps<MyFormikProps>) => {
              console.log("values", values);
              return (
                <div>
                  <TitleContainer>
                    <Title>Add Trend</Title>
                  </TitleContainer>
                  <NameSection>
                    <FormInputField name="name" title="Name" />
                  </NameSection>
                  <FormSection>
                    <Split>
                      <FormInputField
                        style={{
                          marginRight: 30,
                        }}
                        name="companies_identified_count"
                        title="COMPANIES IDENTIFIED"
                        type="number"
                      />
                      <FormInputField name="initial_calls_count" title="INITIAL CALLS" type="number" />
                    </Split>
                    <Split>
                      <FormInputField
                        style={{
                          marginRight: 30,
                        }}
                        name={"secondary_dilligence_count"}
                        title="SECONDARY DILLIGENCE"
                        type="number"
                      />
                      <FormInputField name={"final_dilligence_count"} title="FINAL DILLIGENCE" type="number" />
                    </Split>
                    <Split>
                      <IconPickerField name="icon" title="TREND ICON" />
                    </Split>
                  </FormSection>
                  <FormSection>
                    <InputTitle>FINAL DILLIGENCE COMPANIES</InputTitle>
                    <FieldArray name="final_dilligence_companies">
                      {({ insert, remove, name }) => (
                        <FieldArrayContainer>
                          {values.final_dilligence_companies.map((fdc, i) => {
                            return (
                              <Split key={fdc.id}>
                                <SplitContainer>
                                  <Split>
                                    <FormInputField
                                      width={240}
                                      name={`final_dilligence_companies[${i}].name`}
                                      fontSize={20}
                                      placeholder="Company Name"
                                      noTopMargin
                                    />
                                    <FormInputField
                                      width={240}
                                      name={`final_dilligence_companies[${i}].url`}
                                      fontSize={20}
                                      placeholder="Company Website"
                                      noTopMargin
                                    />
                                  </Split>
                                </SplitContainer>
                                <TrashIcon onClick={() => remove(i)} />
                              </Split>
                            );
                          })}
                          <AddTextContainer>
                            <AnimatedText
                              onClick={() => {
                                insert(values.final_dilligence_companies.length, {
                                  link: "",
                                  title: "",
                                });
                              }}
                            >
                              Add Company
                            </AnimatedText>
                          </AddTextContainer>
                        </FieldArrayContainer>
                      )}
                    </FieldArray>
                  </FormSection>
                  <InputTitle>PORTFOLIO COMPANIES</InputTitle>
                  <FormSection>
                    <FieldArray name="portfolio_companies">
                      {({ insert, remove, name }) => {
                        if (companyLoading) {
                          return <Loading />;
                        }
                        const companyList = companyData.companiesByFund.map((c) => ({
                          label: c.name,
                          value: c.id,
                        }));
                        return (
                          <FieldArrayContainer>
                            {values.portfolio_companies.map((company, i) => {
                              return (
                                <Split key={company}>
                                  <FormSelectField
                                    width={340}
                                    name={`portfolio_companies[${i}].id`}
                                    fontSize={20}
                                    placeholder="Choose Company"
                                    options={companyList}
                                  />
                                  <TrashIcon onClick={() => remove(i)} />
                                </Split>
                              );
                            })}
                            <AddTextContainer>
                              <AnimatedText
                                onClick={() => {
                                  insert(values.portfolio_companies.length, {
                                    id: 0,
                                  });
                                }}
                              >
                                Add Company
                              </AnimatedText>
                            </AddTextContainer>
                          </FieldArrayContainer>
                        );
                      }}
                    </FieldArray>
                  </FormSection>
                  <SubmitContainer>
                    {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                    {error && <AppErrorText>{error}</AppErrorText>}
                  </SubmitContainer>
                </div>
              );
            }}
          </Formik>
        </ModalWrapper>
      </AddTrendContainer>
    </Modal>
  );
};

const AddTrendContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const NameSection = styled.div`
  width: 100%;
`;

const InputTitle = styled.p`
  font-family: "Apercu-Mono";
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const FormSection = styled.div`
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 50px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const AddTrendModal = observer(AddTrendModalComponent);

export { AddTrendModal };
