import styled from "styled-components";

const CMSBodyWrapper = styled.div`
  max-width: 940px;
  padding-top: 75px;
  margin-right: auto;
  margin-left: auto;
`;

export { CMSBodyWrapper };
