import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { Header } from "../Components/Segments";
import { observer } from "mobx-react";
import { StoreContext } from "../models/reactUtils";

// Public Route
// See AppRouter for more information

interface PublicRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PublicRouteProps;

export const NoAuthRouteComponent = ({
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  const store = useContext(StoreContext);

  const { is_authenticated } = store;

  return (
    <div>
      {is_authenticated && <Header />}
      <Route {...rest} component={(props: any) => <Component {...props} />} />
    </div>
  );
};

const NoAuthRoute = observer(NoAuthRouteComponent);

export default NoAuthRoute;
