import React, { createContext, SFC, useState, useMemo } from "react";
import {
  AddCompanyModal,
  AddIndustryModal,
  NewFundModal,
  GeneralSettingsModal,
  AddTrendModal,
} from "../Components/Modals";
import { useQuery } from "../models/reactUtils";

// useContext - gets value from context

export const ModalContext = createContext(null);

export const ModalContainer: SFC = ({ children }) => {
  const { store } = useQuery();
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [addIndustryModalVisible, setIndustryModalVisible] = useState(false);
  const [addTrendModalVisible, setTrendModalVisible] = useState(false);
  const [generalSettingsModalVisible, setGeneralSettingsModalVisible] = useState(false);
  const [newFundModalVisible, setNewFundModalVisible] = useState(false);
  const [roundModalVisible, setRoundModalVisible] = useState(false);
  const [roundModalIsEdit, setRoundModalIsEdit] = useState(false);
  const [companyPortfolioVisible, setCompanyPortfolioVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);

  const toggleCompanyModal = () => setCompanyModalVisible(!companyModalVisible);
  const toggleIndustryModal = () => setIndustryModalVisible(!addIndustryModalVisible);
  const toggleTrendModal = () => setTrendModalVisible(!addTrendModalVisible);
  const toggleGeneralSettingsModal = () => setGeneralSettingsModalVisible(!generalSettingsModalVisible);
  const toggleNewFundModal = () => setNewFundModalVisible(!newFundModalVisible);
  const toggleRoundModal = () => setRoundModalVisible(!roundModalVisible);
  const toggleRoundModalIsEdit = () => setRoundModalIsEdit(!roundModalIsEdit);
  const toggleCompanyPortfolioModal = () => setCompanyPortfolioVisible(!companyPortfolioVisible);
  const toggleVideoModal = () => setVideoModalVisible(!videoModalVisible);

  const value = useMemo(
    () => ({
      companyModalVisible,
      toggleCompanyModal,
      addIndustryModalVisible,
      toggleIndustryModal,
      generalSettingsModalVisible,
      toggleGeneralSettingsModal,
      newFundModalVisible,
      toggleNewFundModal,
      roundModalVisible,
      toggleRoundModal,
      roundModalIsEdit,
      toggleRoundModalIsEdit,
      toggleTrendModal,
      addTrendModalVisible,
      toggleCompanyPortfolioModal,
      companyPortfolioVisible,
      videoModalVisible,
      toggleVideoModal,
    }),
    [
      companyModalVisible,
      toggleCompanyModal,
      addIndustryModalVisible,
      toggleIndustryModal,
      generalSettingsModalVisible,
      toggleGeneralSettingsModal,
      newFundModalVisible,
      toggleNewFundModal,
      roundModalVisible,
      toggleRoundModal,
      roundModalIsEdit,
      toggleRoundModalIsEdit,
      toggleTrendModal,
      addTrendModalVisible,
      toggleCompanyPortfolioModal,
      companyPortfolioVisible,
      videoModalVisible,
      toggleVideoModal,
    ],
  );
  return (
    <ModalContext.Provider value={value}>
      {companyModalVisible && (
        <AddCompanyModal
          toggleModal={toggleCompanyModal}
          showModal={companyModalVisible}
          fund_id={store.selected_fund.id}
        />
      )}
      {addIndustryModalVisible && (
        <AddIndustryModal
          toggleModal={toggleIndustryModal}
          showModal={addIndustryModalVisible}
          fund_id={store.selected_fund.id}
        />
      )}
      {addTrendModalVisible && (
        <AddTrendModal
          toggleModal={toggleTrendModal}
          showModal={addTrendModalVisible}
          fund_id={store.selected_fund.id}
        />
      )}
      {generalSettingsModalVisible && (
        <GeneralSettingsModal
          toggleModal={toggleGeneralSettingsModal}
          showModal={generalSettingsModalVisible}
          fund_id={store.selected_fund.id}
        />
      )}
      {newFundModalVisible && <NewFundModal toggleModal={toggleNewFundModal} showModal={newFundModalVisible} />}
      {/* <CompanyPortfolioModal
        toggleModal={toggleCompanyPortfolioModal}
        showModal={companyPortfolioVisible}
      /> */}
      {/* <VideoModal
        toggleModal={toggleVideoModal}
        showModal={videoModalVisible}
      /> */}
      {/* <RoundModal
        toggleModal={toggleRoundModal}
        showModal={roundModalVisible}
      /> */}
      {children}
    </ModalContext.Provider>
  );
};
