export const CLOUDINARY_UPLOAD_PRESET_COMPANY_LOGO = "company-logo-upload";
export const CLOUDINARY_UPLOAD_URL = "https://api.cloudinary.com/v1_1/base10/image/upload";
export const CLOUDINARY_URL = "cloudinary://924979426899781:mBH1haMdqtIuCcq8zSA1awtQZVA@base10";
export const BACKEND_URL = "https://api.base10.vc";
//export const BACKEND_URL = "http://localhost:4000";
export const PLACEHOLDER_IMAGE = "https://via.placeholder.com/150";

export const PRISMIC_BASE_URL = "https://base10.cdn.prismic.io/api/v2";
export const PRISMIC_CLIENT_ID = "XuLqPBAAAB0AYHtW";
export const PRISMIC_SECRET = "2914617f222644a0baf95ec776887fd6";
export const PRISMIC_ACCESS_TOKEN =
  "MC5YdUxxUEJBQUFDSUFZSHRY.Ku-_vS9Z77-977-977-9cWzvv705ai4AYe-_ve-_ve-_vQzvv71177-9SB1877-977-977-9e--_vQjvv70";

export const MIXPANEL_TOKEN = "4bea2955f825c2de2ab360d6a6ba33bb";

/**
 * Used for old wordpress users. Triggers a password reset prompt
 */
export const OLD_PASSWORD = "base10";

export const QUARTERLY_PASSWORD = "quarterlyletter";

export const SESSION_TOKEN_KEY = "BASE10_TOKEN";
/**
 * local storage key for HBCU dataroom disclaimer modal
 */
export const HBCUDR_DISCLAIMER_KEY = "HBCUDR_DISCLAIMER_KEY";

export const GA_TRACKING_ID = "UA-128203228-6";

export const ACTIVE_DR = [12, 13, 14];
export const IN_ACTIVE_DR = [12, 13, 14];
export const STAGING_DR = 16;
