/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { DataRoomTopicModel, DataRoomTopicModelType } from "./DataRoomTopicModel"
import { DataRoomTopicModelSelector } from "./DataRoomTopicModel.base"
import { IndustryModel, IndustryModelType } from "./IndustryModel"
import { IndustryModelSelector } from "./IndustryModel.base"
import { StageModel, StageModelType } from "./StageModel"
import { StageModelSelector } from "./StageModel.base"
import { TrendModel, TrendModelType } from "./TrendModel"
import { TrendModelSelector } from "./TrendModel.base"
import { RootStoreType } from "./index"


/**
 * FundBase
 * auto generated base class for the model FundModel.
 */
export const FundModelBase = ModelBase
  .named('Fund')
  .props({
    __typename: types.optional(types.literal("Fund"), "Fund"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    companies: types.union(types.undefined, types.array(types.late((): any => CompanyModel))),
    industries: types.union(types.undefined, types.array(types.late((): any => IndustryModel))),
    trend: types.union(types.undefined, types.array(types.late((): any => TrendModel))),
    dataroom_topics: types.union(types.undefined, types.array(types.late((): any => DataRoomTopicModel))),
    capital_deployed_perc_deployed_and_reserved: types.union(types.undefined, types.null, types.number),
    capital_deployed_perc_unallocated: types.union(types.undefined, types.null, types.number),
    moic: types.union(types.undefined, types.null, types.number),
    tvpi: types.union(types.undefined, types.null, types.number),
    gross_moic_on_initial_checks: types.union(types.undefined, types.null, types.number),
    moic_excl_6_months: types.union(types.undefined, types.null, types.number),
    capital_deployed: types.union(types.undefined, types.null, types.number),
    capital_deployed_change: types.union(types.undefined, types.null, types.number),
    fmv: types.union(types.undefined, types.null, types.number),
    fmv_change: types.union(types.undefined, types.null, types.number),
    portfolio_companies: types.union(types.undefined, types.null, types.number),
    new_portfolio_companies: types.union(types.undefined, types.null, types.number),
    months_since_first_close: types.union(types.undefined, types.null, types.number),
    show_capital_deployed: types.union(types.undefined, types.null, types.boolean),
    show_capital_deployed_change: types.union(types.undefined, types.null, types.boolean),
    show_fmv: types.union(types.undefined, types.null, types.boolean),
    show_fmv_change: types.union(types.undefined, types.null, types.boolean),
    show_moic: types.union(types.undefined, types.null, types.boolean),
    show_tvpi: types.union(types.undefined, types.null, types.boolean),
    show_gross_moic_on_initial_checks: types.union(types.undefined, types.null, types.boolean),
    show_moic_excl_6_months: types.union(types.undefined, types.null, types.boolean),
    show_months_since_first_close: types.union(types.undefined, types.null, types.boolean),
    show_new_portfolio_companies: types.union(types.undefined, types.null, types.boolean),
    show_portfolio_companies: types.union(types.undefined, types.null, types.boolean),
    last_update: types.union(types.undefined, types.null, types.frozen()),
    investment_by_stages: types.union(types.undefined, types.array(types.late((): any => StageModel))),
    seed_follow_on: types.union(types.undefined, types.null, types.number),
    seed_primary: types.union(types.undefined, types.null, types.number),
    series_a_primary: types.union(types.undefined, types.null, types.number),
    series_a_follow_on: types.union(types.undefined, types.null, types.number),
    series_b_primary: types.union(types.undefined, types.null, types.number),
    series_b_follow_on: types.union(types.undefined, types.null, types.number),
    reserved_but_not_deployed: types.union(types.undefined, types.null, types.number),
    unallocated: types.union(types.undefined, types.null, types.number),
    graph_early_growth: types.union(types.undefined, types.null, types.number),
    graph_growth: types.union(types.undefined, types.null, types.number),
    graph_late_growth: types.union(types.undefined, types.null, types.number),
    total_investable_capital: types.union(types.undefined, types.null, types.number),
    captial_deployed_footnote: types.union(types.undefined, types.null, types.string),
    fund_overview_footnote: types.union(types.undefined, types.null, types.string),
    capital_deployed_by_stage_footnote: types.union(types.undefined, types.null, types.string),
    capital_deployed_by_industry_footnote: types.union(types.undefined, types.null, types.string),
    advancement_initiative_portfolio_revenue_growth_footnote: types.union(types.undefined, types.null, types.string),
    portfolio_footnote: types.union(types.undefined, types.null, types.string),
    fmv_contribution_by_company_footnote: types.union(types.undefined, types.null, types.string),
    research_process_pipeline_footnote: types.union(types.undefined, types.null, types.string),
    capital_deployed_by_quarter_footnote: types.union(types.undefined, types.null, types.string),
    footnotes_and_disclosesures: types.union(types.undefined, types.null, types.string),
    /** Represents the total company value for the fund. It is a sumation of the total investment from each company under the fund. Technically, it is the total_investment from each round under all companies */
    total_investment_value: types.union(types.undefined, types.null, types.number),
    /** Represents the investment value for the fund. It is a sumation of the base10_invest(fmv) fields from each company under the fund. */
    total_company_value: types.union(types.undefined, types.null, types.number),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FundModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get capital_deployed_perc_deployed_and_reserved() { return this.__attr(`capital_deployed_perc_deployed_and_reserved`) }
  get capital_deployed_perc_unallocated() { return this.__attr(`capital_deployed_perc_unallocated`) }
  get moic() { return this.__attr(`moic`) }
  get tvpi() { return this.__attr(`tvpi`) }
  get gross_moic_on_initial_checks() { return this.__attr(`gross_moic_on_initial_checks`) }
  get moic_excl_6_months() { return this.__attr(`moic_excl_6_months`) }
  get capital_deployed() { return this.__attr(`capital_deployed`) }
  get capital_deployed_change() { return this.__attr(`capital_deployed_change`) }
  get fmv() { return this.__attr(`fmv`) }
  get fmv_change() { return this.__attr(`fmv_change`) }
  get portfolio_companies() { return this.__attr(`portfolio_companies`) }
  get new_portfolio_companies() { return this.__attr(`new_portfolio_companies`) }
  get months_since_first_close() { return this.__attr(`months_since_first_close`) }
  get show_capital_deployed() { return this.__attr(`show_capital_deployed`) }
  get show_capital_deployed_change() { return this.__attr(`show_capital_deployed_change`) }
  get show_fmv() { return this.__attr(`show_fmv`) }
  get show_fmv_change() { return this.__attr(`show_fmv_change`) }
  get show_moic() { return this.__attr(`show_moic`) }
  get show_tvpi() { return this.__attr(`show_tvpi`) }
  get show_gross_moic_on_initial_checks() { return this.__attr(`show_gross_moic_on_initial_checks`) }
  get show_moic_excl_6_months() { return this.__attr(`show_moic_excl_6_months`) }
  get show_months_since_first_close() { return this.__attr(`show_months_since_first_close`) }
  get show_new_portfolio_companies() { return this.__attr(`show_new_portfolio_companies`) }
  get show_portfolio_companies() { return this.__attr(`show_portfolio_companies`) }
  get last_update() { return this.__attr(`last_update`) }
  get seed_follow_on() { return this.__attr(`seed_follow_on`) }
  get seed_primary() { return this.__attr(`seed_primary`) }
  get series_a_primary() { return this.__attr(`series_a_primary`) }
  get series_a_follow_on() { return this.__attr(`series_a_follow_on`) }
  get series_b_primary() { return this.__attr(`series_b_primary`) }
  get series_b_follow_on() { return this.__attr(`series_b_follow_on`) }
  get reserved_but_not_deployed() { return this.__attr(`reserved_but_not_deployed`) }
  get unallocated() { return this.__attr(`unallocated`) }
  get graph_early_growth() { return this.__attr(`graph_early_growth`) }
  get graph_growth() { return this.__attr(`graph_growth`) }
  get graph_late_growth() { return this.__attr(`graph_late_growth`) }
  get total_investable_capital() { return this.__attr(`total_investable_capital`) }
  get captial_deployed_footnote() { return this.__attr(`captial_deployed_footnote`) }
  get fund_overview_footnote() { return this.__attr(`fund_overview_footnote`) }
  get capital_deployed_by_stage_footnote() { return this.__attr(`capital_deployed_by_stage_footnote`) }
  get capital_deployed_by_industry_footnote() { return this.__attr(`capital_deployed_by_industry_footnote`) }
  get advancement_initiative_portfolio_revenue_growth_footnote() { return this.__attr(`advancement_initiative_portfolio_revenue_growth_footnote`) }
  get portfolio_footnote() { return this.__attr(`portfolio_footnote`) }
  get fmv_contribution_by_company_footnote() { return this.__attr(`fmv_contribution_by_company_footnote`) }
  get research_process_pipeline_footnote() { return this.__attr(`research_process_pipeline_footnote`) }
  get capital_deployed_by_quarter_footnote() { return this.__attr(`capital_deployed_by_quarter_footnote`) }
  get footnotes_and_disclosesures() { return this.__attr(`footnotes_and_disclosesures`) }
  get total_investment_value() { return this.__attr(`total_investment_value`) }
  get total_company_value() { return this.__attr(`total_company_value`) }
  companies(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`companies`, CompanyModelSelector, builder) }
  industries(builder?: string | IndustryModelSelector | ((selector: IndustryModelSelector) => IndustryModelSelector)) { return this.__child(`industries`, IndustryModelSelector, builder) }
  trend(builder?: string | TrendModelSelector | ((selector: TrendModelSelector) => TrendModelSelector)) { return this.__child(`trend`, TrendModelSelector, builder) }
  dataroom_topics(builder?: string | DataRoomTopicModelSelector | ((selector: DataRoomTopicModelSelector) => DataRoomTopicModelSelector)) { return this.__child(`dataroom_topics`, DataRoomTopicModelSelector, builder) }
  investment_by_stages(builder?: string | StageModelSelector | ((selector: StageModelSelector) => StageModelSelector)) { return this.__child(`investment_by_stages`, StageModelSelector, builder) }
}
export function selectFromFund() {
  return new FundModelSelector()
}

export const fundModelPrimitives = selectFromFund().name.capital_deployed_perc_deployed_and_reserved.capital_deployed_perc_unallocated.moic.tvpi.gross_moic_on_initial_checks.moic_excl_6_months.capital_deployed.capital_deployed_change.fmv.fmv_change.portfolio_companies.new_portfolio_companies.months_since_first_close.show_capital_deployed.show_capital_deployed_change.show_fmv.show_fmv_change.show_moic.show_tvpi.show_gross_moic_on_initial_checks.show_moic_excl_6_months.show_months_since_first_close.show_new_portfolio_companies.show_portfolio_companies.last_update.seed_follow_on.seed_primary.series_a_primary.series_a_follow_on.series_b_primary.series_b_follow_on.reserved_but_not_deployed.unallocated.graph_early_growth.graph_growth.graph_late_growth.total_investable_capital.captial_deployed_footnote.fund_overview_footnote.capital_deployed_by_stage_footnote.capital_deployed_by_industry_footnote.advancement_initiative_portfolio_revenue_growth_footnote.portfolio_footnote.fmv_contribution_by_company_footnote.research_process_pipeline_footnote.capital_deployed_by_quarter_footnote.footnotes_and_disclosesures.total_investment_value.total_company_value
