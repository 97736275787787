import { createHttpClient } from "mst-gql";
import { RootStore, defaultRootStoreValues } from "../models";
import { BACKEND_URL } from "../utils/variables";
import { persist } from "mst-persist";

export const mstStore = RootStore.create(defaultRootStoreValues, {
  gqlHttpClient: createHttpClient(BACKEND_URL),
});

persist("base10-17", mstStore, {
  storage: localStorage,
  blacklist: ["__queryCache"],
});

// Used for debugging purposes
// @ts-ignore
window.store = mstStore;
