/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { IndustryModel, IndustryModelType } from "./IndustryModel"
import { IndustryModelSelector } from "./IndustryModel.base"
import { TrendModel, TrendModelType } from "./TrendModel"
import { TrendModelSelector } from "./TrendModel.base"
import { RootStoreType } from "./index"


/**
 * FinalDilligenceCompanyBase
 * auto generated base class for the model FinalDilligenceCompanyModel.
 */
export const FinalDilligenceCompanyModelBase = ModelBase
  .named('FinalDilligenceCompany')
  .props({
    __typename: types.optional(types.literal("FinalDilligenceCompany"), "FinalDilligenceCompany"),
    id: types.union(types.undefined, types.integer),
    industry_id: types.union(types.undefined, types.null, types.integer),
    industry: types.union(types.undefined, types.null, types.late((): any => IndustryModel)),
    trend_id: types.union(types.undefined, types.null, types.integer),
    trend: types.union(types.undefined, types.null, types.late((): any => TrendModel)),
    name: types.union(types.undefined, types.string),
    url: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FinalDilligenceCompanyModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get industry_id() { return this.__attr(`industry_id`) }
  get trend_id() { return this.__attr(`trend_id`) }
  get name() { return this.__attr(`name`) }
  get url() { return this.__attr(`url`) }
  industry(builder?: string | IndustryModelSelector | ((selector: IndustryModelSelector) => IndustryModelSelector)) { return this.__child(`industry`, IndustryModelSelector, builder) }
  trend(builder?: string | TrendModelSelector | ((selector: TrendModelSelector) => TrendModelSelector)) { return this.__child(`trend`, TrendModelSelector, builder) }
}
export function selectFromFinalDilligenceCompany() {
  return new FinalDilligenceCompanyModelSelector()
}

export const finalDilligenceCompanyModelPrimitives = selectFromFinalDilligenceCompany().industry_id.trend_id.name.url
