import * as React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, BLACK_COLOR, RED_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "../../utils/theme";
import { useState } from "react";

interface AppTextAreaProps {
  currency?: boolean;
  error?: string;
  width?: number;
  fontSize?: number;
}

export const StyledAppTextArea = styled.textarea<AppTextAreaProps>`
  width: 100%;
  height: 150px;
  padding-left: ${(props) => (props.currency ? 25 : 5)}px;
  border-width: 0;
  border-color: transparent;
  border-bottom-color: ${(props) => (props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  border-bottom-width: 2px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 36)}px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  box-sizing: border-box;
  background-color: transparent;
  color: ${BLACK_COLOR};
  resize: none;
  caret-color: ${PRIMARY_COLOR};
  :focus {
    border-bottom-color: ${PRIMARY_COLOR};
    outline: none;
  }
  ::placeholder {
    opacity: 0.4;
  }
`;

export const AppTextArea = ({
  currency = false,
  title,
  error,
  ...props
}: AppTextAreaProps & React.HTMLProps<HTMLTextAreaElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  return (
    <Div>
      {!!title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      {currency && (
        <LeftText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          $
        </LeftText>
      )}
      {currency && (
        <RightText // error={!!errors[field.name] && !!touched[field.name]}
          error={!!error}
          focused={isFocused}
        >
          M
        </RightText>
      )}
      <StyledAppTextArea
        {...(props as any)}
        onFocus={toggleFocus}
        onBlur={(e) => {
          toggleFocus();
          !!props.onBlur && props.onBlur(e);
        }}
        currency={currency}
        title={title}
        error={error}
      />
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

const Div = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
}

const LeftText = styled.p<TextFocus>`
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  color: ${(props) => (props.focused ? PRIMARY_COLOR : props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

const RightText = styled.p<TextFocus>`
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  color: ${(props) => (props.focused ? PRIMARY_COLOR : props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

RightText.defaultProps = {
  focused: false,
  error: false,
};

LeftText.defaultProps = {
  focused: false,
  error: false,
};

// TODO: Multiline (TextArea)
// TODO: Add font to theme

const InputTitle = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const InputErrorText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${RED_COLOR};
  margin: 0;
`;
