import React, { FunctionComponent, useContext, useEffect } from "react";
import { StoreContext } from "../../models/reactUtils";

const Logout: FunctionComponent = () => {
  const store = useContext(StoreContext);
  useEffect(() => {
    store.logout();
  }, []);
  return <div />;
};

export { Logout };
