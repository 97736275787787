import { AuthActionTypes, Auth } from "../types";

// Auth Reducer
// ------------
// Allows user to login or logout
// Also handle errors in this reducer

const defaultAuthReducerState: Auth = {
  token: null,
  error: "",
  is_Admin: null,
};

const authReducer = (state = defaultAuthReducerState, action: AuthActionTypes): Auth => {
  console.log("action: ", action);
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        token: action.access_token,
        error: "",
        is_Admin: action.is_Admin,
      };
    case "LOGOUT":
      return {
        token: null,
        error: "",
        is_Admin: null,
      };
    case "ERROR":
      return { ...state, error: action.error };
    default:
      return state;
  }
};

export default authReducer;
