/**
 * Fund I, Fund II, Fund III
 * @param fund_id 
 * @returns 
 */
export const returnIfNormalFund = (fund_id: number | string) => {
  return fund_id === 12 || fund_id === 13 || fund_id === 19;
};

/**
 * @returns the string until the colon
 */
export const formatError = (str?: string) => {
  if (!str) {
    return "";
  }
  if (str.indexOf(":") === -1) {
    return str;
  }
  return str.substring(0, str.indexOf(":"));
};

export const returnIfnotAdvancementInitiativeI = (fund_id: number | string) => {
  return fund_id !== 14;
};
