import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Wrapper } from "../UI";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, SECONDARY_FONT } from "../../utils/theme";
import { FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer: FunctionComponent = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <BottomPageWrapper>
          <BottomInfoLarge>2020 © BASE10 PARTNERS</BottomInfoLarge>
          <BottomTwitter>
            <StyledSocialLink href="https://twitter.com/Base10Partners">
              <FaTwitter color={BLACK_COLOR} size={20} />
            </StyledSocialLink>
          </BottomTwitter>
          <BottomNav>
            <BottomNavUl>
              <BottomNavLi>
                <BottomNavLink to="/terms-of-use">Terms of Use</BottomNavLink>
              </BottomNavLi>
            </BottomNavUl>
          </BottomNav>
          <BottomInfoMobile>2020 © BASE10 PARTNERS</BottomInfoMobile>
        </BottomPageWrapper>
      </Wrapper>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  padding-top: 54px;
  padding-bottom: 114px;
  width: 100%;
  display: block;
  background: #ffffff;
`;
const BottomPageWrapper = styled.div`
  position: relative;
  display: block;
  :before {
    content: "";
    position: absolute;
    top: -54px;
    right: 30px;
    left: 30px;
    height: 1px;
    background-color: rgba(26, 26, 26, 0.1);
  }
  :after {
    clear: both;
    content: "";
    display: block;
  }
  @media ${mobileSize} {
    :before {
      top: -80px;
      right: 20px;
      left: 20px;
    }
  }
`;

const BottomInfoLarge = styled.div`
  width: calc(41.66667% - 43px);
  float: left;
  margin-left: 30px;
  color: rgba(26, 26, 26, 0.4);
  font-size: 12px;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 34px;
    text-align: center;
    display: none !important;
  }
`;

const BottomInfoMobile = styled.div`
  color: rgba(26, 26, 26, 0.4);
  display: none;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 34px;
    text-align: center;
    display: block;
  }
`;

const BottomTwitter = styled.div`
  width: calc(16.66667% - 35px);
  float: left;
  margin-left: 30px;
  text-align: center;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const BottomNav = styled.div`
  width: calc(41.66667% - 43px);
  float: left;
  margin-left: 30px;
  text-align: right;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 34px;
    text-align: center;
  }
`;

const BottomNavUl = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  display: block;
`;

const BottomNavLi = styled.li`
  display: inline-block;
`;

const BottomNavLink = styled(Link)`
  color: rgba(26, 26, 26, 0.6);
  text-decoration: none;
  font-family: ${SECONDARY_FONT};
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 2px;
  transition: color 0.25s, opacity 0.25s;
  @media ${mobileSize} {
    line-height: 1.16667;
    letter-spacing: 1px;
    display: inline-block;
    vertical-align: middle;
  }
  :hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledSocialLink = styled.a`
  opacity: 0.2;
  display: inline-block;
  vertical-align: middle;
  color: ${BLACK_COLOR};
  transition: color 0.25s, opacity 0.25s;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export { Footer };
