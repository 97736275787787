import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Header } from "../Components/Segments";
import { observer } from "mobx-react";
import { StoreContext } from "../models/reactUtils";

// Public Route
// See AppRouter for more information

interface PublicRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PublicRouteProps;

export const PublicRouteComponent = ({
  component: Component,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  // const { setQuery, loading, error, data } = useQuery();

  const store = useContext(StoreContext);

  const { is_authenticated } = store;
  console.log("PubicRoute is_authenticated: ", is_authenticated);
  console.log("PubicRoute path: ", rest.path);

  return (
    <div>
      <Header />
      <Route
        {...rest}
        component={(props: any) =>
          !is_authenticated ? <Component {...props} /> : <Redirect to="/investor-dashboard" />
        }
      />
    </div>
  );
};

const PublicRoute = observer(PublicRouteComponent);

export default PublicRoute;
