import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, Loading, AppErrorText } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormInputField } from "../Forms";
import { useQuery } from "../../models/reactUtils";
import { FundModelType } from "../../models";
import { toast } from "react-toastify";

const NewFundSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

interface NewFundModalProps {
  showModal: boolean;
  toggleModal: () => void;
}

interface MyFormikProps {
  name: string;
}

const NewFundModal: FunctionComponent<NewFundModalProps> = observer(
  ({ showModal, toggleModal, fund }: { showModal: boolean; toggleModal: () => void; fund: FundModelType }) => {
    const { setQuery, loading, error, store } = useQuery();

    return (
      <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
        <CloseModal onClick={toggleModal}>
          <AiOutlineClose size={20} color={"black"} />
        </CloseModal>
        <NewFundContainer>
          <ModalWrapper>
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={NewFundSchema}
              onSubmit={async (values, { setFieldError, setStatus }) => {
                console.log("SUBMIT THE NEW FUND FORM", values);
                //data.fund.addFund(values.name)
                const mutation = store.mutateCreateOneFund({
                  data: { name: values.name },
                });
                mutation.then((v) => {
                  toast("Fund created!");
                  store.localAddFund({
                    id: v.createOneFund.id,
                    name: v.createOneFund.name,
                  });
                  toggleModal();
                });
                setQuery(mutation);
                //fund.addFund(values.name);
              }}
            >
              {({
                status,
                setStatus,
                handleSubmit,
                handleChange,
                submitForm,
                isSubmitting,
                values,
              }: FormikProps<MyFormikProps>) => {
                console.log("values", values);
                return (
                  <div>
                    <TitleContainer>
                      <Title>Create new Fund</Title>
                    </TitleContainer>
                    <FormContainer>
                      <FormInputField
                        name={"name"}
                        // fontSize={20}
                        placeholder="Name your fund"
                        noTopMargin
                        textAlign="center"
                      />
                    </FormContainer>
                    <SubmitContainer>
                      {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                      {error && <AppErrorText>{error}</AppErrorText>}
                    </SubmitContainer>
                  </div>
                );
              }}
            </Formik>
          </ModalWrapper>
        </NewFundContainer>
      </Modal>
    );
  },
);

const NewFundContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0px;
`;

export { NewFundModal };
