import React from "react";
import { useField } from "formik";
import { AppFormTitle } from "../UI";

interface CheckboxFieldProps {
  name: string;
  title?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  textAlign?: string;
}

const CheckboxField = ({ style, title, ...props }: CheckboxFieldProps) => {
  const [field] = useField(props.name);
  return (
    <div style={style}>
      {!!title && <AppFormTitle style={{ margin: 0 }}>{title.toUpperCase()}</AppFormTitle>}
      <input name={field.name} type="checkbox" checked={field.value} onChange={field.onChange} {...field} {...props} />
    </div>
  );
};

export { CheckboxField };
