import React from "react";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import { GREY_COLOR } from "../../utils/theme";

interface ProfileImageProps {
  src: string;
}

export const ProfileImage = (props: ProfileImageProps) => {
  return (
    <ProfileImageContainer src={props.src}>
      {!props.src && <FaUser size={50} color={"#ffffff"} />}
    </ProfileImageContainer>
  );
};

const ProfileImageContainer = styled.div<ProfileImageProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.src ? `transparent` : `${GREY_COLOR}`)};
  background-image: ${(props) => (props.src ? `url(${props.src})` : `${GREY_COLOR}`)};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

// const ProfileImage = styled.img``;
