import React, { useContext, useEffect, SFC, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AppText, Loading } from "../../UI";
import { SmartListComponent } from "../../Smart";
import { ModalContext } from "../../../context";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import { PRIMARY_COLOR } from "../../../utils/theme";
import { AddCompanyModal } from "../../Modals";

interface Props {
  fund_id: number;
}

const PortfolioComponent: SFC<Props> = ({ fund_id }) => {
  // const { toggleCompanyModal, companyModalVisible } = useContext(ModalContext);
  const [companyModalVisible, toggleCompanyModal] = useState(false);
  // Used as a refetch hack
  const [refetch, setRefetch] = useState(0);
  const {
    store,
    store: { companies, companiesError, companiesLoading, fetchCompanies },
  } = useQuery();

  useEffect(() => {
    fetchCompanies();
  }, [fund_id, refetch]);

  if (companiesLoading) {
    return <Loading />;
  }
  if (companiesError) {
    return <p>Could not fetch companies</p>;
  }
  return (
    <>
      <AddCompanyModal
        toggleModal={() => toggleCompanyModal(false)}
        showModal={companyModalVisible}
        fund_id={store.selected_fund.id}
        // refetches companies query after the form is completed
        onFinish={() => setRefetch(Math.random())}
      />
      <PortfolioContainer modalActive={companyModalVisible}>
        <PortfolioHeader>
          <PortfolioHeaderTitle>{`${companies.length} Compan${
            companies.length === 1 ? "y" : "ies"
          }`}</PortfolioHeaderTitle>
          <AddCompany onClick={() => toggleCompanyModal(true)}>+ Add Company</AddCompany>
        </PortfolioHeader>
        <ListContainer>
          {!!companies.length ? (
            <SmartListComponent type={"company"} data={companies} />
          ) : (
            <NoCompanyNoticeContainer>
              <NoCompanyNoticeText>
                {`You haven't created companies for this fund yet. Start creating now!`}
              </NoCompanyNoticeText>
            </NoCompanyNoticeContainer>
          )}
        </ListContainer>
      </PortfolioContainer>
    </>
  );
};

interface ContainerState {
  modalActive: boolean;
}

const PortfolioContainer = styled.div<ContainerState>`
  min-height: 500px;
  overflow: ${(props) => (props.modalActive ? "hidden" : "visible")};
  height: ${(props) => (props.modalActive ? "80vh" : "100%")};
`;

const PortfolioHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PortfolioHeaderTitle = styled(AppText)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const AddCompany = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  position: relative;
  cursor: pointer;
  border-bottom: none;
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  min-height: 300px;
  display: flex;
  justify-content: center;
`;

const NoCompanyNoticeContainer = styled.div`
  width: 550px;
`;

const NoCompanyNoticeText = styled(AppText)`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.4;
  text-align: center;
`;

const Portfolio = observer(PortfolioComponent);

export { Portfolio };
