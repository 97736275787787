import * as React from "react";
import styled from "styled-components";
import { AppPanelTitle, AppText, AppFormTitle, AppTitle, ToolTipContainer, Loading } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect, useState } from "react";

import { PRIMARY_FONT, BLACK_COLOR } from "../../../utils/theme";
import ReactTooltip from "react-tooltip";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import { roundNumber } from "../../../utils/format";
import { desktopSize, laptopSize, smallMobileSize } from "../../../utils/breakpoints";
import { returnIfNormalFund } from "../../Dumb/utils";
import { PanelFooter } from "../../Dumb/PanelContent";

interface FMVContributinosByCompanyProps {
  fund_id: number;
  total_investment_value: number;
  total_company_value: number;
}

const FMVContributinosByCompanyPanel: FunctionComponent<FMVContributinosByCompanyProps> = (props) => {
  const { store } = useQuery();
  const [currentTooltip, setCurrentTooltip] = useState(0);
  const [currentlyHovering, setCurrentlyHovering] = useState(false);

  useEffect(() => {}, [currentTooltip, currentTooltip]);

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
    setQuery: companyQuery,
  } = useQuery((store) =>
    store.queryCompaniesByFund(
      { fundId: props.fund_id },
      (qb) => qb.base10_fmv.color.bw_logo_url.name.company_total_investment.base10_total_investment.logo_url,
    ),
  );

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.fmv_contribution_by_company_footnote),
  );

  useEffect(() => {
    const query = store.queryCompaniesByFund(
      { fundId: props.fund_id },
      (qb) => qb.base10_fmv.color.bw_logo_url.name.company_total_investment.base10_total_investment.logo_url,
    );
    companyQuery(query);
  }, [props.fund_id]);

  useEffect(() => {
    const query = store.queryFund({ where: { id: props.fund_id } }, (qb) => qb.fmv_contribution_by_company_footnote);
    fundQuery(query);
  }, [props.fund_id]);

  if (companyLoading || fundLoading) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (companyError || !companyData || !companyData.companiesByFund || fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  const { companiesByFund } = companyData;

  const {
    fund: { fmv_contribution_by_company_footnote },
  } = fundData;

  const maxHeightCompaniesFMV = !!companiesByFund.length
    ? companiesByFund.map((item) => item.base10_fmv).reduce((a: any, b: any) => a + b)
    : 20;

  return (
    <DynamicPanelContainer wide={!returnIfNormalFund(props.fund_id)}>
      <DynamicPanelWrapper wide={!returnIfNormalFund(props.fund_id)}>
        <AppPanelTitle>FMV CONTRIBUTION BY COMPANY ($M)</AppPanelTitle>
        <PanelDescription>Hover the mouse over columns to see portfolio company cost and FMV.</PanelDescription>
        <PanelContent>
          <ChartContainer>
            <ChartInnerWrapper wide={!returnIfNormalFund(props.fund_id)}>
              <ChartTitle>Total Invested vs. FMV</ChartTitle>
              <ChartInfoContainer>
                <GraphDiv>
                  <ChartInfoItem>
                    <ChartInfoItemInnerContainer>
                      <LeftChartInfoItemContent>
                        <ChartInfoItemContentValue>
                          ${roundNumber(companiesByFund.reduce((a, b) => a + b.base10_total_investment, 0))}M
                        </ChartInfoItemContentValue>
                        <ChartInfoItemContentLabel>Total Invested</ChartInfoItemContentLabel>
                      </LeftChartInfoItemContent>
                      <ChartBarContainer>
                        <ChartBar
                          onMouseEnter={() => setCurrentlyHovering(true)}
                          onMouseLeave={() => setCurrentlyHovering(false)}
                        >
                          {companiesByFund
                            .slice()
                            .sort((a, b) => b.base10_fmv - a.base10_fmv)
                            .map((company, index) => {
                              return (
                                <ChartBarItem
                                  key={company.id}
                                  height={(company.base10_total_investment / maxHeightCompaniesFMV) * 400}
                                  data-tip
                                  data-for={`company_invested_${index}`}
                                  onMouseOver={() => setCurrentTooltip(index)}
                                  hover={currentlyHovering}
                                  selected={currentTooltip === index}
                                >
                                  <ChartBarItemBackground color={company.color} />
                                </ChartBarItem>
                              );
                            })}
                        </ChartBar>
                      </ChartBarContainer>
                    </ChartInfoItemInnerContainer>
                  </ChartInfoItem>
                  <TooltipDiv></TooltipDiv>
                  <ChartInfoItem>
                    <ChartInfoItemInnerContainer>
                      <ChartBarContainer>
                        <ChartBar
                          onMouseEnter={() => setCurrentlyHovering(true)}
                          onMouseLeave={() => setCurrentlyHovering(false)}
                        >
                          {companiesByFund
                            .slice()
                            .sort((a, b) => b.base10_fmv - a.base10_fmv)
                            .map((company, index) => {
                              return (
                                <>
                                  <div style={{ position: "relative" }}>
                                    <ToolTipSuperContainer
                                      key={company.id}
                                      style={{
                                        position: "absolute",
                                        left: -180,
                                        top: -(205 + company.base10_fmv * 2.5),
                                        zIndex: 2000,
                                        display: currentlyHovering && index === currentTooltip ? "flex" : "none",
                                      }}
                                    >
                                      <ToolTipContainer>
                                        <TooltipImageContainer color={company.color}>
                                          <LogoContainer>
                                            <Logo src={company.bw_logo_url} />
                                          </LogoContainer>
                                        </TooltipImageContainer>
                                        <TootipValueContainer>
                                          <TooltipValueItem>
                                            <TooltipValueItemValue>
                                              ${roundNumber(company.base10_total_investment)}
                                            </TooltipValueItemValue>
                                            <TooltipValueItemLabel>Invested</TooltipValueItemLabel>
                                          </TooltipValueItem>
                                          <TooltipValueItem>
                                            <TooltipValueItemValue>
                                              ${roundNumber(company.base10_fmv)}
                                            </TooltipValueItemValue>
                                            <TooltipValueItemLabel>Company Value</TooltipValueItemLabel>
                                          </TooltipValueItem>
                                        </TootipValueContainer>
                                      </ToolTipContainer>
                                    </ToolTipSuperContainer>
                                  </div>
                                  <ChartBarItem
                                    key={company.id}
                                    height={(company.base10_fmv / maxHeightCompaniesFMV) * 400}
                                    onMouseEnter={() => setCurrentTooltip(index)}
                                    hover={currentlyHovering}
                                    selected={currentTooltip === index}
                                  >
                                    <ChartBarItemBackground color={company.color} />
                                  </ChartBarItem>
                                </>
                              );
                            })}
                        </ChartBar>
                      </ChartBarContainer>
                      <RightChartInfoItemContent>
                        <ChartInfoItemContentValue>
                          ${roundNumber(companiesByFund.reduce((a, b) => a + b.base10_fmv, 0))}M
                        </ChartInfoItemContentValue>
                        <ChartInfoItemContentLabel>Company Value</ChartInfoItemContentLabel>
                      </RightChartInfoItemContent>
                    </ChartInfoItemInnerContainer>
                  </ChartInfoItem>
                </GraphDiv>
                <ChartLegendContainer>
                  <ChartLegendWrapper wide={!returnIfNormalFund(props.fund_id)}>
                    {companiesByFund
                      .slice()
                      .sort((a, b) => a.base10_fmv - b.base10_fmv)
                      .map((company) => {
                        return (
                          <LegendItem key={company.id}>
                            <InnerLegendItem>
                              <LegendColorContainer>
                                <ColorItem color={company.color} />
                              </LegendColorContainer>
                              <LegendLabel>
                                <LegendText>{company.name}</LegendText>
                              </LegendLabel>
                            </InnerLegendItem>
                          </LegendItem>
                        );
                      })}
                  </ChartLegendWrapper>
                </ChartLegendContainer>
              </ChartInfoContainer>
            </ChartInnerWrapper>
          </ChartContainer>
        </PanelContent>
        <FooterText>{fmv_contribution_by_company_footnote}</FooterText>
      </DynamicPanelWrapper>
    </DynamicPanelContainer>
  );
};

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.5;
  margin-top: 80px;
`;

interface WideProp {
  wide: boolean;
}

const DynamicPanelContainer = styled(HalfPanelContainer)<WideProp>`
  // width: ${(props) => (props.wide ? "calc(75% - 45px)" : "calc(50% - 45px)")};
  width: 100%;
  height: 100%;
  margin: 0px;
  /* padding: ${(props) => (props.wide ? "100px" : "0px")}; */
`;

const DynamicPanelWrapper = styled(PanelWrapper)<WideProp>`
  padding: ${(props) => (props.wide ? "30px 160px" : "30px")};
  @media ${laptopSize} {
    margin-bottom: 0px;
    padding: 30px;
  }
`;

const PanelDescription = styled(AppFormTitle)`
  font-family: ${PRIMARY_FONT};
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  color: rgba(26, 26, 26, 0.4);
  letter-spacing: 0;
`;

const ChartContainer = styled.div`
  width: 100%;
  list-style: none;
`;

const ChartInnerWrapper = styled.div<WideProp>`
  width: 100%;
  float: left;
  min-height: 254px;
  margin-bottom: ${(props) => (props.wide ? "50px" : "0px")};
  @media ${desktopSize} {
    margin-bottom: 30px;
  }
  @media ${laptopSize} {
    margin-bottom: 0px;
  }
`;

const ChartTitle = styled(AppTitle)`
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 17px;
`;

const ChartInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ChartInfoItem = styled.div`
  width: 100%;
  float: left;
  margin-top: 0px;
  border-bottom: 2px solid ${BLACK_COLOR};
  padding-bottom: 15px;
  @media ${smallMobileSize} {
  }
`;

const ChartInfoItemInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const LeftChartInfoItemContent = styled.div`
  display: table-cell;
  vertical-align: bottom;
  width: 100%;
  @media ${smallMobileSize} {
  }
`;

const RightChartInfoItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  vertical-align: bottom;
  width: 100%;
  text-align: right;
  @media ${smallMobileSize} {
  }
`;

const ChartInfoItemContentValue = styled(AppText)`
  font-weight: 700;
  color: ${BLACK_COLOR};
  white-space: nowrap;
  font-size: 28px;
  line-height: 1.28571;
  letter-spacing: 0;
  margin-right: 0;
  @media ${desktopSize} {
    font-size: 14px;
  }
`;

const ChartInfoItemContentLabel = styled(AppText)`
  font-weight: 300;
  color: ${BLACK_COLOR};
  font-size: 14px;
  letter-spacing: 0;
  @media ${desktopSize} {
    font-size: 11px;
  }
`;

const ChartBarContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  width: 60px;
`;

interface BarItemProps {
  height: number;
  hover: boolean;
  selected: boolean;
}

const ChartBarItem = styled.div<BarItemProps>`
  display: flex;
  /* position: relative; */
  /* vertical-align: top; */
  height: ${(props) => `${props.height}px`};
  opacity: ${(props) => (props.hover ? (props.selected ? 1.0 : 0.4) : 1.0)};
  width: 100%;
  z-index: 1;
  filter: ${(props) => (props.hover ? (props.selected ? "none" : "grayscale(100%)") : "none")};
  /* transition: filter 0.25s, opacity 0.25s, -webkit-filter 0.25s; */
  /* :hover {
    filter: none !important;
    opacity: 1;
  } */
`;

const ChartBar = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  height: 400px;
  width: 60%;
  max-width: 100%;
  min-width: 56px;
`;

interface ChartBarItemBackgroundProps {
  color: string;
}

const ChartBarItemBackground = styled.div<ChartBarItemBackgroundProps>`
  background-color: ${(props) => props.color};
  height: 100%;
  width: 100%;
  transition: filter 0.25s, opacity 0.25s, -webkit-filter 0.25s;
`;

const ChartLegendContainer = styled.div`
  width: 30%;
  float: left;
  @media ${smallMobileSize} {
    display: none;
    width: 0;
  }
`;

const LegendItem = styled.div`
  display: block;
  max-width: 100%;
  /* vertical-align: top; */
`;

const ChartLegendWrapper = styled.div<WideProp>`
display: block;
padding-left: ${(props) => (props.wide ? "70px" : "0px")};
@media ${laptopSize} {
padding-left: 0px;
}
${LegendItem}+${LegendItem} {
  margin-top: 4px;
    margin-left: 0;}
`;

const InnerLegendItem = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const LegendColorContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  width: 10px;
  padding-top: 2px;
`;

interface Color {
  color: string;
}

const ColorItem = styled.div<Color>`
  background-color: ${(props) => props.color};
  height: 10px;
  width: 10px;
  border-radius: 50%;
`;

const LegendLabel = styled.div`
  height: auto;
  max-height: 100%;
  padding-left: 10px;
`;

const LegendText = styled(AppText)`
  font-size: 12px;
  line-height: 1.25;
  color: rgba(26, 26, 26, 0.8);
`;

const TooltipImageContainer = styled.div<Color>`
  display: table;
  width: 100%;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  padding-bottom: 4px;
  border-color: ${(props) => props.color};
`;

const LogoContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding-right: 0;
`;

const Logo = styled.img`
  border-style: none;
  display: block;
  max-width: 75%;
  max-height: 30px;
`;

const TooltipValueItem = styled.div`
  display: block;
`;
const TootipValueContainer = styled.div`
    display: block;
    margin-top: 14px;
    ${TooltipValueItem}+${TooltipValueItem}{
      margin-top:10px
    }
`;

const TooltipValueItemValue = styled(AppText)`
  font-size: 18px;
  color: #ffffff;
`;
const TooltipValueItemLabel = styled(AppText)`
  font-size: 12px;
  color: #ffffff;
`;

const GraphDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
`;

const TooltipDiv = styled.div`
  position: relative;
  width: 80px;
  @media ${smallMobileSize} {
    width: 40px;
  }
`;

const ToolTipSuperContainer = styled.div`
  position: relative;
  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 8px #000;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    @media ${smallMobileSize} {
      margin-left: -2px;
    }
  }
`;

const FMVContributinosByCompanyComponent = observer(FMVContributinosByCompanyPanel);

export { FMVContributinosByCompanyComponent };
