import React, { SFC, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  CloseModal,
  ModalWrapper,
  AppTitle,
  AppButton,
  AnimatedText,
  Loading,
  AppErrorText,
  AppText,
} from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, FormikProps, FieldArray } from "formik";
import { FormInputField, FormSelectField, FormDatePicker, FormAreaInputField, ImageUploadField } from "../Forms";
import { TrashIcon } from "../Dumb";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";
import { FundingRoundCoinvestorModelType } from "../../models";
import * as Yup from "yup";

interface EditRoundModalProps {
  showModal: boolean;
  toggleModal: () => void;
  /**
   * Company id for the new fund
   */
  id: number;
  /**
   * Fund id for the fund being editted
   */
  round_id: number;
}

const RoundSchema = Yup.object().shape({
  stage: Yup.string().required("* Please select a stage").notOneOf(["0"], "* Please select a Stage"),
  round_number: Yup.number()
    .integer("* Please choose a whole number")
    .required("* Please select a round number")
    .min(0, "* Please choose a positive integer")
    .notOneOf(["0"], "* Please choose a positive integer"),
});

interface MyFormikProps {
  stage: string;
  round_number: number;
  total_investment: number;
  base10_invest: number;
  funding_date: Date | null;
  ownership: number;
  valuation: number;
  coinvestors: FundingRoundCoinvestorModelType[];
  footnote: string;
}

const EditRoundModalComponent: SFC<EditRoundModalProps> = ({ showModal, toggleModal, id, round_id }) => {
  const { setQuery, loading, error, store } = useQuery();
  const { data: stageData, loading: stageLoading, error: stageError } = useQuery((store) =>
    store.queryStages({}, (qb) => qb.id.name),
  );

  useEffect(() => {
    // Refetch
  }, [id, showModal]);

  if (!showModal) {
    return null;
  }
  if (stageLoading) {
    return <Loading />;
  }
  if (stageError) {
    return <p>Error fetching Stages</p>;
  }
  const stage_list = stageData.stages.map((s) => ({
    label: s.name,
    value: s.name,
  }));

  const fundingRound = store.companies.filter((c) => c.id === id)[0].funding_rounds.filter((f) => f.id === round_id)[0];
  console.log("fundingRound: ", fundingRound);

  const deleteFundingRound = () => {
    const mutation = store.mutateDeleteOneFundingRound({
      where: { id: round_id },
    });
    mutation.then(
      (v) => {
        toast("Funding Round Deleted!");
        store.companies.filter((c) => c.id === id)[0].localDeleteFund(v.deleteOneFundingRound.id);
        toggleModal();
      },
      (e) => {
        toast("Could not delete Funding Round!");
      },
    );
    setQuery(mutation);
  };

  return (
    <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
      <CloseModal onClick={toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <RoundContainer>
        <ModalWrapper>
          <Formik
            initialValues={{
              stage: fundingRound.stage,
              round_number: fundingRound.round_number,
              total_investment: fundingRound.total_investment,
              base10_invest: fundingRound.base10_invest,
              ownership: fundingRound.ownership,
              valuation: fundingRound.valuation,
              funding_date: fundingRound.funding_date,
              coinvestors: fundingRound.coinvestors || [],
              footnote: fundingRound.footnote,
            }}
            validationSchema={RoundSchema}
            onSubmit={async ({ coinvestors, ...round }: MyFormikProps) => {
              const mutation = store.mutateUpdateRound(
                {
                  round: {
                    coinvestors: coinvestors.filter((c) => !!c.name).map(({ __typename, ...c }) => c),
                    round: {
                      ...round,
                      id: round_id,
                    },
                  },
                },
                (qb) =>
                  qb.base10_invest.base10_involved.coinvestors((c) => c.id.name.url).company_id.footnote.funding_date.id
                    .month_year.ownership.round_number.stage.total_investment.valuation,
              );
              mutation.then(
                (v) => {
                  store.companies.filter((c) => c.id === id)[0].localUpdateFund({ ...v.updateRound });
                  toast("Round updated!");
                  toggleModal();
                },
                (e) => {
                  toast("Could not save fund!");
                },
              );
              setQuery(mutation);
            }}
          >
            {({ submitForm, values }: FormikProps<MyFormikProps>) => {
              console.log("values", values);
              return (
                <FormContainer>
                  <TitleContainer>
                    <Title>Edit round</Title>
                  </TitleContainer>
                  <div>
                    <Split>
                      <FormSelectField
                        name={`stage`}
                        title="STAGE"
                        placeholder="Choose stage"
                        width={330}
                        fontSize={20}
                        options={stage_list}
                        type="number"
                      />
                      <FormInputField
                        width={170}
                        title="TOTAL INVEST ($M)"
                        name={`total_investment`}
                        fontSize={20}
                        placeholder="0.0"
                        type="number"
                        noTopMargin
                      />
                      <FormInputField
                        width={170}
                        title="BASE10 INVEST ($M)"
                        name={`base10_invest`}
                        fontSize={20}
                        placeholder="0.0"
                        noTopMargin
                        type="number"
                      />
                    </Split>
                    <Split
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <FormDatePicker name={`funding_date`} title="FUNDING DATE" width={210} />
                      <FormInputField
                        width={80}
                        title="ROUND #"
                        name={`round_number`}
                        fontSize={20}
                        style={{ marginRight: 30 }}
                        placeholder="1"
                        noTopMargin
                        type="number"
                      />
                      <Split>
                        <FormInputField
                          style={{
                            gridColumn: "1 / span 1",
                            gridRow: "1 / span 1",
                            marginRight: 30,
                          }}
                          width={170}
                          title="OWNERSHIP (%)"
                          name={`ownership`}
                          value={values.ownership?.toFixed(1)}
                          step={0.1}
                          fontSize={20}
                          placeholder="0"
                          type="number"
                          noTopMargin
                        />
                        <FormInputField
                          style={{
                            gridColumn: "2 / span 1",
                            gridRow: "1 / span 1",
                            margin: 0,
                          }}
                          width={170}
                          title="VALUATION ($M)"
                          name={`valuation`}
                          fontSize={20}
                          placeholder="0.0"
                          type="number"
                          noTopMargin
                        />
                      </Split>
                    </Split>
                    <FieldArray name={`coinvestors`}>
                      {({ insert, remove, name }) => (
                        <FieldArrayContainer>
                          {values.coinvestors.map((co, index) => {
                            return (
                              <Split key={co.id}>
                                <SplitContainer>
                                  <Split>
                                    <ImageUploadField
                                      name={`coinvestors[${index}].url`}
                                      title="LOGO"
                                      style={{
                                        marginRight: 30,
                                      }}
                                    />
                                    <FormInputField
                                      width={330}
                                      name={`coinvestors[${index}].name`}
                                      fontSize={20}
                                      placeholder="Co-Investor Name"
                                    />
                                  </Split>
                                </SplitContainer>
                                <TrashIcon onClick={() => remove(index)} />
                              </Split>
                            );
                          })}
                          <AddTextContainer>
                            <AnimatedText
                              onClick={() =>
                                insert(values.coinvestors.length, {
                                  name: "",
                                  url: "",
                                  id: 0,
                                })
                              }
                            >
                              Add co-investor
                            </AnimatedText>
                          </AddTextContainer>
                        </FieldArrayContainer>
                      )}
                    </FieldArray>
                    <FormAreaInputField name={"footnote"} title="FOOTNOTE" fontSize={20} placeholder="Description" />
                  </div>
                  <SubmitContainer>
                    {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                    {error && <AppErrorText>{error}</AppErrorText>}
                    {loading ? <Loading /> : <DeleteText onClick={deleteFundingRound}>Delete Funding Round</DeleteText>}
                  </SubmitContainer>
                </FormContainer>
              );
            }}
          </Formik>
        </ModalWrapper>
      </RoundContainer>
    </Modal>
  );
};

const RoundContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteText = styled(AppText)`
  margin-top: 48px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.2;
  :hover {
    cursor: pointer;
  }
`;

const EditRoundModal = observer(EditRoundModalComponent);

export { EditRoundModal };
