import React, { useContext, useState, useCallback, useEffect, useRef } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { /*useQuery,*/ StoreContext } from "../models/reactUtils";
import { Header } from "../Components/Segments";
import moment from "moment";

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps;

const valid_hbcudr_routes = ["/advancement-initiative-data-room", "/logout"];

export const PrivateRouteComponent = ({
  component: Component,
  path,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  // const { setQuery, loading, error, data } = useQuery();

  const store = useContext(StoreContext);

  const { is_authenticated, user } = store;

  //console.log("isAuthenticated component: ", Component);
  //console.log("PROPS", path);
  console.log("PrivateRoute is_authenticated: ", is_authenticated);
  console.log("path: ", path);

  // For automatic logout

  const history = useHistory();

  const [events, setEvents] = useState(["click", "load", "scroll"]);
  const [second, setSecond] = useState(0);

  const warningInactiveInterval: { current: number } = useRef();
  const startTimerInterval: { current: number } = useRef();

  // start inactive check
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
      // }, 6000);
    }, 60000 * 15); // Check every 15 mins
  };

  // warning timer
  const warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 60 * 8; // Maximum ideal time given before logout
      // const popTime = 1; // remaining time (notification) left to logout.

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      // const leftSecond = 60 - diff.seconds();

      // if (minPast === popTime) {
      //   alert(leftSecond);
      //   setSecond(leftSecond);
      // }

      const lastActivityTime = moment().diff(moment(timeString), "minutes");

      if (lastActivityTime > maxTime) {
        console.log("minPast", minPast, "max", maxTime);
        // alert("timeout");
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        history.push("/logout");
      }
    }, 1000);
  };

  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (is_authenticated) {
      const timeStamp = moment();
      sessionStorage.setItem("lastTimeStamp", moment(timeStamp).format());
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastTimeStamp");
    }
    timeChecker();
  }, [is_authenticated]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  return (
    <div>
      <Header />
      <Route
        {...rest}
        component={(props: any) => {
          if (is_authenticated) {
            // If user is HBCUDR role, restrict their paths
            if (user?.role === "HBCUDR") {
              if (valid_hbcudr_routes.includes(path)) {
                return <Component {...props} />;
              } else {
                return <Redirect to="/advancement-initiative-data-room" />;
              }
            }
            return <Component {...props} />;
          } else {
            return <Redirect to="/login" />;
          }
        }}
      />
    </div>
  );
};

const PrivateRoute = observer(PrivateRouteComponent);

export default PrivateRoute;
