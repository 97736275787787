import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { AppText } from "../UI";
import { PRIMARY_COLOR, BLACK_COLOR } from "../../utils/theme";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ResearchForm } from "../Segments/CMS";
import { useQuery } from "../../models/reactUtils";
import { observer } from "mobx-react";
import { IndustryModelType } from "../../models";

interface ResearchListComponentProps {
  data: IndustryModelType;
}

const ResearchListComponentShell: FunctionComponent<ResearchListComponentProps> = ({ data }) => {
  const { store } = useQuery();
  const [showForm, setForm] = useState(false);
  const toggleForm = () => setForm(!showForm);
  if (data.name) {
    return (
      <ResearchComponentWrapper>
        <ResearchComponentContainer onClick={toggleForm}>
          <SectorText>{data.name}</SectorText>
          <SectorText>{data.final_dilligence_count} Final Dilligence</SectorText>
          {showForm ? (
            <StyledUpArrow size={24} color={PRIMARY_COLOR} />
          ) : (
            <StyledDownArrow size={24} color={PRIMARY_COLOR} />
          )}
        </ResearchComponentContainer>
        <ListSeperater />
        {showForm && <ResearchForm data={data} fund_id={store.selected_fund.id} />}
      </ResearchComponentWrapper>
    );
  }
  return <div>Invalid Data</div>;
};

const ResearchComponentWrapper = styled.div`
  background: white;
  width: 100%;
`;

const ResearchComponentContainer = styled.div`
  height: 132px;
  width: calc(100%-100px);
  margin-left: 100px;
  margin-right: 100px;
  background: white;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const SectorText = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-left: 0px;
  margin-right: 100px;
`;

const StyledDownArrow = styled(FaAngleDown)`
  position: absolute;
  right: 0;
  top: 55px;
`;

const StyledUpArrow = styled(FaAngleUp)`
  position: absolute;
  right: 0;
  top: 55px;
`;

const ListSeperater = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: ${BLACK_COLOR};
`;

const ResearchListComponent = observer(ResearchListComponentShell);

export { ResearchListComponent };
