import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import { CapitalDeployedProgressBar, AppPanelTitle, Loading } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelFooter, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";

interface CapitalDeployedProps {
  fund_id: number;
}

const CapitalDeployedPanel: FunctionComponent<CapitalDeployedProps> = (props) => {
  const { store } = useQuery();
  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund(
      { where: { id: props.fund_id } },
      (qb) => qb.capital_deployed_perc_deployed_and_reserved.captial_deployed_footnote,
    ),
  );

  useEffect(() => {
    const query = store.queryFund(
      { where: { id: props.fund_id } },
      (qb) => qb.capital_deployed_perc_deployed_and_reserved.captial_deployed_footnote,
    );
    fundQuery(query);
  }, [props.fund_id]);

  if (fundLoading || !fundData || !fundData.fund) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (fundError) {
    return <p>Error</p>;
  }
  const {
    fund: { capital_deployed_perc_deployed_and_reserved, captial_deployed_footnote },
  } = fundData;

  return (
    <HalfPanelContainer>
      <PanelWrapper>
        <AppPanelTitle>Capital Deployed & Reserved</AppPanelTitle>
        <PanelContent>
          <CapitalDeployedProgressBar deployed={capital_deployed_perc_deployed_and_reserved} />
          <Split>
            <div>
              <CapitalDeployedText>{capital_deployed_perc_deployed_and_reserved}%</CapitalDeployedText>
              {/* <CapitalDeployedLabel>Deployed and reserved</CapitalDeployedLabel> */}
            </div>
            <div>
              {capital_deployed_perc_deployed_and_reserved < 100 && (
                <>
                  <CapitalDeployedText unallocated={true}>
                    {100 - capital_deployed_perc_deployed_and_reserved}%
                  </CapitalDeployedText>
                  <CapitalDeployedLabel>Unallocated</CapitalDeployedLabel>
                </>
              )}
            </div>
          </Split>
        </PanelContent>
        {captial_deployed_footnote && <PanelFooter>{captial_deployed_footnote}</PanelFooter>}
      </PanelWrapper>
    </HalfPanelContainer>
  );
};

interface CapitalDeployedTextProps {
  unallocated?: boolean;
}

const CapitalDeployedText = styled.h3<CapitalDeployedTextProps>`
  font-family: ${PRIMARY_FONT};
  font-weight: normal;
  font-size: 80px;
  line-height: 1;
  letter-spacing: -2px;
  margin: 0;
  color: ${(props) => (props.unallocated ? PRIMARY_COLOR : BLACK_COLOR)};
  @media ${mobileSize} {
    font-size: 40px;
    letter-spacing: -1px;
  }
`;

const CapitalDeployedLabel = styled.h4<CapitalDeployedTextProps>`
  font-family: ${PRIMARY_FONT};
  margin: 0;
  margin-top: -1px;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  font-weight: normal;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
  }
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CapitalDeployedComponent = observer(CapitalDeployedPanel);

export { CapitalDeployedComponent };
