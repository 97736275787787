import React, { Component } from "react";
import styled from "styled-components";
import { AppText } from "../UI";
import { PRIMARY_COLOR, BLACK_COLOR } from "../../utils/theme";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { PortfolioForm } from "../Segments/CMS";
import { PLACEHOLDER_IMAGE } from "../../utils/variables";
import { CompanyModelType } from "../../models";

interface CompanyListComponentProps {
  data: CompanyModelType;
}

interface CompanyListComponentState {
  showForm: boolean;
}

class CompanyListComponent extends Component<CompanyListComponentProps, CompanyListComponentState> {
  constructor(props: CompanyListComponentProps) {
    super(props);

    this.state = {
      showForm: false,
    };
  }

  showForm = () => {
    this.setState({
      showForm: !this.state.showForm,
    });
  };

  render() {
    const { data } = this.props;
    const { showForm } = this.state;
    if (data.name) {
      return (
        <CompanyComponentWrapper>
          <CompanyComponentContainer onClick={this.showForm}>
            <CompanyImage src={!!data.logo_url ? data.logo_url : PLACEHOLDER_IMAGE} />
            <RoundsText>{`${data.num_funding_rounds} round${data.num_funding_rounds !== 1 ? "s" : ""}`}</RoundsText>
            <SectorText>{data.industry_string}</SectorText>
            {this.state.showForm ? (
              <StyledUpArrow size={24} color={PRIMARY_COLOR} />
            ) : (
              <StyledDownArrow size={24} color={PRIMARY_COLOR} />
            )}
          </CompanyComponentContainer>
          <ListSeperater />
          {showForm && <PortfolioForm data={data} />}
        </CompanyComponentWrapper>
      );
    }
    return <div></div>;
  }
}

interface ShowNav {
  showForm: boolean;
}

const CompanyComponentWrapper = styled.div`
  /* margin-left: auto; */
  /* margin-right: auto; */
  background: white;
  width: 100%;
`;

const CompanyComponentContainer = styled.div`
  height: 132px;
  width: calc(100%-100px);
  margin-left: 100px;
  margin-right: 100px;
  background: white;
  position: relative;
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const CompanyImage = styled.img`
  max-width: 120px;
`;

const RoundsText = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-left: 75px;
  width: 90px;
`;

const SectorText = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-left: 115px;
`;

const StyledDownArrow = styled(FaAngleDown)`
  position: absolute;
  right: -50px;
  top: 55px;
`;

const StyledUpArrow = styled(FaAngleUp)`
  position: absolute;
  right: -50px;
  top: 55px;
`;

const ListSeperater = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: ${BLACK_COLOR};
`;

export { CompanyListComponent };
