import React from "react";
import { useField } from "formik";
import styled from "styled-components";
import { AppFormTitle, AppErrorText } from "../UI";
import { IconPicker } from "../Dumb/IconPicker";

interface IconPickerFieldProps {
  name: string;
  style?: React.CSSProperties;
  title?: string;
  type?: string;
  width?: number;
}

const IconPickerField = ({ style, title, ...props }: IconPickerFieldProps) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <Container style={style}>
      {!!title && <AppFormTitle style={{ margin: 0 }}>{title.toUpperCase()}</AppFormTitle>}
      <IconPicker value={field.value} onChange={(icon) => helpers.setValue(icon)} />
      {meta.error && meta.touched && <AppErrorText>{meta.error}</AppErrorText>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export { IconPickerField };
