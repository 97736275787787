import * as React from "react";
import styled from "styled-components";
import RLoading from "react-spinkit";
import { PRIMARY_COLOR } from "../../utils/theme";

export interface LoadingProps {
  color?: string;
  size?: number;
  position?: string;
}

const Loading: React.SFC<LoadingProps> = ({ position = "center" }) => {
  return (
    <Div style={{ justifyContent: position }}>
      <RLoading name="line-scale" color={PRIMARY_COLOR} fadeIn="none" />
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { Loading };
