import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, Loading, AppErrorText, AppText } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CheckboxField, FormInputField, FormSelectField } from "../Forms";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";
import { UrlType } from "../../models";
import { RED_COLOR, WHITE_COLOR, PRIMARY_COLOR } from "../../utils/theme";
import { BACKEND_URL } from "../../utils/variables";

const type_options = [
  {
    label: "File",
    value: "PDF",
  },
  {
    label: "Link",
    value: "LINK",
  },
  {
    label: "Video",
    value: "VIDEO",
  },
];

const dataRoomItemSchema = Yup.object().shape({
  label: Yup.string().required("Label is required"),
  url: Yup.string().url("* Must be valid URL").required("URL is required"),
  type: Yup.string().required("Must select an item type"),
  send_notification: Yup.boolean().notRequired(),
});

interface DataRoomItemModalProps {
  showModal: boolean;
  toggleModal: () => void;
  modal_type: "add" | "edit";
  label?: string;
  type?: UrlType;
  url?: string;
  id?: number;
  topic_id: number;
  onComplete: ({ id, label, type, url }: { id: number; label: string; url: string; type: any }) => void;
  onDelete?: (id: number) => void;
  /**
   * Used for temporary hbcu user. Shows send_notification checkbox
   */
  hbcu?: boolean;
}

interface MyFormikProps {
  label: string;
  type: string;
  url: string;
  send_notification: boolean;
}

const DataRoomItemModal: FunctionComponent<DataRoomItemModalProps> = observer(
  ({ showModal, toggleModal, modal_type, label = "", type, url, onComplete, onDelete, id, topic_id, hbcu }) => {
    const { setQuery, loading, error, store } = useQuery();

    const deleteItem = () => {
      const mutation = store.mutateDeleteOneDataRoomItem({ where: { id } });
      mutation.then((v) => {
        toast("Item deleted!");
        onDelete(id);
        //toggleModal();
      });
      setQuery(mutation);
    };

    return (
      <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
        <CloseModal onClick={toggleModal}>
          <AiOutlineClose size={20} color={"black"} />
        </CloseModal>
        <NewFundContainer>
          <ModalWrapper>
            <Formik
              initialValues={{
                label,
                type,
                url,
                send_notification: true,
              }}
              validationSchema={dataRoomItemSchema}
              onSubmit={async (values, { setFieldError, setStatus }) => {
                console.log("Dataroom topic form submission", values);
                if (modal_type === "add") {
                  const mutation = store.mutateCreateHBCUDataroomItem({
                    label: values.label,
                    url: values.url,
                    type: values.type as any,
                    topicId: topic_id,
                    sendNotification: values.send_notification,
                  });
                  mutation.then((v) => {
                    toast("Item created!");
                    onComplete({ ...(v.createHBCUDataroomItem as any) });
                    toggleModal();
                  });
                  setQuery(mutation);
                } else {
                  const mutation = store.mutateUpdateHBCUDataroomItem({
                    label: values.label,
                    type: values.type as any,
                    url: values.url,
                    itemId: id,
                    sendNotification: values.send_notification,
                  });
                  mutation.then((v) => {
                    toast("Item Updated");
                    onComplete({
                      id: v.updateHBCUDataroomItem.id,
                      label: v.updateHBCUDataroomItem.label,
                      type: v.updateHBCUDataroomItem.type,
                      url: v.updateHBCUDataroomItem.url,
                    });
                    toggleModal();
                  });
                  setQuery(mutation);
                }
              }}
            >
              {({ submitForm, values, setFieldValue, errors, touched }: FormikProps<MyFormikProps>) => {
                return (
                  <div>
                    <TitleContainer>
                      <Title>{modal_type === "add" ? "Create new Item" : "Edit Item"}</Title>
                    </TitleContainer>
                    <FormContainer>
                      <Split>
                        <MembersContainer>
                          <Split>
                            <FormInputField width={440} name={`label`} fontSize={20} placeholder="Label" noTopMargin />
                            <FormSelectField
                              width={180}
                              name={`type`}
                              fontSize={20}
                              placeholder="Item Type"
                              options={type_options}
                            />
                          </Split>
                          {!!values.type && values.type !== "0" && (
                            <>
                              {values.type === "PDF" ? (
                                <>
                                  <input
                                    type="file"
                                    multiple={false}
                                    accept="file/pdf"
                                    onChange={async (event) => {
                                      event.preventDefault();
                                      if (!event.currentTarget.files.length) {
                                        return;
                                      }
                                      const formData = new FormData();
                                      formData.append("document", event.currentTarget.files[0]);
                                      const responseRaw = await fetch(`${BACKEND_URL}/upload/document`, {
                                        body: formData,
                                        method: "post",
                                      });
                                      const response = await responseRaw.json();

                                      console.log("response: ", response);
                                      setFieldValue("url", response.url);
                                    }}
                                  />
                                  <AppText>{values.url}</AppText>
                                  {errors.url && touched.url && <AppErrorText>{errors.url}</AppErrorText>}
                                </>
                              ) : (
                                <FormInputField name={`url`} fontSize={20} placeholder="Item URL" noTopMargin />
                              )}
                            </>
                          )}
                          {hbcu && <CheckboxField name="send_notification" title="Send Notification" />}
                        </MembersContainer>
                      </Split>
                    </FormContainer>
                    <SubmitContainer>
                      {loading ? (
                        <Loading />
                      ) : (
                        <>
                          <StyledAppButton onClick={submitForm}>
                            {modal_type === "add" ? "Create Item" : "Save Changes"}
                          </StyledAppButton>
                          {error && <AppErrorText>{error}</AppErrorText>}
                          {!!onDelete && <DeleteText onClick={deleteItem}>Delete Item</DeleteText>}
                        </>
                      )}
                    </SubmitContainer>
                  </div>
                );
              }}
            </Formik>
          </ModalWrapper>
        </NewFundContainer>
      </Modal>
    );
  },
);

const NewFundContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 25px 0px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
`;

const DeleteText = styled(AppText)`
  color: ${RED_COLOR};
  margin-top: 50px;
  margin-bottom: 25px;
  border-width: 0;
`;

const StyledInput = styled.input`
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  height: 40px;
  width: 150px;
`;

export { DataRoomItemModal };
