/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { FundingRoundCoinvestorModel, FundingRoundCoinvestorModelType } from "./FundingRoundCoinvestorModel"
import { FundingRoundCoinvestorModelSelector } from "./FundingRoundCoinvestorModel.base"
import { RootStoreType } from "./index"


/**
 * FundingRoundBase
 * auto generated base class for the model FundingRoundModel.
 */
export const FundingRoundModelBase = ModelBase
  .named('FundingRound')
  .props({
    __typename: types.optional(types.literal("FundingRound"), "FundingRound"),
    id: types.union(types.undefined, types.integer),
    stage: types.union(types.undefined, types.null, types.string),
    round_number: types.union(types.undefined, types.null, types.integer),
    funding_date: types.union(types.undefined, types.null, types.frozen()),
    total_investment: types.union(types.undefined, types.null, types.number),
    base10_invest: types.union(types.undefined, types.null, types.number),
    valuation: types.union(types.undefined, types.null, types.number),
    ownership: types.union(types.undefined, types.null, types.number),
    coinvestors: types.union(types.undefined, types.array(types.late((): any => FundingRoundCoinvestorModel))),
    footnote: types.union(types.undefined, types.null, types.string),
    company: types.union(types.undefined, types.null, types.late((): any => CompanyModel)),
    company_id: types.union(types.undefined, types.null, types.integer),
    /** Displays the month and the year of the round in 'MMM YYYY' format. Used for the fundraising and valuation history company modal chart */
    month_year: types.union(types.undefined, types.null, types.string),
    /** Determines whether base10 was involved in the round. True if so, false if not. */
    base10_involved: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FundingRoundModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get stage() { return this.__attr(`stage`) }
  get round_number() { return this.__attr(`round_number`) }
  get funding_date() { return this.__attr(`funding_date`) }
  get total_investment() { return this.__attr(`total_investment`) }
  get base10_invest() { return this.__attr(`base10_invest`) }
  get valuation() { return this.__attr(`valuation`) }
  get ownership() { return this.__attr(`ownership`) }
  get footnote() { return this.__attr(`footnote`) }
  get company_id() { return this.__attr(`company_id`) }
  get month_year() { return this.__attr(`month_year`) }
  get base10_involved() { return this.__attr(`base10_involved`) }
  coinvestors(builder?: string | FundingRoundCoinvestorModelSelector | ((selector: FundingRoundCoinvestorModelSelector) => FundingRoundCoinvestorModelSelector)) { return this.__child(`coinvestors`, FundingRoundCoinvestorModelSelector, builder) }
  company(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`company`, CompanyModelSelector, builder) }
}
export function selectFromFundingRound() {
  return new FundingRoundModelSelector()
}

export const fundingRoundModelPrimitives = selectFromFundingRound().stage.round_number.funding_date.total_investment.base10_invest.valuation.ownership.footnote.company_id.month_year.base10_involved
