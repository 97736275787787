/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { DataRoomItemModel, DataRoomItemModelType } from "./DataRoomItemModel"
import { DataRoomItemModelSelector } from "./DataRoomItemModel.base"
import { FundModel, FundModelType } from "./FundModel"
import { FundModelSelector } from "./FundModel.base"
import { RootStoreType } from "./index"


/**
 * DataRoomTopicBase
 * auto generated base class for the model DataRoomTopicModel.
 */
export const DataRoomTopicModelBase = ModelBase
  .named('DataRoomTopic')
  .props({
    __typename: types.optional(types.literal("DataRoomTopic"), "DataRoomTopic"),
    id: types.union(types.undefined, types.integer),
    ordinal: types.union(types.undefined, types.integer),
    topic_name: types.union(types.undefined, types.string),
    items: types.union(types.undefined, types.array(types.late((): any => DataRoomItemModel))),
    fund_id: types.union(types.undefined, types.null, types.integer),
    fund: types.union(types.undefined, types.null, types.late((): any => FundModel)),
    hbcu: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class DataRoomTopicModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get topic_name() { return this.__attr(`topic_name`) }
  get fund_id() { return this.__attr(`fund_id`) }
  get hbcu() { return this.__attr(`hbcu`) }
  items(builder?: string | DataRoomItemModelSelector | ((selector: DataRoomItemModelSelector) => DataRoomItemModelSelector)) { return this.__child(`items`, DataRoomItemModelSelector, builder) }
  fund(builder?: string | FundModelSelector | ((selector: FundModelSelector) => FundModelSelector)) { return this.__child(`fund`, FundModelSelector, builder) }
}
export function selectFromDataRoomTopic() {
  return new DataRoomTopicModelSelector()
}

export const dataRoomTopicModelPrimitives = selectFromDataRoomTopic().ordinal.topic_name.fund_id.hbcu
