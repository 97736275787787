/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CapitalDeployedPerQuarterCompanyModel, CapitalDeployedPerQuarterCompanyModelType } from "./CapitalDeployedPerQuarterCompanyModel"
import { CapitalDeployedPerQuarterCompanyModelSelector } from "./CapitalDeployedPerQuarterCompanyModel.base"
import { RootStoreType } from "./index"


/**
 * CapitalDeployedPerQuarterBase
 * auto generated base class for the model CapitalDeployedPerQuarterModel.
 */
export const CapitalDeployedPerQuarterModelBase = ModelBase
  .named('CapitalDeployedPerQuarter')
  .props({
    __typename: types.optional(types.literal("CapitalDeployedPerQuarter"), "CapitalDeployedPerQuarter"),
    quarter: types.union(types.undefined, types.null, types.string),
    ordinal: types.union(types.undefined, types.null, types.integer),
    primary: types.union(types.undefined, types.null, types.number),
    followup: types.union(types.undefined, types.null, types.number),
    companies: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => CapitalDeployedPerQuarterCompanyModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CapitalDeployedPerQuarterModelSelector extends QueryBuilder {
  get quarter() { return this.__attr(`quarter`) }
  get ordinal() { return this.__attr(`ordinal`) }
  get primary() { return this.__attr(`primary`) }
  get followup() { return this.__attr(`followup`) }
  companies(builder?: string | CapitalDeployedPerQuarterCompanyModelSelector | ((selector: CapitalDeployedPerQuarterCompanyModelSelector) => CapitalDeployedPerQuarterCompanyModelSelector)) { return this.__child(`companies`, CapitalDeployedPerQuarterCompanyModelSelector, builder) }
}
export function selectFromCapitalDeployedPerQuarter() {
  return new CapitalDeployedPerQuarterModelSelector()
}

export const capitalDeployedPerQuarterModelPrimitives = selectFromCapitalDeployedPerQuarter().quarter.ordinal.primary.followup
