import React, { createContext, FunctionComponent, useState, useMemo } from "react";

interface UserContextState {
  quarterly_authenticated: boolean;
  authenticateQuarterlyLogin: () => void;
  deauthorizeQuarterlyLogin: () => void;
}

export const UserContext = createContext<UserContextState | null>(null);

export const UserProvider: FunctionComponent = ({ children }) => {
  const [quarterly_authenticated, setQuarterly_authenticated] = useState(false);

  const authenticateQuarterlyLogin = () => setQuarterly_authenticated(true);

  const deauthorizeQuarterlyLogin = () => setQuarterly_authenticated(false);

  const memoizedValue = useMemo(
    () => ({
      quarterly_authenticated,
      authenticateQuarterlyLogin,
      deauthorizeQuarterlyLogin,
    }),
    [quarterly_authenticated, authenticateQuarterlyLogin, deauthorizeQuarterlyLogin],
  );

  return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
};
