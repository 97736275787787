import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, AnimatedText, Loading, AppErrorText } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { BLACK_COLOR, SECONDARY_FONT } from "../../utils/theme";
import { Formik, FormikProps, FieldArray } from "formik";
import { FormInputField } from "../Forms";
import { TrashIcon } from "../Dumb";
import { useQuery } from "../../models/reactUtils";
import { observer } from "mobx-react";
import { toast } from "react-toastify";

interface GeneralSettingsModalProps {
  showModal: boolean;
  toggleModal: () => void;
  fund_id: number;
}

interface MyFormikProps {
  stages: { id: number; name: string }[];
}

const GeneralSettingsModalComponent: FunctionComponent<GeneralSettingsModalProps> = ({
  showModal,
  toggleModal,
  fund_id,
}) => {
  const { setQuery, loading, error, store } = useQuery();
  const { data: stageData, loading: stageLoading, error: stageError, setQuery: stageSetQuery } = useQuery((store) =>
    store.queryStages({}, (qb) => qb.id.name),
  );

  useEffect(() => {
    console.log("fund_id change... refetching: ", fund_id);
    const query2 = store.queryStages({}, (qb) => qb.id.name);
    stageSetQuery(query2);
  }, [fund_id]);

  if (!showModal) {
    return null;
  }
  if (stageLoading) {
    return <Loading />;
  }
  if (stageError) {
    return <p>Error fetching stages</p>;
  }
  return (
    <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
      <CloseModal onClick={toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <GeneralSettingsContainer>
        <ModalWrapper>
          <Formik
            initialValues={{
              stages: !!stageData.stages.length ? stageData.stages : [{ id: 0, name: "" }],
            }}
            onSubmit={async (values) => {
              const mutation = store.mutateUpdateStages({
                stages: {
                  // @ts-ignore
                  stages: values.stages.map(({ __typename, ...s }) => s),
                },
              });
              mutation.then((v) => {
                toast("Settings updated!");
                toggleModal();
              });
              setQuery(mutation);
            }}
          >
            {({ submitForm, values }: FormikProps<MyFormikProps>) => {
              console.log("values: ", values);
              return (
                <div>
                  <TitleContainer>
                    <Title>General Settings</Title>
                  </TitleContainer>
                  <FormContainer>
                    <InputTitle>STAGES</InputTitle>
                    <FieldArray name="stages">
                      {({ insert, remove, name }) => {
                        return (
                          <FieldArrayContainer>
                            {values.stages.map((stage, i) => {
                              return (
                                <Split key={stage.id}>
                                  <FormInputField
                                    width={550}
                                    name={`stages[${i}].name`}
                                    fontSize={20}
                                    placeholder="Stage Name"
                                    noTopMargin
                                  />
                                  <TrashIcon onClick={() => remove(i)} />
                                </Split>
                              );
                            })}
                            <AddTextContainer>
                              <AnimatedText
                                onClick={() => {
                                  insert(values.stages.length, {
                                    id: Math.round(Math.random() * 10000),
                                    name: "",
                                  });
                                }}
                              >
                                Add Stage
                              </AnimatedText>
                            </AddTextContainer>
                          </FieldArrayContainer>
                        );
                      }}
                    </FieldArray>
                  </FormContainer>
                  <SubmitContainer>
                    {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                    {error && <AppErrorText>{error}</AppErrorText>}
                  </SubmitContainer>
                </div>
              );
            }}
          </Formik>
        </ModalWrapper>
      </GeneralSettingsContainer>
    </Modal>
  );
};

const GeneralSettingsContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const InputTitle = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GeneralSettingsModal = observer(GeneralSettingsModalComponent);

export { GeneralSettingsModal };
