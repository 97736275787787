import React, { SFC, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { FormInputField, SliderField, CheckboxField } from "../../Forms";
import { AppTitle, AppButton, Loading, AppErrorText } from "../../UI";
import { FormDatePicker } from "../../Forms";
import { PRIMARY_COLOR } from "../../../utils/theme";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { returnIfNormalFund } from "../../Dumb/utils";

interface OverallFormProps {
  fund_id: number;
}

interface MyFormikProps {
  capital_deployed_perc_deployed_and_reserved: number;
  moic: number;
  moic_excl_6_months: number;
  gross_moic_on_initial_checks: number;
  capital_deployed: number;
  capital_deployed_change: number;
  fmv: number;
  fmv_change: number;
  portfolio_companies: number;
  new_portfolio_companies: number;
  months_since_first_close: number;
  show_capital_deployed_change: boolean;
  show_fmv: boolean;
  show_fmv_change: boolean;
  show_moic: boolean;
  show_moic_excl_6_months: boolean;
  show_tvpi: boolean;
  show_gross_moic_on_initial_checks: boolean;
  show_months_since_first_close: boolean;
  show_new_portfolio_companies: boolean;
  show_portfolio_companies: boolean;
  last_update: Date;
  seed_primary: number;
  seed_follow_on: number;
  series_a_primary: number;
  series_a_follow_on: number;
  series_b_primary: number;
  series_b_follow_on: number;
  reserved_but_not_deployed: number;
  unallocated: number;
  total_investable_capital: number;
  graph_early_growth?: number;
  graph_growth?: number;
  graph_late_growth?: number;
  captial_deployed_footnote?: string;
  fund_overview_footnote?: string;
  capital_deployed_by_stage_footnote?: string;
  capital_deployed_by_industry_footnote?: string;
  advancement_initiative_portfolio_revenue_growth_footnote?: string;
  portfolio_footnote?: string;
  tvpi?: number;
  fmv_contribution_by_company_footnote?: string;
  research_process_pipeline_footnote?: string;
  capital_deployed_by_quarter_footnote?: string;
  footnotes_and_disclosesures?: string;
}

const OverallFormSchema = Yup.object().shape({
  moic: Yup.number().typeError("* Must specify a number").required("Field is required"),
  moic_excl_6_months: Yup.number().typeError("* Must specify a number").required("Field is required"),
  gross_moic_on_initial_checks: Yup.number().typeError("* Must specify a number").required("Field is required"),
  capital_deployed: Yup.number().typeError("* You must specify a number").required("Field is required"),
  capital_deployed_change: Yup.number().typeError("* You must specify a number").required("Field is required"),
  fmv: Yup.number().typeError("* You must specify a number").required("Field is required"),
  fmv_change: Yup.number().typeError("* You must specify a number").required("Field is required"),
  portfolio_companies: Yup.number().typeError("* You must specify a number").required("Field is required"),
  new_portfolio_companies: Yup.number().typeError("* You must specify a number").required("Field is required"),
  months_since_first_close: Yup.number().typeError("* You must specify a number").required("Field is required"),
  last_update: Yup.date().required("Field is required"),
  seed_primary: Yup.number().typeError("* You must specify a number").required("Field is required"),
  seed_follow_on: Yup.number().typeError("* You must specify a number").required("Field is required"),
  series_a_primary: Yup.number().typeError("* You must specify a number").required("Field is required"),
  series_a_follow_on: Yup.number().typeError("* You must specify a number").required("Field is required"),
  series_b_primary: Yup.number().typeError("* You must specify a number").required("Field is required"),
  series_b_follow_on: Yup.number().typeError("* You must specify a number").required("Field is required"),
  reserved_but_not_deployed: Yup.number().typeError("* You must specify a number").required("Field is required"),
  unallocated: Yup.number().typeError("* You must specify a number").required("Field is required"),
  total_investable_capital: Yup.number().typeError("* You must specify a number").required("Field is required"),
  graph_early_growth: Yup.number().typeError("* You must specify a number"),
  graph_growth: Yup.number().typeError("* You must specify a number"),
  graph_late_growth: Yup.number().typeError("* You must specify a number"),
  tvpi: Yup.number().typeError("* You must specify a number"),
  capital_deployed_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  fund_overview_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  capital_deployed_by_stage_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  capital_deployed_by_industry_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  advancement_initiative_portfolio_revenue_growth_footnote: Yup.string()
    .nullable()
    .typeError("* Footnote must be text"),
  portfolio_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  fmv_contribution_by_company_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  research_process_pipeline_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  capital_deployed_by_quarter_footnote: Yup.string().nullable().typeError("* Footnote must be text"),
  footnotes_and_disclosesures: Yup.string().nullable().typeError("* Footnote must be text"),
});

const OverallForm: SFC<OverallFormProps> = observer(({ fund_id }) => {
  const { setQuery, loading: updateLoading, error: updateError } = useQuery();
  const { loading, data, error, store, setQuery: setQueryFetch } = useQuery((store) =>
    store.queryFund(
      { where: { id: fund_id } },
      (qb) =>
        qb.id.capital_deployed.last_update.capital_deployed_change.capital_deployed_perc_deployed_and_reserved
          .capital_deployed_perc_unallocated.fmv.fmv_change.id.moic.moic_excl_6_months.moic_excl_6_months
          .gross_moic_on_initial_checks.months_since_first_close.name.new_portfolio_companies.portfolio_companies
          .seed_follow_on.seed_primary.series_a_follow_on.series_a_primary.series_b_follow_on.series_b_primary
          .reserved_but_not_deployed.unallocated.total_investable_capital.show_capital_deployed
          .show_capital_deployed_change.show_fmv.show_fmv_change.show_moic.show_moic_excl_6_months
          .show_gross_moic_on_initial_checks.show_months_since_first_close.show_new_portfolio_companies
          .show_portfolio_companies.graph_early_growth.graph_growth.graph_late_growth.captial_deployed_footnote
          .fund_overview_footnote.capital_deployed_by_stage_footnote.capital_deployed_by_industry_footnote
          .advancement_initiative_portfolio_revenue_growth_footnote.portfolio_footnote
          .fmv_contribution_by_company_footnote.research_process_pipeline_footnote.capital_deployed_by_quarter_footnote
          .footnotes_and_disclosesures.tvpi.show_tvpi,
    ),
  );
  useEffect(() => {
    const query = store.queryFund(
      { where: { id: fund_id } },
      (qb) =>
        qb.id.capital_deployed.last_update.capital_deployed_change.capital_deployed_perc_deployed_and_reserved
          .capital_deployed_perc_unallocated.fmv.fmv_change.id.moic.moic_excl_6_months.moic_excl_6_months
          .gross_moic_on_initial_checks.months_since_first_close.name.new_portfolio_companies.portfolio_companies
          .seed_follow_on.seed_primary.series_a_follow_on.series_a_primary.series_b_follow_on.series_b_primary
          .reserved_but_not_deployed.unallocated.total_investable_capital.show_capital_deployed
          .show_capital_deployed_change.show_fmv.show_fmv_change.show_moic.show_moic_excl_6_months
          .show_gross_moic_on_initial_checks.show_months_since_first_close.show_new_portfolio_companies
          .show_portfolio_companies.graph_early_growth.graph_growth.graph_late_growth.captial_deployed_footnote
          .fund_overview_footnote.capital_deployed_by_stage_footnote.capital_deployed_by_industry_footnote
          .advancement_initiative_portfolio_revenue_growth_footnote.portfolio_footnote
          .fmv_contribution_by_company_footnote.research_process_pipeline_footnote.capital_deployed_by_quarter_footnote
          .footnotes_and_disclosesures.tvpi.show_tvpi,
    );
    //query.refetch();
    setQueryFetch(query);
  }, [fund_id]);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error</p>;
  }
  const {
    fund: { __getStore, __setStore, __typename, hasLoaded, log, selected, is_selected, ...variables },
  } = data;
  return (
    <div>
      <Formik
        initialValues={{
          ...variables,
        }}
        validationSchema={OverallFormSchema}
        onSubmit={(values) => {
          console.log("Overall form submission", values);
          const mutation = store.mutateUpdateOneFund({
            where: { id: store.selected_fund.id },
            data: {
              capital_deployed: { set: values.capital_deployed },
              capital_deployed_change: { set: values.capital_deployed_change },
              capital_deployed_perc_deployed_and_reserved: { set: values.capital_deployed_perc_deployed_and_reserved },
              fmv: { set: values.fmv },
              fmv_change: { set: values.fmv_change },
              graph_early_growth: { set: values.graph_early_growth },
              graph_growth: { set: values.graph_growth },
              graph_late_growth: { set: values.graph_late_growth },
              gross_moic_on_initial_checks: { set: values.gross_moic_on_initial_checks },
              last_update: { set: values.last_update },
              moic: { set: values.moic },
              tvpi: { set: values.tvpi },
              moic_excl_6_months: { set: values.moic_excl_6_months },
              months_since_first_close: { set: values.months_since_first_close },
              new_portfolio_companies: { set: values.new_portfolio_companies },
              portfolio_companies: { set: values.portfolio_companies },
              reserved_but_not_deployed: { set: values.reserved_but_not_deployed },
              seed_follow_on: { set: values.seed_follow_on },
              seed_primary: { set: values.seed_primary },
              series_a_follow_on: { set: values.series_a_follow_on },
              series_a_primary: { set: values.series_a_primary },
              series_b_follow_on: { set: values.series_b_follow_on },
              series_b_primary: { set: values.series_b_primary },
              show_capital_deployed_change: { set: values.show_capital_deployed_change },
              show_fmv: { set: values.show_fmv },
              show_fmv_change: { set: values.show_fmv_change },
              show_gross_moic_on_initial_checks: { set: values.show_gross_moic_on_initial_checks },
              show_moic: { set: values.show_moic },
              show_moic_excl_6_months: { set: values.show_moic_excl_6_months },
              show_tvpi: { set: values.show_tvpi },
              show_months_since_first_close: { set: values.show_months_since_first_close },
              show_new_portfolio_companies: { set: values.show_new_portfolio_companies },
              show_portfolio_companies: { set: values.show_portfolio_companies },
              total_investable_capital: { set: values.total_investable_capital },
              unallocated: { set: values.unallocated },
              capital_deployed_perc_unallocated: { set: 100 - values.capital_deployed_perc_deployed_and_reserved },
              captial_deployed_footnote: { set: values.captial_deployed_footnote },
              fund_overview_footnote: { set: values.fund_overview_footnote },
              capital_deployed_by_stage_footnote: { set: values.capital_deployed_by_stage_footnote },
              capital_deployed_by_industry_footnote: { set: values.capital_deployed_by_industry_footnote },
              advancement_initiative_portfolio_revenue_growth_footnote: {
                set: values.advancement_initiative_portfolio_revenue_growth_footnote,
              },
              portfolio_footnote: { set: values.portfolio_footnote },
              fmv_contribution_by_company_footnote: { set: values.fmv_contribution_by_company_footnote },
              research_process_pipeline_footnote: { set: values.research_process_pipeline_footnote },
              capital_deployed_by_quarter_footnote: { set: values.capital_deployed_by_quarter_footnote },
              footnotes_and_disclosesures: { set: values.footnotes_and_disclosesures },
            },
          });
          mutation.then(() => {
            toast("Changes Saved!");
          });
          setQuery(mutation);
        }}
      >
        {({ submitForm, values }: FormikProps<MyFormikProps>) => {
          return (
            <div>
              <CapitalDeployed>
                <StyledTitle>Capital Deployed (%)</StyledTitle>
                <SliderField name={"capital_deployed_perc_deployed_and_reserved"} />
                <CapitalDeployedTextContainer>
                  <CapitalDeployedTextLeft>
                    <CapitalDeployedNumber>{`${
                      values.capital_deployed_perc_deployed_and_reserved || 0
                    }%`}</CapitalDeployedNumber>
                    <CapitalDeployedText>deployed and reserved</CapitalDeployedText>
                  </CapitalDeployedTextLeft>
                  <CapitalDeployedTextRight>
                    <CapitalDeployedNumber style={{ color: PRIMARY_COLOR }}>{`${
                      100 - values.capital_deployed_perc_deployed_and_reserved
                    }%`}</CapitalDeployedNumber>
                    <CapitalDeployedText style={{ color: PRIMARY_COLOR, textAlign: "right" }}>
                      unallocated
                    </CapitalDeployedText>
                  </CapitalDeployedTextRight>
                </CapitalDeployedTextContainer>
              </CapitalDeployed>
              <SectionContainer>
                <LeftSection>
                  <StyledTitle>Statistics</StyledTitle>
                </LeftSection>
                <RightSection>
                  <FormGrid>
                    <FormInputField
                      name={"moic"}
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                      }}
                      title="MOIC"
                      type="number"
                    />
                    <CheckboxField
                      name="show_moic"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "1 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "1 / span 1",
                      }}
                      name={"moic_excl_6_months"}
                      title="MOIC (excl. last 6 months)"
                      type="number"
                    />
                    <CheckboxField
                      name="show_moic_excl_6_months"
                      title="Display value"
                      style={{
                        gridColumn: "4 / span 1",
                        gridRow: "1 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1",
                      }}
                      name={"capital_deployed"}
                      type="number"
                      title="Capital Deployed"
                      currency={true}
                    />
                    <CheckboxField
                      name="show_capital_deployed"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "3 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "2 / span 1",
                      }}
                      name={"capital_deployed_change"}
                      title="Capital Deployed Change"
                      type="number"
                      currency={true}
                    />
                    <CheckboxField
                      name="show_capital_deployed_change"
                      title="Display value"
                      style={{
                        gridColumn: "4 / span 1",
                        gridRow: "2 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "4 / span 1",
                      }}
                      name={"fmv"}
                      type="number"
                      currency={true}
                      title="FMV"
                    />
                    <CheckboxField
                      name="show_fmv"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "4 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "3 / span 1",
                      }}
                      name={"fmv_change"}
                      currency={true}
                      title="FMV CHANGE"
                      type="number"
                    />
                    <CheckboxField
                      name="show_fmv_change"
                      title="Display value"
                      style={{
                        gridColumn: "4 / span 1",
                        gridRow: "3 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "5 / span 1",
                      }}
                      name={"portfolio_companies"}
                      title="PORTFOLIO COMPANIES"
                      type="number"
                    />
                    <CheckboxField
                      name="show_portfolio_companies"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "5 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "4 / span 1",
                      }}
                      name={"new_portfolio_companies"}
                      title="NEW PORTFOLIO COMPANIES"
                      type="number"
                    />
                    <CheckboxField
                      name="show_new_portfolio_companies"
                      title="Display value"
                      style={{
                        gridColumn: "4 / span 1",
                        gridRow: "4 / span 1",
                      }}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "6 / span 1",
                      }}
                      name={"months_since_first_close"}
                      title="WEIGHTED AVERAGE AGE &ensp; OF INVESTMENT"
                      type="number"
                    />
                    <CheckboxField
                      name="show_months_since_first_close"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "6 / span 1",
                      }}
                    />
                    <FormInputField
                      name={"tvpi"}
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "6 / span 1",
                      }}
                      title="TVPI"
                      type="number"
                    />
                    <CheckboxField
                      name="show_tvpi"
                      title="Display value"
                      style={{
                        gridColumn: "4 / span 1",
                        gridRow: "6 / span 1",
                      }}
                    />
                    <FormDatePicker
                      name={"last_update"}
                      title="LAST UPDATE"
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "5 / span 1",
                      }}
                    />
                    <FormInputField
                      name={"gross_moic_on_initial_checks"}
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "2 / span 1",
                      }}
                      title="Gross MOIC on initial checks"
                      type="number"
                    />
                    <CheckboxField
                      name="show_gross_moic_on_initial_checks"
                      title="Display value"
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "2 / span 1",
                      }}
                    />
                  </FormGrid>
                </RightSection>
              </SectionContainer>
              <SectionContainer>
                <LeftSection>
                  <StyledTitle>By Stage</StyledTitle>
                </LeftSection>
                <RightSection>
                  <FormGrid>
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "1 / span 1",
                        marginRight: 30,
                      }}
                      name={"seed_primary"}
                      title="SEED PRIMARY"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "1 / span 1",
                        marginRight: 30,
                      }}
                      currency={true}
                      name={"seed_follow_on"}
                      title="SEED FOLLOW-ON"
                      type="number"
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "2 / span 1",
                        marginRight: 30,
                      }}
                      name={"series_a_primary"}
                      title="SERIES A PRIMARY"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "2 / span 1",
                        marginRight: 30,
                      }}
                      name={"series_a_follow_on"}
                      title="SERIES A FOLLOW ON"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "3 / span 1",
                        marginRight: 30,
                      }}
                      name={"series_b_primary"}
                      title="SERIES B PRIMARY"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "3 / span 1",
                        marginRight: 30,
                      }}
                      name={"series_b_follow_on"}
                      title="SERIES B FOLLOW ON"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "4 / span 1",
                        marginRight: 30,
                      }}
                      name={"reserved_but_not_deployed"}
                      title="RESERVED BUT NOT DEPLOYED"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "3 / span 2",
                        gridRow: "4 / span 1",
                        marginRight: 30,
                      }}
                      name={"unallocated"}
                      title="UNALLOCATED"
                      type="number"
                      currency={true}
                    />
                    <FormInputField
                      style={{
                        gridColumn: "1 / span 2",
                        gridRow: "5 / span 1",
                        marginRight: 30,
                      }}
                      name={"total_investable_capital"}
                      title="TOTAL INVESTABLE CAPITAL"
                      type="number"
                      currency={true}
                    />
                  </FormGrid>
                </RightSection>
              </SectionContainer>
              {!returnIfNormalFund(fund_id) && (
                <SectionContainer>
                  <LeftSection>
                    <StyledTitle>Advancement Initiative I - Capital Deployed by Stage</StyledTitle>
                  </LeftSection>
                  <RightSection>
                    <FormGrid style={{ gridTemplateRows: "115px 115px" }}>
                      <FormInputField
                        style={{
                          gridColumn: "1 / span 2",
                          gridRow: "1 / span 1",
                          marginRight: 30,
                        }}
                        name={"graph_early_growth"}
                        title="Early-Growth"
                        type="number"
                        currency={true}
                      />
                      <FormInputField
                        style={{
                          gridColumn: "3 / span 2",
                          gridRow: "1 / span 1",
                          marginRight: 30,
                        }}
                        currency={true}
                        name={"graph_growth"}
                        title="Growth"
                        type="number"
                      />
                      <FormInputField
                        style={{
                          gridColumn: "1 / span 2",
                          gridRow: "2 / span 1",
                          marginRight: 30,
                        }}
                        name={"graph_late_growth"}
                        title="Late-Growth"
                        type="number"
                        currency={true}
                      />
                    </FormGrid>
                  </RightSection>
                </SectionContainer>
              )}
              <SectionContainer>
                <LeftSection>
                  <StyledTitle>Footnotes</StyledTitle>
                </LeftSection>
                <RightSection>
                  <FormGrid
                    style={{
                      gridTemplateColumns: "600px",
                      gridTemplateRows: "115px 115px 115px 115px 115px 115px 115px 115px 115px 115px",
                    }}
                  >
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"captial_deployed_footnote"}
                      title="capital deployed footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"fund_overview_footnote"}
                      title="fund overview footnote (defaults to last updated at)"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"capital_deployed_by_stage_footnote"}
                      title="capital deployed by stage footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"capital_deployed_by_industry_footnote"}
                      title="capital deployed by industry footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"advancement_initiative_portfolio_revenue_growth_footnote"}
                      title="advancement initiative portfolio revenue growth footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"portfolio_footnote"}
                      title="portfolio footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"fmv_contribution_by_company_footnote"}
                      title="fmv contribution by company footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"research_process_pipeline_footnote"}
                      title="research process pipeline footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"capital_deployed_by_quarter_footnote"}
                      title="capital deployed by quarter footnote"
                      type="string"
                    />
                    <FormInputField
                      style={{
                        marginRight: 30,
                      }}
                      name={"footnotes_and_disclosesures"}
                      title="footnotes and disclosesures"
                      type="string"
                    />
                  </FormGrid>
                </RightSection>
              </SectionContainer>
              <ButtonContainer>
                {updateLoading ? (
                  <Loading />
                ) : (
                  <AppButton type="submit" onClick={submitForm} height={70}>
                    Save Changes
                  </AppButton>
                )}
                {updateError && <AppErrorText>Could not save changes!</AppErrorText>}
              </ButtonContainer>
            </div>
          );
        }}
      </Formik>
    </div>
  );
});

const StyledTitle = styled(AppTitle)``;

const CapitalDeployed = styled.div`
  height: 300px;
  width: 100%;
`;

const CapitalDeployedTextContainer = styled.div`
  width: 100%;
`;

const CapitalDeployedNumber = styled(AppTitle)`
  font-size: 80px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
`;

const CapitalDeployedText = styled(AppTitle)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: right;
`;

const CapitalDeployedTextLeft = styled.div`
  float: left;
`;

const CapitalDeployedTextRight = styled.div`
  float: right;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 50px;
`;

const LeftSection = styled.div`
  width: 380px;
`;

const RightSection = styled.div``;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 220px 80px 220px 80px;
  grid-template-rows: 115px 115px 115px 115px 115px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
`;

export { OverallForm };
