import React, { useContext, useState, FunctionComponent, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { Formik, FormikProps } from "formik";
import { RouteComponentProps, withRouter, Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import styled, { keyframes } from "styled-components";
import { Dispatch } from "redux";
import { AppActions, Auth } from "../../types";
import { AppState, store } from "../../store/configureStore";
import { FormInputField } from "../Forms";
import { mobileSize } from "../../utils/breakpoints";
import Button from "@material-ui/core/Button";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT, RED_COLOR } from "../../utils/theme";
import { useQuery, StoreContext } from "../../models/reactUtils";
import { observer } from "mobx-react";
import { Base10Logo } from "../../svg/base10Logo";
import { AppButton, Loading } from "../UI";
import { OLD_PASSWORD, QUARTERLY_PASSWORD } from "../../utils/variables";
import { UserContext } from "../../context/UserContext";
import ReactGA from "react-ga";
import { MdSentimentVeryDissatisfied } from "react-icons/md";
import { makeToast } from "../../utils/toast";
import { trackEvent } from "../../services/mixpanel";

interface LoginProps {}

interface MyFormikProps {
  username: string;
  password: string;
}
function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

type Props = RouteComponentProps<{
  type: string;
}> &
  LoginProps;

const LoginComponent: FunctionComponent<Props> = (props) => {
  const query = useQueryParam();
  const loginType = props.match.params.type;
  console.log("props.match.params: ", props.match.params);

  const LoginSchema = () => {
    switch (loginType) {
      case "quarterly-letter":
      case "quarterly-letter-may":
        return Yup.object().shape({
          password: Yup.string().required("Please specify a password").max(50, "* Too Long!"),
        });
      default:
        return Yup.object().shape({
          username: Yup.string().required("Username or email is required"),
          password: Yup.string().required("Please specify a password").max(50, "* Too Long!"),
        });
    }
  };

  const store = useContext(StoreContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const username = query.get("username");
    const token = query.get("token");
    console.log("username: ", username);
    console.log("token: ", token);
    if (username && token) {
      // attempt auto login
      const attemptLogin = async () => {
        store.mutateSendMagicEmail({ email: username });
        const mutation: any = await store.login({ username, password: "", token });
        if (!mutation.success) {
          SetErrorMessage(mutation.message);
          Sentry.captureEvent({ message: `Invalid login for user ${username}: ${mutation.message}` });
        }
      };
      attemptLogin();
    }
  }, []);

  const sendMagicLinkEmail = async (username: string) => {
    await store.mutateSendMagicEmail({ email: username });
    makeToast({ message: "Email Sent!" });
  };

  const [errorMessage, SetErrorMessage] = useState("");

  const { authenticateQuarterlyLogin } = useContext(UserContext);

  return (
    <LoginContainer>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginSchema()}
        onSubmit={async (values, { setStatus }) => {
          const { username, password } = values;
          console.log("username", username);
          console.log("password", password);
          if (loginType === "quarterly-letter") {
            if (password === QUARTERLY_PASSWORD) {
              authenticateQuarterlyLogin();
              trackEvent("login");
              props.history.push(`/quarterly-update/success`);
              return;
            } else {
              SetErrorMessage("Wrong password");
              return;
            }
          } else if (loginType === "quarterly-letter-may") {
            if (password === "mayupdate") {
              authenticateQuarterlyLogin();
              trackEvent("login");
              props.history.push(`/quarterly-update-may/success`);
              return;
            } else {
              SetErrorMessage("Wrong password");
              return;
            }
          }
          if (password === OLD_PASSWORD) {
            props.history.push(`/new-password/${username}`);
          }
          try {
            ReactGA.event({ action: `Login`, category: `Login`, label: `Login` });
            const mutation: any = await store.login({ username, password });
            if (!mutation.success) {
              SetErrorMessage(mutation.message);
              Sentry.captureEvent({ message: `Invalid login for user ${username}: ${mutation.message}` });
            }
            trackEvent("login");
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ status, submitForm, isSubmitting, values }: FormikProps<MyFormikProps>) => {
          // console.log("status", status);
          return (
            <LoginBox>
              <LoginTitle>
                <Base10Logo color={BLACK_COLOR} />
              </LoginTitle>
              {errorMessage && (
                <div>
                  <LoginMessage>{errorMessage}</LoginMessage>
                  <MagicButton
                    color={RED_COLOR}
                    onClick={() => sendMagicLinkEmail(values.username)}
                    disabled={isSubmitting}
                  >
                    Invalid Password! Click Here to Reset.
                  </MagicButton>
                </div>
              )}
              <LoginFormContainer>
                {loginType !== "quarterly-letter-may" && loginType !== "quarterly-letter" && (
                  <InputFieldContainer>
                    <FormInputField name={"username"} title={"Email"} fullWidth={true} fontSize={20} />
                  </InputFieldContainer>
                )}
                <InputFieldContainer>
                  <FormInputField
                    name={"password"}
                    title={"Password"}
                    type={"password"}
                    fullWidth={true}
                    fontSize={20}
                  />
                </InputFieldContainer>
                <div>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <LoginButton color={PRIMARY_COLOR} onClick={submitForm} disabled={isSubmitting}>
                      {loginType !== "quarterly-letter" && loginType !== "quarterly-letter-may"
                        ? "Login"
                        : "Sign in to view quarterly letter"}
                    </LoginButton>
                  )}
                </div>
              </LoginFormContainer>

              {loginType !== "quarterly-letter" && loginType !== "quarterly-letter-may" && (
                <>
                  <LostPasswordContainer>
                    <StyledLink to="/reset-password">Lost your password?</StyledLink>
                  </LostPasswordContainer>
                  <LostPasswordContainer>
                    <StyledLink to="/request-access">Request Access</StyledLink>
                  </LostPasswordContainer>
                </>
              )}
            </LoginBox>
          );
        }}
      </Formik>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f7f9fa;
  min-width: 0;
  color: #444;
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 1.4;
  display: block;
  background-color: #f7f9fa;
`;

const LoginBox = styled.div`
  width: 480px;
  padding: 8% 0 0;
  margin: auto;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const LoginButton = styled(AppButton)`
  background-color: ${PRIMARY_COLOR};
  color: #fff;
`;

const MagicButton = styled(AppButton)`
  background-color: ${RED_COLOR};
  color: #000;
`;

const LoginTitle = styled.h1`
  text-align: center;
  display: block;
  font-size: 16px;
  margin-block-start: 11px;
  margin-block-end: 11px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const LoginFormContainer = styled.div`
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccd0d4;
  margin-top: 60px;
  padding: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  @media ${mobileSize} {
    margin-top: 40px;
    padding: 40px;
  }
`;

const InputFieldContainer = styled.div`
  padding-bottom: 20px;
  min-height: 90px;
`;

const LostPasswordContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  border-bottom: 0;
  color: ${BLACK_COLOR};
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: 10;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const LoginMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* background-color: ${BLACK_COLOR}; */
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: ${BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const Login = observer(withRouter(LoginComponent));

export { Login };
