import React, { useContext, useState, FunctionComponent, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { RouteComponentProps, Link } from "react-router-dom";
import * as Yup from "yup";
import styled, { keyframes } from "styled-components";
import { FormInputField } from "../Forms";
import { mobileSize } from "../../utils/breakpoints";
import Button from "@material-ui/core/Button";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../utils/theme";
import { useQuery, StoreContext } from "../../models/reactUtils";
import { observer } from "mobx-react";
import { Base10Logo } from "../../svg/base10Logo";
import { AppButton, Loading } from "../UI";
import ReactGA from "react-ga";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("* email is required").email("* Must be a valid email"),
  username: Yup.string().required("* username is required"),
  password: Yup.string().required("* Please specify a password").max(50, "* Too Long!"),
});

interface MyFormikProps {
  email: string;
  username: string;
  password: string;
}

type Props = RouteComponentProps<unknown>;

const SignupComponent: FunctionComponent<Props> = () => {
  // const { setQuery, loading, error, data: storeData } = useQuery();
  const [errorMessage, SetErrorMessage] = useState("");

  const store = useContext(StoreContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <SignupContainer>
      <Formik
        initialValues={{
          email: "",
          username: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setStatus }) => {
          const { email, password, username } = values;
          setStatus({ error: "", loading: true });
          console.log("email", email);
          console.log("password", password);
          try {
            const mutation = await store.signup({ email, password, username });
            if (!mutation.success) {
              SetErrorMessage(mutation.message);
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ status, submitForm, isSubmitting }: FormikProps<MyFormikProps>) => {
          // console.log("status", status);
          return (
            <SignupBox>
              <SignupTitle>
                <Base10Logo color={BLACK_COLOR} />
              </SignupTitle>
              {errorMessage && <SignupMessage>{errorMessage}</SignupMessage>}
              <SignupFormContainer>
                <InputFieldContainer>
                  <FormInputField name={"email"} title={"email"} fullWidth={true} fontSize={20} />
                  <FormInputField name={"username"} title={"username"} fullWidth={true} fontSize={20} />
                </InputFieldContainer>
                <InputFieldContainer>
                  <FormInputField
                    name={"password"}
                    title={"Password"}
                    type={"password"}
                    fullWidth={true}
                    fontSize={20}
                  />
                </InputFieldContainer>
                <div>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <LoginButton color={PRIMARY_COLOR} onClick={submitForm} disabled={isSubmitting}>
                      Signup
                    </LoginButton>
                  )}
                </div>
              </SignupFormContainer>
            </SignupBox>
          );
        }}
      </Formik>
    </SignupContainer>
  );
};

const SignupContainer = styled.div`
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f7f9fa;
  min-width: 0;
  color: #444;
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 1.4;
  display: block;
  background-color: #f7f9fa;
`;

const SignupBox = styled.div`
  width: 480px;
  padding: 8% 0 0;
  margin: auto;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const SignupTitle = styled.h1`
  text-align: center;
  display: block;
  font-size: 16px;
  margin-block-start: 11px;
  margin-block-end: 11px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const LoginButton = styled(AppButton)`
  background-color: ${PRIMARY_COLOR};
  color: #fff;
`;

const SignupFormContainer = styled.div`
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccd0d4;
  margin-top: 60px;
  padding: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  @media ${mobileSize} {
    margin-top: 40px;
    padding: 40px;
  }
`;

const InputFieldContainer = styled.div`
  padding-bottom: 20px;
  min-height: 90px;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  border-bottom: 0;
  color: ${BLACK_COLOR};
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: 10;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const SignupMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: ${BLACK_COLOR};
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const Signup = observer(SignupComponent);

export { Signup };
