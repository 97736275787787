import mixpanel from "mixpanel-browser";
import { useQuery } from "../models/reactUtils";
import { MIXPANEL_TOKEN } from "../utils/variables";

mixpanel.init(MIXPANEL_TOKEN);
const unique_id = mixpanel.get_distinct_id();

export const trackEvent = (event: string, properties?: any) => {
  try {
    mixpanel.track(event, properties ?? {});
  } catch (err) {
    console.error(err);
  }
};

export const mixpanelReset = () => {
  mixpanel.reset();
};

export const trackNavClicked = (nav: string) => {
  trackEvent(`Nav clicked`, { Nav: nav });
};

export const trackFundChanged = (fund: string) => {
  trackEvent(`Dashboard Fund changed`, { Fund: fund });
};

export const trackPortfolioClicked = (company: string) => {
  trackEvent(`Dashboard Company Portfolio clicked`, { Company: company });
};

export const trackAdvancementFileClick = (file: string) => {
  trackEvent(`Series B Fund I DR file clicked`, { "File Name": file });
};

export const trackUpdateLettersDropdown = (dropdown: string) => {
  trackEvent(`Update Letters Dropdown changed`, { "Letter Name": dropdown });
};

export const initMixpanel = (user: any) => {
  if (user === undefined && user === null) {
    console.log("Mixpanel Init Error: user not found");
    return;
  }

  mixpanel.identify(user.id);

  mixpanel.people.set({
    $email: user.email,
    $name: user.email,
    $role: user.role,
    $user_id: user.id,
  });
};
