/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum QueryMode {
  default="default",
insensitive="insensitive"
}

/**
* QueryMode
*/
export const QueryModeEnum = types.enumeration("QueryMode", [
        "default",
  "insensitive",
      ])
