/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FundModel, FundModelType } from "./FundModel"
import { FundModelSelector } from "./FundModel.base"
import { RootStoreType } from "./index"


/**
 * StageBase
 * auto generated base class for the model StageModel.
 */
export const StageModelBase = ModelBase
  .named('Stage')
  .props({
    __typename: types.optional(types.literal("Stage"), "Stage"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    base10_investment: types.union(types.undefined, types.null, types.number),
    fund_id: types.union(types.undefined, types.null, types.integer),
    fund: types.union(types.undefined, types.null, types.late((): any => FundModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class StageModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get base10_investment() { return this.__attr(`base10_investment`) }
  get fund_id() { return this.__attr(`fund_id`) }
  fund(builder?: string | FundModelSelector | ((selector: FundModelSelector) => FundModelSelector)) { return this.__child(`fund`, FundModelSelector, builder) }
}
export function selectFromStage() {
  return new StageModelSelector()
}

export const stageModelPrimitives = selectFromStage().name.base10_investment.fund_id
