import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { RouteComponentProps, Link } from "react-router-dom";
import * as Yup from "yup";
import styled, { keyframes } from "styled-components";
import { FormInputField } from "../Forms";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../utils/theme";
import { observer } from "mobx-react";
import { Base10Logo } from "../../svg/base10Logo";
import { AppButton, Loading } from "../UI";
import { StoreContext } from "../../models/reactUtils";
import ReactGA from "react-ga";
import { makeToast } from "../../utils/toast";

const ResetPasswordSchema = Yup.object().shape({
  username: Yup.string().required("Username or email is required"),
});

interface MyFormikProps {
  username: string;
}

type Props = RouteComponentProps<unknown>;

const ResetPasswordComponent: FunctionComponent<Props> = () => {
  const [errorMessage, SetErrorMessage] = useState("");
  const [sentMessage, setSentMessage] = useState("");
  // const { setQuery, loading, error, data } = useQuery();

  const store = useContext(StoreContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const sendMagicLinkEmail = async (username: string) => {
    await store.mutateSendMagicEmail({ email: username });
    makeToast({ message: "Email Sent!" });
    setSentMessage("Email Sent!");
  };

  return (
    <ResetPasswordContainer>
      <Formik
        initialValues={{
          username: "",
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { username } = values;
          setStatus({ error: "", loading: true });
          console.log("username", username);
          if (!username) {
            SetErrorMessage("Please specify a username");
            return;
          }
          try {
            await sendMagicLinkEmail(values.username);
          } catch (error) {
            SetErrorMessage(error.response.errors[0].message);
            console.log(error);
          }
        }}
      >
        {({
          status,
          setStatus,
          handleSubmit,
          handleChange,
          submitForm,
          isSubmitting,
          values,
        }: FormikProps<MyFormikProps>) => {
          console.log("status", status);
          return (
            <ResetPasswordBox>
              <ResetPasswordTitle>
                <Base10Logo color={BLACK_COLOR} />
              </ResetPasswordTitle>
              <ResetPasswordMessage>
                Plese enter your username or email address. You will recieve an email message with instructions on how
                to reset your password
              </ResetPasswordMessage>
              {errorMessage && <LoginMessage>{errorMessage}</LoginMessage>}
              <ResetPasswordFormContainer>
                <InputFieldContainer>
                  <FormInputField name={"username"} title={"Email"} fullWidth={true} fontSize={20} />
                </InputFieldContainer>
                <div>
                  {isSubmitting ? (
                    <Loading />
                  ) : sentMessage ? (
                    <p>{sentMessage}</p>
                  ) : (
                    <LoginButton color={PRIMARY_COLOR} onClick={submitForm} disabled={isSubmitting}>
                      Submit
                    </LoginButton>
                  )}
                </div>
              </ResetPasswordFormContainer>
              <LostPasswordContainer>
                <StyledLink to="/login">Log in</StyledLink>
              </LostPasswordContainer>
            </ResetPasswordBox>
          );
        }}
      </Formik>
    </ResetPasswordContainer>
  );
};

const ResetPasswordContainer = styled.div`
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f7f9fa;
  min-width: 0;
  color: #444;
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 1.4;
  display: block;
  background-color: #f7f9fa;
`;

const ResetPasswordBox = styled.div`
  width: 480px;
  padding: 8% 0 0;
  margin: auto;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const LoginButton = styled(AppButton)`
  background-color: ${PRIMARY_COLOR};
  color: #fff;
`;

const ResetPasswordTitle = styled.h1`
  text-align: center;
  display: block;
  font-size: 16px;
  margin-block-start: 11px;
  margin-block-end: 11px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const ResetPasswordMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: ${BLACK_COLOR};
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const LoginMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: ${BLACK_COLOR};
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const ResetPasswordFormContainer = styled.div`
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccd0d4;
  margin-top: 60px;
  padding: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  @media ${mobileSize} {
    margin-top: 40px;
    padding: 40px;
  }
`;

const InputFieldContainer = styled.div`
  padding-bottom: 20px;
  min-height: 90px;
`;

const LostPasswordContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  border-bottom: 0;
  color: ${BLACK_COLOR};
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: 10;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const ResetPassword = observer(ResetPasswordComponent);

export { ResetPassword };
