import React, { Component } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "../src/routers/AppRouter";
import { MuiThemeProvider } from "@material-ui/core/styles";
import GlobalFonts from "./fonts/Fonts";

import { GlobalTheme } from "./utils/muiTheme";
import { ModalContainer, UserProvider } from "./context";
import { StoreContext } from "./models/reactUtils";
import { mstStore } from "./store/mst";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import { GA_TRACKING_ID } from "./utils/variables";

Sentry.init({
  dsn: "https://481eab41d35a46d193af5a94a8496ffd@o241222.ingest.sentry.io/5262167",
});

ReactGA.initialize(GA_TRACKING_ID);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <GlobalStyles /> */}
          <MuiThemeProvider theme={GlobalTheme}>
            <ToastContainer />
            <GlobalFonts />
            <StoreContext.Provider value={mstStore}>
              <ModalContainer>
                <UserProvider>
                  <AppRouter />
                </UserProvider>
              </ModalContainer>
            </StoreContext.Provider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
