import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, Loading, AppErrorText, AppText } from "../UI";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormInputField, FormSelectField } from "../Forms";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";
import { RED_COLOR, WHITE_COLOR } from "../../utils/theme";

const dataRoomTopicSchema = Yup.object().shape({
  topic_name: Yup.string().required("Name is required"),
});

interface DataRoomTopicModalProps {
  showModal: boolean;
  toggleModal: () => void;
  modal_type: "add" | "edit";
  topic_name?: string;
  id?: number;
  fund_id: number;
  onComplete: ({ id, topic_name }: { id: number; topic_name: string }) => void;
  onDelete?: (id: number) => void;
  hbcu?: boolean;
}

interface MyFormikProps {
  topic_name: string;
}

const DataRoomTopicModal: FunctionComponent<DataRoomTopicModalProps> = observer(
  ({ showModal, toggleModal, modal_type, topic_name = "", onComplete, id, onDelete, hbcu = false, fund_id }) => {
    const { setQuery, loading, error, store } = useQuery();

    const deleteTopic = () => {
      const mutation = store.mutateDeleteOneDataRoomTopic({ where: { id } });
      mutation.then((v) => {
        toast("Topic deleted!");
        onDelete(id);
        // toggleModal();
      });
      setQuery(mutation);
    };

    return (
      <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
        <CloseModal onClick={toggleModal}>
          <AiOutlineClose size={20} color={"black"} />
        </CloseModal>
        <NewFundContainer>
          <ModalWrapper>
            <Formik
              initialValues={{
                topic_name,
              }}
              validationSchema={dataRoomTopicSchema}
              onSubmit={async (values, { setFieldError, setStatus }) => {
                console.log("Dataroom topic form submission", values);
                if (modal_type === "add") {
                  const mutation = store.mutateCreateOneDataRoomTopic({
                    data: hbcu
                      ? { topic_name: values.topic_name, hbcu: true }
                      : { topic_name: values.topic_name, fund: { connect: { id: fund_id } } },
                  });
                  mutation.then((v) => {
                    toast("Topic created!");
                    onComplete({ id: v.createOneDataRoomTopic.id, topic_name: v.createOneDataRoomTopic.topic_name });
                    toggleModal();
                  });
                  setQuery(mutation);
                } else {
                  const mutation = store.mutateUpdateOneDataRoomTopic({
                    data: { topic_name: { set: values.topic_name } },
                    where: { id },
                  });
                  mutation.then((v) => {
                    toast("Topic Updated");
                    onComplete({ id: v.updateOneDataRoomTopic.id, topic_name: v.updateOneDataRoomTopic.topic_name });
                    toggleModal();
                  });
                  setQuery(mutation);
                }
              }}
            >
              {({ submitForm, values }: FormikProps<MyFormikProps>) => {
                return (
                  <div>
                    <TitleContainer>
                      <Title>{modal_type === "add" ? "Create new Topic" : "Edit Topic"}</Title>
                    </TitleContainer>
                    <FormContainer>
                      <FormInputField
                        name={"topic_name"}
                        // fontSize={20}
                        placeholder="Name your topic"
                        noTopMargin
                        textAlign="center"
                      />
                    </FormContainer>
                    <SubmitContainer>
                      {loading ? (
                        <Loading />
                      ) : (
                        <>
                          <StyledAppButton onClick={submitForm}>
                            {modal_type === "add" ? "Create Topic" : "Save Changes"}
                          </StyledAppButton>
                          {error && <AppErrorText>{error}</AppErrorText>}
                          {!!onDelete && <DeleteText onClick={deleteTopic}>Delete Topic</DeleteText>}
                        </>
                      )}
                    </SubmitContainer>
                  </div>
                );
              }}
            </Formik>
          </ModalWrapper>
        </NewFundContainer>
      </Modal>
    );
  },
);

const NewFundContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 25px 0px;
`;

const DeleteText = styled(AppText)`
  color: ${RED_COLOR};
  margin-top: 50px;
  margin-bottom: 25px;
  border-width: 0;
`;

export { DataRoomTopicModal };
