import React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR } from "../../utils/theme";

// interface AppSliderProps {
//   value: number;
// }

const height = "36px";
const trackHeight = "16px";

const upperColor = PRIMARY_COLOR;
const lowerColor = BLACK_COLOR;
const thumbColor = "#ddd";
const thumbHoverColor = "#ccc";
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

// Webkit cannot style progress so we fake it with a long shadow on the thumb element
const makeLongShadow = (color, size) => {
  let i = 8;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 2000; i++) {
    shadow = `${shadow}, ${i}px 0 0${size} ${color}`;
  }

  return shadow;
};

export const AppSlider = ({ ...props }) => {
  // console.log("props", props);
  return (
    <Styles>
      <CustomSlider type="range" min={0} max={100} {...(props as any)} />
    </Styles>
  );
};

const Styles = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  .value {
    flex: 1;
    font-size: 50px;
  }
`;

const CustomSlider = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: ${height};
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
    /* border-radius: 47%; */
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    width: 16px;
    height: 32px;
    background: ${thumbColor};
    border-radius: 4px;
    border: 1px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: ${makeLongShadow(upperColor, "-7.5px")};
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
    border-radius: 4px;
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    width: 16px;
    height: 32px;
    background: ${thumbColor};
    border-radius: 4px;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    width: 16px;
    height: 32px;
    background: ${thumbColor};
    border-radius: 4px;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;
