import React, { SFC, useEffect } from "react";
import styled from "styled-components";
import { Formik, FormikProps, FieldArray } from "formik";
import { FinalDilligenceCompanies } from "../../../types/dataTypes";
import { AppText, AppButton, AppFormTitle, AnimatedText, Loading, AppErrorText } from "../../UI";
import * as Yup from "yup";
import { FormInputField, FormSelectField, IconPickerField } from "../../Forms";
import { TrashIcon } from "../../Dumb";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { toast } from "react-toastify";
import { IndustryModelType } from "../../../models";

interface ResearchFormProps {
  data: IndustryModelType;
  fund_id: number;
}

interface MyFormikProps {
  name: string;
  companies_identified_count: number;
  initial_calls_count: number;
  secondary_dilligence_count: number;
  final_dilligence_count: number;
  final_dilligence_companies: FinalDilligenceCompanies[];
  portfolio_companies: number[];
  icon: string;
}

const ResearchFormSchema = Yup.object().shape({
  name: Yup.string().required("* Field is required"),
  icon: Yup.string().notRequired(),
  companies_identified_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  initial_calls_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  secondary_dilligence_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
  final_dilligence_count: Yup.number()
    .integer("* Must specify a whole number")
    .typeError("* Must specify a whole number")
    .required("* Field is required"),
});

const ResearchFormComponent: SFC<ResearchFormProps> = ({ fund_id, data: { id } }) => {
  const { setQuery, loading, error, store } = useQuery();
  const { data: companyData, loading: companyLoading, setQuery: companySetQuery } = useQuery((store) =>
    store.queryCompaniesByFund({ fundId: fund_id }, (qb) => qb.id.name.logo_url),
  );

  useEffect(() => {
    console.log("fund_id change... refetching: ", fund_id);
    const query = store.queryCompaniesByFund({ fundId: fund_id }, (qb) => qb.id.name.logo_url);
    companySetQuery(query);
  }, [fund_id]);

  const { data: industryData, loading: industryLoading, error: industryError } = useQuery((store) =>
    store.queryIndustry({ where: { id } }, (qb) =>
      qb.id.name.icon.companies_identified_count.initial_calls_count.secondary_dilligence_count.final_dilligence_count
        .final_dilligence_companies((b) => b.id.name.url)
        .portfolio_companies((b) => b.id.name.logo_url),
    ),
  );
  const deleteCompany = () => {
    const mutation = store.mutateDeleteOneIndustry({
      where: { id },
    });
    mutation.then(
      (v) => {
        toast("Industry Deleted!");
        store.localRemoveIndustry(id);
      },
      (e) => {
        toast("Could not delete industry!");
      },
    );
    setQuery(mutation);
  };

  if (industryLoading) {
    return <Loading />;
  }
  if (industryError) {
    return <p>Error fetching Industry</p>;
  }

  const {
    portfolio_companies,
    final_dilligence_companies,
    name,
    icon,
    companies_identified_count,
    final_dilligence_count,
    initial_calls_count,
    secondary_dilligence_count,
  } = industryData.industry;

  return (
    <ResearchFormContainer>
      <Formik
        initialValues={{
          name,
          icon,
          companies_identified_count,
          initial_calls_count,
          secondary_dilligence_count,
          final_dilligence_count,
          final_dilligence_companies: !!final_dilligence_companies.length
            ? final_dilligence_companies
            : [{ name: "", url: "", id: 0 }],
          portfolio_companies: !!portfolio_companies.length ? portfolio_companies : [0],
        }}
        validationSchema={ResearchFormSchema}
        onSubmit={async ({ portfolio_companies, final_dilligence_companies, ...industry }) => {
          const mutation = store.mutateUpdateIndustry({
            children: {
              industry: {
                ...industry,
                id,
              },
              portfolio_companies: portfolio_companies
                .filter((c) => !!c)
                // @ts-ignore
                .map((c) => parseInt(c.id)),
              final_dilligence_companies: final_dilligence_companies
                .filter((c) => c.name)
                .map((fd) => ({ id: fd.id, name: fd.name, url: fd.url })),
            },
          });
          mutation.then(
            (v) => {
              toast("Industry Updated!");
              store.localUpdateIndustry({
                id: v.updateIndustry.id,
                name: v.updateIndustry.name,
                ordinal: v.updateIndustry.ordinal,
                final_dilligence_count: v.updateIndustry.final_dilligence_count,
              });
            },
            (e) => {
              toast("Could not add industry!");
            },
          );
          setQuery(mutation);
        }}
      >
        {({ submitForm, values }: FormikProps<MyFormikProps>) => {
          return (
            <FormContainer>
              <NameSection>
                <FormInputField name={"name"} title="Name" />
              </NameSection>
              <FormSection>
                <Split>
                  <FormInputField
                    style={{
                      marginRight: 30,
                    }}
                    name="companies_identified_count"
                    title="COMPANIES IDENTIFIED"
                    type="number"
                  />
                  <FormInputField name="initial_calls_count" title="INITIAL CALLS" type="number" />
                </Split>
                <Split>
                  <FormInputField
                    style={{
                      marginRight: 30,
                    }}
                    name="secondary_dilligence_count"
                    title="SECONDARY DILLIGENCE"
                    type="number"
                  />
                  <FormInputField name="final_dilligence_count" title="FINAL DILLIGENCE" type="number" />
                </Split>
                <Split>
                  <IconPickerField name="icon" title="INDUSTRY ICON" />
                </Split>
              </FormSection>
              <FormSection>
                <InputTitle>FINAL DILLIGENCE COMPANIES</InputTitle>
                <FieldArray name="final_dilligence_companies">
                  {({ insert, remove, name }) => (
                    <FieldArrayContainer>
                      {values.final_dilligence_companies.map((fc, i) => {
                        return (
                          <Split key={fc.id}>
                            <SplitContainer>
                              <Split>
                                <FormInputField
                                  width={240}
                                  name={`final_dilligence_companies[${i}].name`}
                                  fontSize={20}
                                  placeholder="Company Name"
                                  noTopMargin
                                />
                                <FormInputField
                                  width={240}
                                  name={`final_dilligence_companies[${i}].url`}
                                  fontSize={20}
                                  placeholder="Company Website"
                                  noTopMargin
                                />
                              </Split>
                            </SplitContainer>
                            <TrashIcon onClick={() => remove(i)} />
                          </Split>
                        );
                      })}
                      <AddTextContainer>
                        <AnimatedText
                          onClick={() => {
                            insert(values.final_dilligence_companies.length, {
                              link: "",
                              title: "",
                              id: 0,
                            });
                          }}
                        >
                          Add Company
                        </AnimatedText>
                      </AddTextContainer>
                    </FieldArrayContainer>
                  )}
                </FieldArray>
              </FormSection>
              <InputTitle>PORTFOLIO COMPANIES</InputTitle>
              <FormSection>
                <FieldArray name="portfolio_companies">
                  {({ insert, remove, name }) => {
                    if (companyLoading) {
                      return <Loading />;
                    }
                    const companyList = companyData.companiesByFund.map((c) => ({
                      label: c.name,
                      value: c.id,
                    }));
                    return (
                      <FieldArrayContainer>
                        {values.portfolio_companies.map((company, i) => {
                          return (
                            <Split key={company}>
                              <FormSelectField
                                width={340}
                                name={`portfolio_companies[${i}].id`}
                                fontSize={20}
                                placeholder="Choose Company"
                                options={companyList}
                                type="number"
                              />
                              <TrashIcon onClick={() => remove(i)} />
                            </Split>
                          );
                        })}
                        <AddTextContainer>
                          <AnimatedText
                            onClick={() => {
                              insert(values.portfolio_companies.length, {
                                id: 0,
                              });
                            }}
                          >
                            Add Company
                          </AnimatedText>
                        </AddTextContainer>
                      </FieldArrayContainer>
                    );
                  }}
                </FieldArray>
              </FormSection>
              <SubmitContainer>
                {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                {error && <AppErrorText>{error}</AppErrorText>}
              </SubmitContainer>
              <DeleteContainer>
                {loading ? <Loading /> : <DeleteText onClick={deleteCompany}>Delete Industry</DeleteText>}
              </DeleteContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </ResearchFormContainer>
  );
};

const ResearchFormContainer = styled.div`
  width: calc(100%-100px);
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NameSection = styled.div`
  width: 100%;
`;

const FormSection = styled.div`
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 50px;
`;

const InputTitle = styled(AppFormTitle)`
  margin: 0;
  margin-bottom: 5px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;
const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  justify-content: center;
`;

const DeleteText = styled(AppText)`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.2;
  :hover {
    cursor: pointer;
  }
`;

const ResearchForm = observer(ResearchFormComponent);

export { ResearchForm };
