/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CapitalDeployedByIndustryItemModel, CapitalDeployedByIndustryItemModelType } from "./CapitalDeployedByIndustryItemModel"
import { CapitalDeployedByIndustryItemModelSelector } from "./CapitalDeployedByIndustryItemModel.base"
import { RootStoreType } from "./index"


/**
 * CapitalDeployedByIndustryBase
 * auto generated base class for the model CapitalDeployedByIndustryModel.
 */
export const CapitalDeployedByIndustryModelBase = ModelBase
  .named('CapitalDeployedByIndustry')
  .props({
    __typename: types.optional(types.literal("CapitalDeployedByIndustry"), "CapitalDeployedByIndustry"),
    industry: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late((): any => CapitalDeployedByIndustryItemModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CapitalDeployedByIndustryModelSelector extends QueryBuilder {
  industry(builder?: string | CapitalDeployedByIndustryItemModelSelector | ((selector: CapitalDeployedByIndustryItemModelSelector) => CapitalDeployedByIndustryItemModelSelector)) { return this.__child(`industry`, CapitalDeployedByIndustryItemModelSelector, builder) }
}
export function selectFromCapitalDeployedByIndustry() {
  return new CapitalDeployedByIndustryModelSelector()
}

export const capitalDeployedByIndustryModelPrimitives = selectFromCapitalDeployedByIndustry()
