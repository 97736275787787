import React from "react";
import { useField } from "formik";
import { AppSlider } from "../UI";

const SliderField = ({
  style,
  type = "range",
  ...props
}: {
  name: string;
  title?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
}) => {
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppSlider {...props} {...field} error={meta.touched && meta.error} type={type} label={props.title} />
    </div>
  );
};

export { SliderField };
