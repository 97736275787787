import React from "react";
import { useField } from "formik";
import { AppInput, AppInputProps, AppFormTitle, AppText } from "../UI";
import styled from "styled-components";

interface StaticTextFieldProps extends AppInputProps {
  name: string;
  title?: string;
  style?: React.CSSProperties;
  fontSize?: number;
  maxWidth?: number;
  marginBottom?: number;
  link?: boolean;
  id?: string;
}

const StaticTextField = ({ style, name, title, fontSize, maxWidth, marginBottom, link, id }: StaticTextFieldProps) => {
  const [field] = useField(name);
  return (
    <Div style={style} marginBottom={marginBottom}>
      {title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      {!!link ? (
        <a href={field.value} target="_blank">
          <ThisAppText id={id} maxWidth={maxWidth} fontSize={fontSize}>
            {field.value}
          </ThisAppText>
        </a>
      ) : (
        <ThisAppText id={id} maxWidth={maxWidth} fontSize={fontSize}>
          {field.value}
        </ThisAppText>
      )}
    </Div>
  );
};

interface DivProps {
  marginBottom?: number;
}

const Div = styled.div<DivProps>`
  height: 50px;
  margin-bottom: ${(props) => props.marginBottom ?? 50}px;
`;

interface TextProps {
  fontSize: number;
  maxWidth?: number;
}

const InputTitle = styled(AppFormTitle)`
  margin: 0;
  margin-bottom: 5px;
`;

const ThisAppText = styled(AppText)<TextProps>`
  font-size: ${(props) => props.fontSize}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => props.maxWidth || 500}px;
`;

ThisAppText.defaultProps = {
  fontSize: 24,
};

export { StaticTextField };
