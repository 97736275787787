import * as React from "react";
import styled from "styled-components";

interface BurgerProps {
  onClick: () => void;
  showX: boolean;
}

interface BurgerState {
  showX: boolean;
  visible: boolean;
}

// declare global {
//   interface Window {
//     pageYoffset: any;
//   }
// }

class BurgerIcon extends React.Component<BurgerProps, BurgerState> {
  constructor(props: BurgerProps) {
    super(props);

    this.state = {
      showX: false,
      visible: true,
    };
  }

  render() {
    const { onClick, showX } = this.props;
    return (
      <BurgerContainer visible={this.state.visible} showX={showX}>
        <BurgerIconDiv showX={showX} onClick={onClick}>
          <Bar />
          <Bar />
        </BurgerIconDiv>
      </BurgerContainer>
    );
  }
}

interface ContainerProps {
  visible: boolean;
  showX: boolean;
}

interface IconState {
  showX: boolean;
}
const BurgerContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  height: 40px;
  /* top: 50px; */
  top: 10px;
  width: 80px;
  z-index: 8;
  transition: top 300ms;
`;

const Bar = styled.span`
  display: block;
  width: 1.2em;
  height: 0.2em;
  margin: 0.3em;
  border-right: 1.2em solid #fff;
  /* 	border-left: 0.6em solid #d6d6d6; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-transition-property: -webkit-transform, margin, border-right-color, box-shadow;
  -moz-transition-property: -moz-transform, margin, border-right-color, box-shadow;
  transition-property: transform, margin, border-right-color, box-shadow; */
  -webkit-transition-duration: 600ms;
  -moz-transition-duration: 600ms;
  transition-duration: 600ms;
`;

const BurgerIconDiv = styled.div<IconState>`
  /* border: 1px solid white; */
  display: block;
  position: relative;
  width: 100%;
  margin: ${(props) => (props.showX ? "-3.2em 0 0 0.7em" : "-2.2em auto 0 0.2em")};
  -webkit-transition: margin 300ms;
  -moz-transition: margin 300ms;
  transition: margin 300ms ease;
  cursor: pointer;
  /* ${Bar}:nth-of-type(1) {
    opacity: ${(props) => (props.showX ? "0" : "1")};
    -webkit-transition: opacity 150ms;
    -moz-transition: opacity 150ms;
    transition: opacity 150ms; */
  }
  ${Bar}:nth-of-type(1) {
    -webkit-transform: ${(props) => (props.showX ? "rotate(-135deg) translateY(-0.95em)" : "rotate(-180deg)")};
    -moz-transform: ${(props) => (props.showX ? "rotate(-135deg) translateY(-0.95em)" : "rotate(-180deg)")};
    -ms-transform: ${(props) => (props.showX ? "rotate(-135deg) translateY(-0.95em)" : "rotate(-180deg)")};
    -o-transform: ${(props) => (props.showX ? "rotate(-135deg) translateY(-0.95em)" : "rotate(-180deg)")};
    transform: ${(props) => (props.showX ? "rotate(-135deg) translateY(-0.95em)" : "rotate(-180deg)")};
  }
  ${(props) =>
    props.showX &&
    `
    ${Bar}:nth-of-type(2) {
		margin-left: -0.5em;
		-webkit-transform: rotate(-45deg) translateY(0.20em);
		-moz-transform: rotate(-45deg) translateY(0.20em);
		-ms-transform: rotate(-45deg) translateY(0.20em);
		-o-transform: rotate(-45deg) translateY(0.20em);
    transform: rotate(-45deg) translateY(0.20em);
    }
  `};
`;

export { BurgerIcon };
