import React, { SFC, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { AppText, Loading } from "../../UI";
import { SmartListComponent } from "../../Smart";
import { ModalContext } from "../../../context";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import { PRIMARY_COLOR } from "../../../utils/theme";

interface Props {
  fund_id: number;
}

const ResearchComponent: SFC<Props> = ({ fund_id }) => {
  const { toggleIndustryModal, addIndustryModalVisible } = useContext(ModalContext);

  const {
    store: { industrys, industrysError, industrysLoading, fetchIndustrys },
  } = useQuery();

  useEffect(() => {
    fetchIndustrys();
  }, [fund_id]);

  if (industrysLoading) {
    return <Loading />;
  }
  if (industrysError) {
    return <p>Could not fetch industrys</p>;
  }
  return (
    <ResearchContainer modalActive={addIndustryModalVisible}>
      <ResearchHeader>
        <ResearchHeaderTitle>{`${industrys.length} Industries`}</ResearchHeaderTitle>
        <AddIndustry onClick={toggleIndustryModal}>+ Add Industry</AddIndustry>
      </ResearchHeader>
      <ListContainer>
        {!!industrys.length ? (
          <SmartListComponent type={"research"} data={industrys} />
        ) : (
          <NoIndustryNoticeContainer>
            <NoIndustryNoticeText>
              {`You haven't created industries for this fund yet. Start creating now!`}
            </NoIndustryNoticeText>
          </NoIndustryNoticeContainer>
        )}
      </ListContainer>
    </ResearchContainer>
  );
};

interface ContainerState {
  modalActive: boolean;
}

const ResearchContainer = styled.div<ContainerState>`
  min-height: 500px;
  overflow: ${(props) => (props.modalActive ? "hidden" : "visible")};
  height: ${(props) => (props.modalActive ? "80vh" : "100%")};
`;

const ResearchHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ResearchHeaderTitle = styled(AppText)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const AddIndustry = styled(AppText)`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
  position: relative;
  cursor: pointer;
  border-bottom: none;
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    /* margin-top: 0.1875rem; */
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  min-height: 133px;
  display: flex;
  justify-content: center;
`;

const NoIndustryNoticeContainer = styled.div`
  width: 550px;
`;

const NoIndustryNoticeText = styled(AppText)`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  opacity: 0.4;
  text-align: center;
`;

const Research = observer(ResearchComponent);

export { Research };
