/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * CapitalDeployedByIndustryItemBase
 * auto generated base class for the model CapitalDeployedByIndustryItemModel.
 */
export const CapitalDeployedByIndustryItemModelBase = ModelBase
  .named('CapitalDeployedByIndustryItem')
  .props({
    __typename: types.optional(types.literal("CapitalDeployedByIndustryItem"), "CapitalDeployedByIndustryItem"),
    amount: types.union(types.undefined, types.null, types.number),
    icon: types.union(types.undefined, types.null, types.string),
    industry_string: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CapitalDeployedByIndustryItemModelSelector extends QueryBuilder {
  get amount() { return this.__attr(`amount`) }
  get icon() { return this.__attr(`icon`) }
  get industry_string() { return this.__attr(`industry_string`) }
}
export function selectFromCapitalDeployedByIndustryItem() {
  return new CapitalDeployedByIndustryItemModelSelector()
}

export const capitalDeployedByIndustryItemModelPrimitives = selectFromCapitalDeployedByIndustryItem().amount.icon.industry_string
