import * as React from "react";
import styled from "styled-components";
import * as theme from "../../utils/theme";
import { Modal, Wrapper, AppText, AppTitle } from "../UI";

interface Props {
  showModal: boolean;
  toggleModal: () => void;
}

const DisclaimerPopupComponent: React.FunctionComponent<Props> = ({ showModal, toggleModal }) => {
  return (
    <PaddingDiv1>
      <Modal height="fit-content" width="fit-content" showModal={showModal} toggleModal={toggleModal}>
        <Wrapper>
          <PaddingDiv2>
            <DisclaimerTitle>DISCLAIMER</DisclaimerTitle>
            <DisclaimerText>By clicking “agree,” you acknowledge, represent and agree to the following:</DisclaimerText>
            <DisclaimerText>
              1. That you are an “accredited investor” as defined in Rule 501 of Regulation D promulgated under the U.S.
              Securities Act of 1933, as amended and that you will immediately contact Base10 Partners Management, LLC
              (the “Company”) if there is any change in your status as an “accredited investor.”
            </DisclaimerText>
            <DisclaimerText>
              2. That the information contained in this virtual data room, including any attachments (collectively “VDR
              Data”), is confidential and contains proprietary trade secrets, and other commercially sensitive
              information. You will strictly maintain the confidentiality of all VDR Data and will take normal and
              reasonable precautions to maintain such confidentiality so that you do not divulge VDR Data to any third
              party. You may not transmit the VDR Data to any third party nor reproduce, distribute or publish the VDR
              Data without prior written consent from the Company. Any unauthorized reproduction, recirculation or
              retranslation of the VDR Data to any other persons or entities is strictly prohibited and will result in
              appropriate legal action.
            </DisclaimerText>
            <DisclaimerText>
              3. Subject to your compliance with the terms hereof, you agree to use the VDR Data for the sole purposes
              of assessing whether to invest in Base10 Advancement Initiative I, L.P. (the “Fund”). The Company reserves
              the right to terminate, at any time, with or without cause, your access to the VDR Data, without giving
              notice of such termination. By clicking “accept,” you acknowledge and agree to (i) strictly maintain the
              confidentiality of all VDR Data and only use the VDR Data to evaluate an investment in the Fund (and not
              for any other purpose), and (ii) take normal and reasonable precautions to maintain such confidentiality
              so that the VDR Data is not divulged to any thirdparty. In addition, except otherwise agreed to in writing
              by the Company, the terms of any confidentiality agreement between you and Company, or any party acting on
              behalf of the Fund, shall be in addition to these terms, and such confidentiality agreement shall not be
              superseded by this click-through agreement. If you are being provided access to the VDR Data at the
              request of a prospective investor in the Fund, by clicking “agree,” you acknowledge that you have been
              given a copy of the terms of the confidentiality agreement between the Company and the potential investor,
              if any, and you agree to be bound by the same duties ofconfidentiality to Company and the Fund as such
              potential investor.
            </DisclaimerText>
            <DisclaimerText>
              4. That the VDR Data is owned by the Company and is protected by laws and international treaty provisions.
              That the Company retains all rights and titles to the VDR Data at all times.
            </DisclaimerText>
            <DisclaimerText>
              5. That the VDR Data has been prepared for informational purposes only and does not constitute financial,
              legal, tax or accounting advice or advice on the suitability or profitability of any investment. That the
              VDR Data is based upon information obtained from sources that the Company believes to be reliable.
              However, the Company has not and cannot verify, and does not accept responsibility or liability for, the
              accuracy or completeness of the VDR Data. Potential investors should be aware that the VDR Data is subject
              to change at any time without notice.
            </DisclaimerText>
            <DisclaimerText>
              6. That the use of the VDR Data may be restricted by local law or regulation in certain jurisdictions. The
              VDR Data is not intended to be published or made available to any person in any jurisdiction where doing
              so would contravene any applicable laws or regulations. Accessing the VDR Data in certain jurisdictions
              may be restricted by law.
            </DisclaimerText>
            <DisclaimerText>
              7. That you are aware of the laws in your own jurisdiction relating to the provision and sale of
              securities, financial services and products and you warrant that you will not pass on or utilize the
              information contained in the VDR Data in a manner that could constitute a breach of such laws.
            </DisclaimerText>
            <DisclaimerText>
              8. That you will be bound by all of the terms set forth above each and every time you enter this VDR or
              view or access VDR Data
            </DisclaimerText>
            <DisclaimerText>
              9. In the event that you are required by law to disclose the VDR Data, you shall promptly notify the
              Company in writing of such requirement, so that the Company may seek an appropriate protective order or
              other remedy and you shall provide such cooperation as the Company shall reasonably request. You also
              agree to return to the Company or destroy all electronic and other copies of VDR Data in your possession
              promptly upon request.
            </DisclaimerText>
            <DisclaimerText>
              10. You acknowledge and agree that monetary damages may be inadequate to compensate the Company for any
              breach of these terms and that, in addition to any other remedies that may be available, the Company may
              be entitled to obtain injunctive relief against the threatened breach of these terms or the continuation
              of any such breach.
            </DisclaimerText>
            <DisclaimerHeading>
              ACCORDINGLY YOU AGREE THAT ANY TIME YOU CLICK OR HAVE CLICKED ON AN “I ACCEPT,” “I AGREE,” “I CONSENT” OR
              OTHER SIMILARLY WORDED BUTTON OR ENTRY FIELD WITH A MOUSE, KEYSTROKE OR OTHER DEVICE, YOU AGREE TO BE
              BOUND BY THE TERMS AND CONDITIONS ACCOMPANYING SUCH PROMPT, AND YOUR AGREEMENT OR CONSENT IS LEGALLY
              BINDING AND ENFORCEABLE AND IS THE LEGAL EQUIVALENT OF A HANDWRITTEN SIGNATURE ON AN AGREEMENT THAT IS
              PRINTED ON PAPER.
            </DisclaimerHeading>
            <EnterPasswordDiv>
              <AcceptButton onClick={toggleModal}>ACCEPT AND CONTINUE</AcceptButton>
            </EnterPasswordDiv>
          </PaddingDiv2>
        </Wrapper>
      </Modal>
    </PaddingDiv1>
  );
};

const EnterPasswordDiv = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AcceptButton = styled.button`
  height: 40px;
  width: 300px;
`;

const PaddingDiv2 = styled.div`
  padding: 50px;
  min-height: 100vh;
  margin: auto auto;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
`;

const PaddingDiv1 = styled.div`
  padding: 50px;
`;

const DisclaimerText = styled(AppText)`
  font-size: 15px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
  margin-bottom: 10px;
`;

const DisclaimerHeading = styled(AppTitle)`
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: ${theme.BLACK_COLOR};
  margin-top: 15px;
`;

const DisclaimerTitle = styled(AppTitle)`
  font-size: 20px;
  color: ${theme.BLACK_COLOR};
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: underline;
`;

export { DisclaimerPopupComponent };
