import React, { Component } from "react";
import styled from "styled-components";
import { BLACK_COLOR } from "../../utils/theme";

interface DumbListComponentProps {
  data: any[];
  renderChildren: (arrayData: any) => JSX.Element;
}

interface DumbListComponentState {}

class DumbListComponent extends Component<DumbListComponentProps, DumbListComponentState> {
  constructor(props: DumbListComponentProps) {
    super(props);
  }
  render() {
    const { data, renderChildren } = this.props;
    return (
      <ListContainer>
        {data.map((item, index) => {
          return (
            <div key={index}>
              {renderChildren(item)}
              <ListSeperater />
            </div>
          );
        })}
      </ListContainer>
    );
  }
}

const ListContainer = styled.div`
  width: 100%;
  box-shadow: 0 8px 24px 0 rgba(26, 26, 26, 0.1);
  margin-bottom: 50px;
`;

const ListSeperater = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: ${BLACK_COLOR};
`;

export { DumbListComponent };
