import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AppTitle, AnimatedText, Loading, Wrapper, AnimatedLink, AppSubTitle, AppText } from "../UI";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, PRIMARY_FONT, PRIMARY_COLOR, SECONDARY_FONT, OFF_WHITE_BACKGROUND } from "../../utils/theme";
import { DisclaimerPopupComponent, VideoModal } from "../Modals";
import { ContactUs, Footer } from "../Segments";
import ReactGA from "react-ga";
import moment from "moment";
import { useHeadingsData } from "../../hooks";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";

import "../../App.css";
import { trackAdvancementFileClick } from "../../services/mixpanel";

interface ItemType {
  label: string;
  type: string;
  url: string;
}

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = useRef({});
  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          //@ts-ignore
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id),
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-110px 0px -40% 0px",
    });
    const headingElements = Array.from(document.querySelectorAll("h2, h3"));
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);
};

function capitalizeFirstLetter(string) {
  return string
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");
}

const Headings = ({ headings, activeId }) => (
  <StyledOl>
    {headings.map((heading) => (
      <StyledListItem key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <StyledLink
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            console.log("head id:", heading.id);
            const temp = document.querySelector(`#${heading.id}`);
            console.log("element heading: ", temp);
            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {/* {capitalizeFirstLetter(heading.title.toLowerCase())} */}
          {heading.title}
        </StyledLink>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <StyledListItem key={child.id} className={child.id === activeId ? "active" : ""}>
                <StyledLink
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </StyledLink>
              </StyledListItem>
            ))}
          </ul>
        )}
      </StyledListItem>
    ))}
  </StyledOl>
);

const TableOfContents = () => {
  const [activeId, setActiveId] = useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);
  return (
    <StyledNav aria-label="Table of contents">
      <TOCTitle>- Table of Contents -</TOCTitle>
      <Headings headings={nestedHeadings} activeId={activeId} />
    </StyledNav>
  );
};

const DataRoomComponent: FunctionComponent = () => {
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const history = useHistory();
  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };
  const {
    store,
    store: { selected_fund },
  } = useQuery();

  interface RouteTypes {
    id?: string;
  }

  const { id } = useParams<RouteTypes>();
  const selected_fund_id = id ? parseInt(id) : undefined;

  const {
    data: dataroomData,
    loading: dataroomLoading,
    error: dataroomError,
    setQuery: dataroomQuery,
    // setQuery: dataroomSetQuery,
  } = useQuery((store) => {
    if (store.user.role === "HBCU") {
      return store.queryDataRoomTopics(
        { where: { hbcu: { equals: true } } },
        (qb) => qb.topic_name.ordinal.id.items((items) => items.id.label.type.url.ordinal),
        { fetchPolicy: "network-only" },
      );
    }
    if (id) {
      return store.queryDataRoomTopics(
        { where: { hbcu: { equals: false }, fund_id: { equals: selected_fund_id } } },
        (qb) => qb.topic_name.ordinal.id.items((items) => items.id.label.type.url.ordinal),
        { fetchPolicy: "network-only" },
      );
    } else {
      return store.queryDataRoomTopics(
        { where: { hbcu: { equals: false }, OR: [{ fund_id: { notIn: [16] } }, { fund_id: { equals: null } }] } },
        (qb) => qb.topic_name.ordinal.id.items((items) => items.id.label.type.url.ordinal),
        { fetchPolicy: "network-only" },
      );
    }
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (dataroomData && dataroomData.dataRoomTopics) {
      setExpanded(dataroomData.dataRoomTopics.map((item) => true));
    }
  }, [dataroomData]);

  // redirect to investor dashboard if id is 21
  useEffect(() => {
    if (id === "21") {
      history.push("/investor-dashboard");
    }
  }, [id, history]);

  const flipExpanded = (index: number) =>
    setExpanded(
      expanded.map((item, i) => {
        if (i === index) {
          return !expanded[i];
        }
        return expanded[i];
      }),
    );

  useEffect(() => {
    if (!selected_fund) {
      return;
    }
    ReactGA.event({ action: `Dataroom: ${selected_fund.name}`, category: `Dataroom` });
  }, [selected_fund.name]);

  useEffect(() => {
    if (store.user.role === "HBCU") {
      const query = store.queryDataRoomTopics(
        { where: { hbcu: { equals: true } } },
        (qb) => qb.topic_name.id.ordinal.items((items) => items.id.ordinal.label.type.url),
        { fetchPolicy: "network-only" },
      );
      dataroomQuery(query);
      return;
    }
    if (selected_fund_id) {
      const query = store.queryDataRoomTopics(
        { where: { hbcu: { equals: false }, fund_id: { equals: selected_fund_id } } },
        (qb) => qb.topic_name.id.ordinal.items((items) => items.id.ordinal.label.type.url),
        { fetchPolicy: "network-only" },
      );
      dataroomQuery(query);
      return;
    } else {
      const query = store.queryDataRoomTopics(
        { where: { hbcu: { equals: false }, OR: [{ fund_id: { notIn: [16] } }, { fund_id: { equals: null } }] } },
        (qb) => qb.topic_name.id.ordinal.items((items) => items.id.ordinal.label.type.url),
        { fetchPolicy: "network-only" },
      );
      dataroomQuery(query);
      return;
    }
  }, [selected_fund.id]);

  if (dataroomLoading && !dataroomData) {
    return <Loading />;
  }

  if (dataroomError) {
    return <p>Error</p>;
  }

  console.log("dataRoomdata", dataroomData);
  const { dataRoomTopics } = dataroomData;

  return (
    <DataRoomcontainer>
      <VideoModal toggleModal={toggleVideoModal} showModal={videoModalVisible} url={videoLink} />
      <TableOfContents />
      <ThisWrapper>
        {!!store && !!store.user && store.user.role !== "HBCU" ? null : (
          <FundText>Advancement Initiative Fund Data Room</FundText>
        )}
        {dataRoomTopics
          .slice()
          .sort((a, b) => a.ordinal - b.ordinal)
          .map((topics, i) => {
            return (
              <DataContentSegment key={topics.topic_name}>
                <Vertical>
                  <DataRoomSegmentTitleHidden id={`${(topics.topic_name || "").replace(/[^a-zA-Z0-9]/g, "")}${i}`}>
                    {topics.topic_name}
                  </DataRoomSegmentTitleHidden>
                  <Horizontal>
                    <DataRoomSegmentTitle onClick={() => flipExpanded(i)}>{topics.topic_name}</DataRoomSegmentTitle>
                    {expanded.length && expanded[i] ? (
                      <IoIosArrowDown onClick={() => flipExpanded(i)} style={{ cursor: "pointer", paddingLeft: 5 }} />
                    ) : (
                      <IoIosArrowForward
                        onClick={() => flipExpanded(i)}
                        style={{ cursor: "pointer", paddingLeft: 5 }}
                      />
                    )}
                  </Horizontal>
                </Vertical>
                <DataListContainer>
                  <DataList>
                    {expanded.length &&
                      expanded[i] &&
                      topics.items
                        .slice()
                        .sort((a, b) => a.ordinal - b.ordinal)
                        .map((item: ItemType) => {
                          if (item.type === "PDF") {
                            return (
                              <DataListItem>
                                <StyledAnimatedLink
                                  onClick={() => {
                                    trackAdvancementFileClick(item.label);
                                    ReactGA.event({
                                      category: "Dataroom click",
                                      action: `${store.user.username}`,
                                      label: `PDF clicked: ${item.label} at ${moment().toLocaleString()}`,
                                    });
                                  }}
                                  href={item.url}
                                  target="_blank"
                                >
                                  {item.label}
                                </StyledAnimatedLink>
                              </DataListItem>
                            );
                          }
                          if (item.type === "LINK") {
                            return (
                              <DataListItem>
                                <StyledAnimatedLink
                                  onClick={() => {
                                    trackAdvancementFileClick(item.label);
                                    ReactGA.event({
                                      category: "Dataroom click",
                                      action: `${store.user.username}`,
                                      label: `Link clicked: ${item.label} at ${moment().toLocaleString()}`,
                                    });
                                  }}
                                  href={item.url}
                                  target="_blank"
                                >
                                  {item.label}
                                </StyledAnimatedLink>
                              </DataListItem>
                            );
                          }
                          if (item.type === "VIDEO") {
                            return (
                              <DataListItem>
                                <StyledAnimatedText
                                  onClick={() => {
                                    trackAdvancementFileClick(item.label);
                                    ReactGA.event({
                                      category: "Dataroom click",
                                      action: `${store.user.username}`,
                                      label: `Video clicked: ${item.label} at ${moment().toLocaleString()}`,
                                    });
                                    toggleVideoModal();
                                    setVideoLink(item.url);
                                  }}
                                >
                                  {item.label}
                                </StyledAnimatedText>
                              </DataListItem>
                            );
                          }
                        })}
                  </DataList>
                </DataListContainer>
              </DataContentSegment>
            );
          })}
      </ThisWrapper>
      <ContactUs />
      <Footer />
    </DataRoomcontainer>
  );
};

const DataContentSegment = styled.div`
  /* width: calc(83.33333% - 55px); */
  float: left;
  /* margin-left: 30px; */
  /* margin-left: calc(8.33333% - 33px + 60px); */
  padding-top: 20px;
  padding-bottom: 30px;
  background: ${OFF_WHITE_BACKGROUND};
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const DataRoomcontainer = styled.div`
  padding-top: 120px;
  /* width: 100%; */
  /* min-height: 100px; */
  overflow: hidden;
  background-color: ${OFF_WHITE_BACKGROUND};
  ${DataContentSegment}+${DataContentSegment}{
    padding-top: 0;
  }
  ${DataContentSegment}:last-child{
    padding-bottom: 120px;
  }
`;

const DataRoomSegmentTitle = styled(AppTitle)`
  font-family: ${SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 2px;
  :hover {
    cursor: pointer;
  }
`;

const DataRoomSegmentTitleHidden = styled(AppSubTitle)`
  font-family: ${SECONDARY_FONT};
  font-weight: 400;
  color: ${OFF_WHITE_BACKGROUND};
  /* text-transform: uppercase; */
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 2px;
`;

const DataListContainer = styled.div`
  margin-top: 11px;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataList = styled.ul`
  list-style: none;
  padding-left: 30px;
  @media ${mobileSize} {
    padding-left: 20px;
  }
`;

const FundText = styled.div`
  height: 50px;
  width: auto;
  font-family: ${PRIMARY_FONT};
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  color: #000;
  margin-left: 30px;
  @media ${mobileSize} {
    flex-direction: column;
    margin-left: 20px;
  }
`;

const DataListItem = styled.li`
  display: list-item;
  text-align: -webkit-match-parent;
  position: relative;
  color: rgba(26, 26, 26, 0.6);
  ::before {
    content: "·";
    position: absolute;
    color: ${PRIMARY_COLOR};
    top: -8px;
    right: auto;
    left: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    font-size: 36px;
    line-height: 1.38889;
    letter-spacing: -1px;
  }
`;

const StyledAnimatedLink = styled(AnimatedLink)`
  font-family: ${PRIMARY_FONT};
  color: ${BLACK_COLOR};
  font-size: 20px;
  line-height: 1.6;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 1.33333;
  }
`;

const StyledAnimatedText = styled(AnimatedText)`
  font-family: ${PRIMARY_FONT};
  color: ${BLACK_COLOR};
  font-size: 20px;
  line-height: 1.6;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 1.33333;
  }
  ::after {
    height: 2px;
    bottom: -2px;
  }
`;

const StyledNav = styled.nav`
  position: fixed;
  top: 150px;
  left: 10px;
  /* position: sticky;
  position: -webkit-sticky; */

  max-width: 200px;
  @media ${mobileSize} {
    display: none;
  }
`;

const StyledOl = styled.ol`
  /* Give table of contents a scrollbar */
  /*Calculation: 100vh(window) - 150px(top offset) - 18px(header font size) - 16*2(ol margin)*/
  max-height: calc(100vh - 200px);
  overflow: auto;
`;

const StyledLink = styled.a`
  color: grey;
  text-decoration: none;
  font-family: ${PRIMARY_FONT};
  :active {
    color: white;
  }
  :hover {
    color: ${PRIMARY_COLOR};
  }
`;

const DataRoom = observer(DataRoomComponent);

const ThisWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* max-width: 100%; */
  margin-left: 300px;
  margin-right: 20px;
  @media ${mobileSize} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;
`;

const TOCTitle = styled(AppText)`
  font-weight: 600;
  font-size: 18px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { DataRoom };
