import * as React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, BLACK_COLOR, RED_COLOR, PRIMARY_FONT, SECONDARY_FONT } from "../../utils/theme";
import { useState } from "react";

export interface AppInputProps extends React.HTMLProps<HTMLInputElement> {
  currency?: boolean;
  error?: string;
  width?: number;
  fontSize?: number;
  textAlign?: string;
  /**
   * When there is no title, leave out a top margin
   */
  noTopMargin?: boolean;
  /**
   * If true, will not display an error message even if there is an error message
   */
  displayNoError?: boolean;
}

export const StyledAppInput = styled.input<AppInputProps>`
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  height: 50px;
  padding-left: ${(props) => (props.currency ? 25 : 5)}px;
  border-width: 0;
  border-color: transparent;
  margin-top: ${(props) => (!props.title ? (props.noTopMargin ? 0 : 19) : 0)}px;
  border-bottom-color: ${(props) => (props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  border-bottom-width: 2px;
  font-size: ${(props) => (!!props.fontSize ? props.fontSize : 36)}px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  box-sizing: border-box;
  color: ${BLACK_COLOR};
  background-color: transparent;
  caret-color: ${PRIMARY_COLOR};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    border-bottom-color: ${PRIMARY_COLOR};
    outline: none;
  }
  ::placeholder {
    opacity: 0.4;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  }
`;

StyledAppInput.defaultProps = {
  noTopMargin: false,
};

export const StyledFileInput = styled.input<AppInputProps>`
  display: none;
`;

export const FileLabel = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 14px 12px;
  border-radius: 4px;
  background: ${BLACK_COLOR};
  color: #ffffff;
  cursor: pointer;
`;

const AppInputComponent = ({
  currency = false,
  displayNoError = false,
  title,
  error,
  ...props
}: AppInputProps & React.HTMLProps<HTMLInputElement>) => {
  const [isFocused, setFocus] = useState(false);
  const toggleFocus = () => setFocus(!isFocused);
  const { type, step, min, max } = props;
  return (
    <Div width={props.width}>
      {!!title && <InputTitle>{title.toUpperCase()}</InputTitle>}
      {currency && (
        <LeftText error={!!error} focused={isFocused}>
          $
        </LeftText>
      )}
      {currency && (
        <RightText error={!!error} focused={isFocused}>
          M
        </RightText>
      )}
      <StyledAppInput
        {...(props as any)}
        onFocus={toggleFocus}
        onBlur={(e) => {
          toggleFocus();
          !!props.onBlur && props.onBlur(e);
        }}
        currency={currency}
        title={title}
        error={error}
        max={max}
        min={min}
        {...(type === "number"
          ? {
              // value: Number(value)?.toFixed(1),
              step: step,
              min: min ?? 0,
              max: max ?? 1000000000000,
            }
          : {})}
      />
      {displayNoError ? undefined : !!error ? (
        <InputErrorText>{error}</InputErrorText>
      ) : (
        <InputErrorText>&nbsp;</InputErrorText>
      )}
    </Div>
  );
};

interface DivProps {
  width?: number;
}

const Div = styled.div<DivProps>`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : `100%`)};
  display: flex;
  flex-direction: column;
`;

interface TextFocus {
  focused?: boolean;
  error?: boolean;
}

const LeftText = styled.p<TextFocus>`
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  color: ${(props) => (props.focused ? PRIMARY_COLOR : props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

const RightText = styled.p<TextFocus>`
  position: absolute;
  right: 0;
  bottom: 15px;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: ${PRIMARY_FONT};
  color: ${(props) => (props.focused ? PRIMARY_COLOR : props.error ? RED_COLOR : "rgba(26, 26, 26, 0.2)")};
  margin: 0;
`;

RightText.defaultProps = {
  focused: false,
  error: false,
};

LeftText.defaultProps = {
  focused: false,
  error: false,
};

// TODO: Add font to theme

const InputTitle = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${BLACK_COLOR};
  margin: 0;
  margin-bottom: 5px;
`;

const InputErrorText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${RED_COLOR};
  margin: 0;
`;

//const AppInput = React.memo(AppInputComponent, (p, n) => p === n);
const AppInput = AppInputComponent;

export { AppInput };
