/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RoleEnum } from "./RoleEnum"
import { UserSessionModel, UserSessionModelType } from "./UserSessionModel"
import { UserSessionModelSelector } from "./UserSessionModel.base"
import { RootStoreType } from "./index"


/**
 * UserBase
 * auto generated base class for the model UserModel.
 */
export const UserModelBase = ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    username: types.union(types.undefined, types.string),
    email: types.union(types.undefined, types.string),
    initial_password: types.union(types.undefined, types.null, types.string),
    password: types.union(types.undefined, types.string),
    role: types.union(types.undefined, RoleEnum),
    session_token: types.union(types.undefined, types.string),
    url: types.union(types.undefined, types.null, types.string),
    accessible_funds: types.union(types.undefined, types.array(types.integer)),
    accessible_letters: types.union(types.undefined, types.array(types.string)),
    hbcu_data_room_access: types.union(types.undefined, types.null, types.boolean),
    updated_at: types.union(types.undefined, types.null, types.frozen()),
    created_date: types.union(types.undefined, types.null, types.frozen()),
    user_sessions: types.union(types.undefined, types.array(types.late((): any => UserSessionModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get username() { return this.__attr(`username`) }
  get email() { return this.__attr(`email`) }
  get initial_password() { return this.__attr(`initial_password`) }
  get password() { return this.__attr(`password`) }
  get role() { return this.__attr(`role`) }
  get session_token() { return this.__attr(`session_token`) }
  get url() { return this.__attr(`url`) }
  get accessible_funds() { return this.__attr(`accessible_funds`) }
  get accessible_letters() { return this.__attr(`accessible_letters`) }
  get hbcu_data_room_access() { return this.__attr(`hbcu_data_room_access`) }
  get updated_at() { return this.__attr(`updated_at`) }
  get created_date() { return this.__attr(`created_date`) }
  user_sessions(builder?: string | UserSessionModelSelector | ((selector: UserSessionModelSelector) => UserSessionModelSelector)) { return this.__child(`user_sessions`, UserSessionModelSelector, builder) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().name.username.email.initial_password.password.role.session_token.url.accessible_funds.accessible_letters.hbcu_data_room_access.updated_at.created_date
