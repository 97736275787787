import React, { useEffect, FunctionComponent, useState, useContext } from "react";
import styled from "styled-components";
import { Wrapper, AppSelect } from "../UI";
import { observer } from "mobx-react";
import { mobileSize } from "../../utils/breakpoints";
import { PRIMARY_FONT, OFF_WHITE_BACKGROUND, PRIMARY_COLOR } from "../../utils/theme";
import { ContactUs, Footer } from "../Segments";
import { prismicClient, Prismic, RichText } from "../../services";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { StoreContext, useQuery } from "../../models/reactUtils";
import { UserContext } from "../../context";
import ReactGA from "react-ga";

type Props = RouteComponentProps<{
  nonauth: string;
}>;

const QuarterlyUpdateMayComponent: FunctionComponent<Props> = (props) => {
  const [blog, setBlog] = useState(undefined);
  const [fullBlogList, setFullBlogList] = useState([]);
  const [blogIndex, setBlogIndex] = useState(0);
  const [blogList, setBlogList] = useState([{ value: 0, label: "" }]);
  const store = useContext(StoreContext);
  const {
    store: { user },
  } = useQuery();

  const getPrismicFundTags = () => {
    console.log("user: ", user);
    if (!user) {
      return ["fund_i", "fund_ii"];
    }
    if (!user.accessible_funds) {
      return ["fund_i", "fund_ii"];
    }
    if (!user.accessible_funds.length) {
      return ["fund_i", "fund_ii"];
    }
    if (user.accessible_funds.includes(12) && user.accessible_funds.includes(13)) {
      return ["fund_i", "fund_ii"];
    }
    if (user.accessible_funds.includes(12)) {
      return ["fund_i"];
    }
    if (user.accessible_funds.includes(13)) {
      return ["fund_ii"];
    }
    return ["fund_i", "fund_ii"];
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!blog) {
      return;
    }
    ReactGA.event({ action: blog.data.title1, category: "Update Letter View" });
  }, [blog]);

  useEffect(() => {
    const fetchData = async () => {
      // const response = await prismicClient.query([
      //   Prismic.Predicates.at("document.type", "quarterly_letter"),
      //   Prismic.Predicates.any("document.tags", getPrismicFundTags()),
      // ]);
      const response = await prismicClient.query([Prismic.Predicates.at("document.type", "quarterly_letter")]);
      console.log("prismic response: ", response);
      if (response) {
        const blogs = response.results
          .map((r) => ({ ...r, first_publication_date: new Date(r.first_publication_date) }))
          .sort((a, b) => b.first_publication_date.getTime() - a.first_publication_date.getTime());
        const selectFields = blogs.reduce((array, currentValue, index) => {
          if (currentValue.data.title1[0].text.includes("may")) {
            array.push({
              label: currentValue.data.title1[0].text as string,
              value: index,
            });
          }
          array.push({
            label: currentValue.data.title1[0].text as string,
            value: index,
          });
          return array;
        }, []);
        setBlog(blogs[blogIndex]);
        setBlogList(selectFields);
        setFullBlogList(blogs);
      }
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    setBlog(fullBlogList[blogIndex]);
  }, [blogIndex]);

  const myCustomLink = (type, element, content, children, index) => {
    console.log("type: ", type);
    console.log("element: ", element);
    console.log("content: ", content);
    console.log("index: ", index);
    return (
      <a href={element.data.url} target="_blank">
        {content}
      </a>
    );
  };

  const { is_authenticated } = store;
  const { quarterly_authenticated } = useContext(UserContext);

  const handleSelectQuarter = (event: any) => {
    event.preventDefault();
    setBlogIndex(event.target.value);
  };

  if (!is_authenticated) {
    console.log("quarterly_authenticated", quarterly_authenticated);

    if (props.match.params.nonauth !== "success" || !quarterly_authenticated) {
      return <Redirect to="/login/quarterly-letter" />;
    }
  }
  // dummy

  return (
    <QuarterlyUpdatecontainer>
      <Wrapper>
        {/* <SelectFieldContainer>
          <AppSelect
            options={blogList}
            onChange={handleSelectQuarter}
            placeholder={"Switch letter"}
            width={375}
            fontSize={18}
            color={PRIMARY_COLOR}
            ignorePlaceholderOpacity
          />
        </SelectFieldContainer> */}
        {blog && (
          <PrismicContainer>
            <RichText render={blog.data.title1} />
            <RichText serializeHyperlink={myCustomLink} render={blog.data.body} />
          </PrismicContainer>
        )}
      </Wrapper>
      <ContactUs />
      <Footer />
    </QuarterlyUpdatecontainer>
  );
};

const PrismicContainer = styled.div`
  font-family: ${PRIMARY_FONT};
  line-height: 1.6;
  font-size: 20px;
`;

const DataContentSegment = styled.div`
  width: calc(83.33333% - 55px);
  float: left;
  margin-left: 30px;
  margin-left: calc(8.33333% - 33px + 60px);
  padding-top: 120px;
  padding-bottom: 30px;
  background: ${OFF_WHITE_BACKGROUND};
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const QuarterlyUpdatecontainer = styled.div`
  padding-top: 120px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  background-color: ${OFF_WHITE_BACKGROUND};
  ${DataContentSegment}+${DataContentSegment}{
    padding-top: 0;
  }
  ${DataContentSegment}:last-child{
    padding-bottom: 120px;
  }
`;

const SelectFieldContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
`;

const QuarterlyUpdateMay = observer(QuarterlyUpdateMayComponent);

export { QuarterlyUpdateMay };
