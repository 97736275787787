import React, { FunctionComponent, useState, useContext, useEffect } from "react";
import { Formik, FormikProps } from "formik";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";
import * as Yup from "yup";
import styled, { keyframes } from "styled-components";
import { FormInputField } from "../Forms";
import { mobileSize } from "../../utils/breakpoints";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../utils/theme";
import { observer } from "mobx-react";
import { Base10Logo } from "../../svg/base10Logo";
import { AppButton, Loading } from "../UI";
import { StoreContext } from "../../models/reactUtils";
import { toast } from "react-toastify";
import { makeToast } from "../../utils/toast";
import ReactGA from "react-ga";

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("* Password is required")
    .min(3, "* Password must be 3-15 characters")
    .max(15, "* Password must be 3-15 characters")
    .matches(/^[a-zA-Z0-9]+$/, {
      message: "Alphanumeric characters only",
      excludeEmptyString: true,
    }),
  password_confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "* Passwords do not match")
    .required("* Please specify a password")
    .min(3, "* Password must be 3-15 characters")
    .max(15, "* Password must be 3-15 characters")
    .matches(/^[a-zA-Z0-9]+$/, {
      message: "Alphanumeric characters only",
      excludeEmptyString: true,
    }),
});

interface MyFormikProps {
  password: string;
  password_confirm: string;
}

interface NavigationProps {
  username?: string;
}

type Props = RouteComponentProps<NavigationProps>;

const NewPasswordComponent: FunctionComponent<Props> = (props) => {
  const [errorMessage, SetErrorMessage] = useState("");
  // const { setQuery, loading, error, data } = useQuery();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const store = useContext(StoreContext);

  return (
    <NewPasswordContainer>
      <Formik
        initialValues={{
          password: "",
          password_confirm: "",
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={async (values, { setStatus }) => {
          const { password } = values;
          const {
            match: {
              params: { username },
            },
          } = props;
          setStatus({ error: "", loading: true });
          try {
            const mutation = await store.newPassword({ username, password });
            if (!mutation.success) {
              SetErrorMessage(mutation.message);
            }
            toast({ message: "Your password has been reset" });
            props.history.push(`/login`);
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({
          status,
          setStatus,
          handleSubmit,
          handleChange,
          submitForm,
          isSubmitting,
          values,
        }: FormikProps<MyFormikProps>) => {
          console.log("status", status);
          return (
            <NewPasswordBox>
              <NewPasswordTitle>
                <Base10Logo color={BLACK_COLOR} />
              </NewPasswordTitle>
              <NewPasswordMessage>
                Now, please input a new unique password in the fields below, hit submit and sign in on the next page
              </NewPasswordMessage>
              {errorMessage && <LoginMessage>{errorMessage}</LoginMessage>}
              <NewPasswordFormContainer>
                <InputFieldContainer>
                  <FormInputField name="password" title="Password" fullWidth={true} type="password" fontSize={20} />
                  <FormInputField
                    name="password_confirm"
                    title="Confirm Password"
                    fullWidth={true}
                    type="password"
                    fontSize={20}
                  />
                </InputFieldContainer>
                <div>
                  {isSubmitting ? (
                    <Loading />
                  ) : (
                    <LoginButton color={PRIMARY_COLOR} onClick={submitForm} disabled={isSubmitting}>
                      Submit
                    </LoginButton>
                  )}
                </div>
              </NewPasswordFormContainer>
              <LostPasswordContainer>
                <StyledLink to="/login">Log in</StyledLink>
              </LostPasswordContainer>
            </NewPasswordBox>
          );
        }}
      </Formik>
    </NewPasswordContainer>
  );
};

const NewPasswordContainer = styled.div`
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f7f9fa;
  min-width: 0;
  color: #444;
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 1.4;
  display: block;
  background-color: #f7f9fa;
`;

const NewPasswordBox = styled.div`
  width: 480px;
  padding: 8% 0 0;
  margin: auto;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const LoginButton = styled(AppButton)`
  background-color: ${PRIMARY_COLOR};
  color: #fff;
`;

const NewPasswordTitle = styled.h1`
  text-align: center;
  display: block;
  font-size: 16px;
  margin-block-start: 11px;
  margin-block-end: 11px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`;

const NewPasswordMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: ${BLACK_COLOR};
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const LoginMessage = styled.p`
  margin-top: 60px;
  margin-bottom: -60px;
  padding: 27px 60px;
  border-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: ${BLACK_COLOR};
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-top: 40px;
    margin-bottom: -40px;
    padding: 17px 40px;
  }
`;

const NewPasswordFormContainer = styled.div`
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccd0d4;
  margin-top: 60px;
  padding: 60px;
  -webkit-box-shadow: none;
  box-shadow: none;
  @media ${mobileSize} {
    margin-top: 40px;
    padding: 40px;
  }
`;

const InputFieldContainer = styled.div`
  padding-bottom: 20px;
  min-height: 90px;
`;

const LostPasswordContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const linkAnimation = keyframes`
0% {width:0} 100%{width:100%}
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  cursor: pointer;
  border-bottom: 0;
  color: ${BLACK_COLOR};
  ::after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    width: 100%;
    margin-top: 10;
    background-color: ${PRIMARY_COLOR};
  }
  :hover {
    cursor: pointer;
    text-decoration: none;
    transition: width 0.25s;
    ::after {
      -webkit-animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
      animation: ${linkAnimation} 0.5s cubic-bezier(1, 0, 0, 1) forwards;
    }
  }
`;

const NewPassword = observer(withRouter(NewPasswordComponent));

export { NewPassword };
