import React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, BLACK_COLOR } from "../../utils/theme";

interface CapitalDeployedProgressBarProps {
  deployed: number;
}

export const CapitalDeployedProgressBar = (props: CapitalDeployedProgressBarProps) => {
  return (
    <StyledCapitalDeployedProgressBarContainer>
      <StyledCapitalDeployedProgressBar deployed={props.deployed} />
    </StyledCapitalDeployedProgressBarContainer>
  );
};

const StyledCapitalDeployedProgressBarContainer = styled.div`
  background-color: ${PRIMARY_COLOR};
  height: 10px;
  width: 100%;
  position: relative;
`;

const StyledCapitalDeployedProgressBar = styled.div<CapitalDeployedProgressBarProps>`
  width: ${(props) => `${(props.deployed / 100) * 100}%`};
  position: absolute;
  left: 0;
  height: 10px;
  background-color: ${BLACK_COLOR};
`;
