export const stageOrder = (a, b) => {
  const aa = _stagePosition(a.stage);
  const bb = _stagePosition(b.stage);
  return bb - aa;
};

const _stagePosition = (stage: string) => {
  switch (stage) {
    case "Seed":
      return 5;
    case "Series A":
      return 4;
    case "Series A-1":
      return 3;
    case "Series B":
      return 2;
    case "Series C":
      return 1;
  }
};
