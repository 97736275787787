import * as React from "react";
import styled from "styled-components";
import { IconPickerItem, iconList } from ".";
import { useState, useEffect, useRef } from "react";
import { AppInput } from "../../UI";

interface IconPickerProps {
  value: string;
  onChange: (value: string) => void;
}

const IconPicker: React.SFC<IconPickerProps> = ({ value, onChange }) => {
  const ref = useRef(null);
  const [display, changeDisplay] = useState(false);
  const [searchString, setSearchString] = useState("");
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        changeDisplay(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  const buttonClick = () => changeDisplay(!display);
  const onChangeSearch = (event: any) => {
    setSearchString(event.target.value);
  };
  return (
    <Container ref={ref} onClick={() => buttonClick()}>
      <IconPickerItem icon={value} />
      {display && (
        <PickerContainer onClick={(e) => e.stopPropagation()}>
          <AppInput onChange={onChangeSearch} value={searchString} placeholder="Search" fontSize={20} />
          {iconList
            .filter((i) => i.toLowerCase().includes(searchString.toLowerCase()))
            .map((icon) => (
              <IconPickerItem
                key={icon}
                icon={icon}
                onClick={(value) => {
                  onChange(value);
                  changeDisplay(false);
                  setSearchString("");
                }}
              />
            ))}
        </PickerContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 5px;
  width: 50px;
  min-height: 40px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #000;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const PickerContainer = styled.div`
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  background-color: #fff;
  padding: 5px;
  width: 200px;
  max-height: 300px;
  border-radius: 4px;
  border-width: 2px;
  border-color: #000;
  border-style: solid;
  z-index: 10;
`;

export { IconPicker };
