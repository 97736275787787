import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, PRIMARY_FONT } from "../../../utils/theme";
import { CapitalDeployedProgressBar, AppPanelTitle, Loading, AppText, AppTitle } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import chart2 from "../../../data/chart2.png";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";

interface FundProp {
  fund_id: number;
}

const AdvancementInitiative1Chart2Panel: FunctionComponent<FundProp> = ({ fund_id }) => {
  const { store } = useQuery();
  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: fund_id } }, (qb) => qb.advancement_initiative_portfolio_revenue_growth_footnote),
  );

  useEffect(() => {
    const query = store.queryFund(
      { where: { id: fund_id } },
      (qb) => qb.advancement_initiative_portfolio_revenue_growth_footnote,
    );
    fundQuery(query);
  }, [fund_id]);

  if (fundLoading) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  const {
    fund: { advancement_initiative_portfolio_revenue_growth_footnote },
  } = fundData;

  return (
    <MostOfPanelContainer>
      <PanelWrapper>
        <AppPanelTitle>
          Advancement Initiative Portfolio Revenue growth vs Top10 fastest growing Public Comps
        </AppPanelTitle>
        <PanelContent>
          <ChartTitle></ChartTitle>
          <ChartImg src={chart2} />
          <FooterText>{advancement_initiative_portfolio_revenue_growth_footnote}</FooterText>
        </PanelContent>
      </PanelWrapper>
    </MostOfPanelContainer>
  );
};

const MostOfPanelContainer = styled(HalfPanelContainer)`
  width: calc(75% - 45px);
`;

const ChartTitle = styled(AppTitle)`
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 17px;
`;

const ChartImg = styled.img`
  width: 100%;
  height: auto;
`;

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 80px;
`;

const AdvancementInitiative1Chart2 = observer(AdvancementInitiative1Chart2Panel);

export { AdvancementInitiative1Chart2 };
