/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { RootStoreType } from "./index"


/**
 * CompanyMemberBase
 * auto generated base class for the model CompanyMemberModel.
 */
export const CompanyMemberModelBase = ModelBase
  .named('CompanyMember')
  .props({
    __typename: types.optional(types.literal("CompanyMember"), "CompanyMember"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    role: types.union(types.undefined, types.null, types.string),
    url: types.union(types.undefined, types.null, types.string),
    company_id: types.union(types.undefined, types.null, types.integer),
    company: types.union(types.undefined, types.null, types.late((): any => CompanyModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompanyMemberModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get role() { return this.__attr(`role`) }
  get url() { return this.__attr(`url`) }
  get company_id() { return this.__attr(`company_id`) }
  company(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`company`, CompanyModelSelector, builder) }
}
export function selectFromCompanyMember() {
  return new CompanyMemberModelSelector()
}

export const companyMemberModelPrimitives = selectFromCompanyMember().name.role.url.company_id
