import styled from "styled-components";

export const CloseModal = styled.div`
  cursor: pointer;
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: #ffffff;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.8;
  }
`;
