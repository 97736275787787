import * as React from "react";
import styled from "styled-components";
import { AppPanelTitle, Loading, AppText, AppTitle } from "../../UI";
import { HalfPanelContainer, PanelContainer, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";

interface FundProp {
  fund_id: number;
}

const FootersAndDisclosuresComponent: FunctionComponent<FundProp> = ({ fund_id }) => {
  const { store } = useQuery();
  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: fund_id } }, (qb) => qb.footnotes_and_disclosesures),
  );

  useEffect(() => {
    const query = store.queryFund({ where: { id: fund_id } }, (qb) => qb.footnotes_and_disclosesures);
    fundQuery(query);
  }, [fund_id]);

  if (fundLoading) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  const {
    fund: { footnotes_and_disclosesures },
  } = fundData;

  let segmentedFooters = [];

  if (!!footnotes_and_disclosesures) {
    segmentedFooters = footnotes_and_disclosesures.split("\\n");
  }

  if (segmentedFooters.length === 0) {
    return null;
  }

  return (
    <PanelContainer>
      <PanelWrapper>
        <AppPanelTitle style={{ marginBottom: "40px" }}>Footnotes and Disclosures</AppPanelTitle>
        {segmentedFooters.length &&
          segmentedFooters.map((footer, i) => (
            <>
              <FooterText key={`footer-${i}`}>{footer}</FooterText>
              <br />
            </>
          ))}
      </PanelWrapper>
    </PanelContainer>
  );
};

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 20px;
`;

const FootersAndDisclosures = observer(FootersAndDisclosuresComponent);

export { FootersAndDisclosures };
