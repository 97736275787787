import * as React from "react";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AppSelect, CMSBodyWrapper, Loading } from "../UI";
import { BsGear } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import { observer } from "mobx-react";
import { BLACK_COLOR, PRIMARY_COLOR, BACKGROUND_COLOR } from "../../utils/theme";
import { Wrapper } from "../UI";
import { OverallForm, Portfolio, Research, Trend, DataRoom, DataRoomHBCU, Users } from "../Segments/CMS";
import { SFC, useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context";
import { useQuery } from "../../models/reactUtils";
import ReactGA from "react-ga";

const EditInfoComponent: SFC = () => {
  const [page, setPage] = useState("overall");
  // const [selectedFund, changeSelectedFund] = useState("");
  const {
    generalSettingsModalVisible,
    newFundModalVisible,
    toggleGeneralSettingsModal,
    toggleNewFundModal,
  } = useContext(ModalContext);
  const handleSelectPage = (event: React.ChangeEvent<unknown>, value: any) => {
    console.log("value: ", value);
    setPage(value);
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // const { error, loading, data } = useQuery((store) =>
  //   store.queryFunds({}, (qb) => qb.name.id)
  // );
  const {
    store: {
      user: { role },
    },
    store: { fundsError, fundsLoading, fund_list, selectFund, selected_fund },
  } = useQuery();
  const renderEditPage = () => {
    switch (page) {
      case "overall":
        return <OverallForm fund_id={selected_fund.id} />;
      case "portfolio":
        return <Portfolio fund_id={selected_fund.id} />;
      case "industry":
        return <Research fund_id={selected_fund.id} />;
      case "trend":
        return <Trend fund_id={selected_fund.id} />;
      case "dataroom":
        return <DataRoom fund_id={selected_fund.id} />;
      case "dataroomHBCU":
        return <DataRoomHBCU fund_id={selected_fund.id} />;
      case "users":
        return <Users />;
    }
  };
  const handleSelectFund = (event: any) => {
    event.preventDefault();
    selectFund(event.target.value);
    // changeSelectedFund(event.target.value);
  };

  if (role !== "ADMIN") {
    return <div>You are not authorized to view this page</div>;
  }

  return (
    <EditInfoContainer modalActive={generalSettingsModalVisible || newFundModalVisible}>
      <Wrapper>
        <EditInfoNavigationContainer>
          <EditInfoFundsSelectionContainer>
            {fundsLoading ? (
              <Loading />
            ) : fundsError ? (
              <p>Error</p>
            ) : (
              <LeftSection>
                {fund_list.length > 1 ? (
                  <SelectContainer>
                    <AppSelect
                      options={fund_list}
                      value={selected_fund.id}
                      onChange={handleSelectFund}
                      width={415}
                      fontSize={36}
                      color="#fff"
                      ignorePlaceholderOpacity
                      noUnderline
                    />
                  </SelectContainer>
                ) : (
                  <FundText>{selected_fund.name}</FundText>
                )}
              </LeftSection>
            )}
            <RightSection>
              <SettingsContainer>
                <BlueCircle onClick={toggleGeneralSettingsModal}>
                  <BsGear size={20} color={"white"} />
                </BlueCircle>
                <BlueCircle onClick={toggleNewFundModal}>
                  <AiOutlinePlus size={20} color={"white"} />
                </BlueCircle>
              </SettingsContainer>
            </RightSection>
            <EditInfoTabContainer>
              <StyledTabs value={page} onChange={handleSelectPage} aria-label="simple tabs example">
                <StyledTab label="Overall" value="overall" />
                <StyledTab label="Portfolio" value="portfolio" />
                <StyledTab label="Industry" value="industry" />
                <StyledTab label="Trend" value="trend" />
                <StyledTab label="Data Room" value="dataroom" />
                <StyledTab label="Data Room HBCU" value="dataroomHBCU" />
                <StyledTab label="Users" value="users" />
              </StyledTabs>
            </EditInfoTabContainer>
          </EditInfoFundsSelectionContainer>
        </EditInfoNavigationContainer>
      </Wrapper>
      <div style={{ background: BACKGROUND_COLOR }}>
        <CMSBodyWrapper>{fundsLoading ? <Loading /> : renderEditPage()}</CMSBodyWrapper>
      </div>
    </EditInfoContainer>
  );
};

interface ContainerState {
  modalActive: boolean;
}

const EditInfoContainer = styled.div<ContainerState>`
  background: ${BLACK_COLOR};
  padding-top: 120px;
  overflow: ${(props) => (props.modalActive ? "hidden" : "visible")};
  height: ${(props) => (props.modalActive ? "80vh" : "100%")};
`;

const EditInfoNavigationContainer = styled.div`
  width: 100%;
  height: 195px;
  position: relative;
  display: flex;
`;

const EditInfoFundsSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BlueCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: ${PRIMARY_COLOR};
  :hover {
    cursor: pointer;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 100px;
  height: 50px;
  align-items: center;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 110px;
  justify-content: space-between;
`;

const FundText = styled.div`
  height: 50px;
  font-family: "LL Circular Web", sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  color: #ffffff;
`;

const EditInfoTabContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: yellow;
`;

const SelectContainer = styled.div`
  margin-left: 15px;
`;

const StyledTabs = styled(Tabs)`
  background-color: ${BLACK_COLOR};
  color: white;
  .MuiTab-root {
    text-transform: none;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    font-size: 15px;
    padding-right: 0;
    padding-left: 0;
    min-width: 0px;

    @media ${`(min-width: 600px)`} {
      min-width: 0px;
    }
  }
  /* .PrivateTabIndicator-root-1 {
    background-color: ${PRIMARY_COLOR};
    height: 4px;
  } */
  .MuiTabs-indicator {
    background-color: ${PRIMARY_COLOR};
    height: 4px;
  }
`;
const StyledTab = styled(Tab)`
  background-color: ${PRIMARY_COLOR};
`;

const EditInfo = observer(EditInfoComponent);

export { EditInfo };
