import * as React from "react";
import styled from "styled-components";
import { FaRegTrashAlt } from "react-icons/fa";
import { BLACK_COLOR } from "../../utils/theme";

interface ITrashIconProps {
  onClick: () => void;
}

const TrashIcon: React.FunctionComponent<ITrashIconProps> = ({ onClick }) => {
  return (
    <TrashContainer>
      <TrashCan>
        <FaRegTrashAlt size={20} color={BLACK_COLOR} style={{ opacity: 1 }} onClick={onClick} />
      </TrashCan>
    </TrashContainer>
  );
};

const TrashContainer = styled.div`
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TrashCan = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export { TrashIcon };
