/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { RootStoreType } from "./index"


/**
 * UserSessionBase
 * auto generated base class for the model UserSessionModel.
 */
export const UserSessionModelBase = ModelBase
  .named('UserSession')
  .props({
    __typename: types.optional(types.literal("UserSession"), "UserSession"),
    id: types.union(types.undefined, types.integer),
    time_spent_on_page: types.union(types.undefined, types.null, types.integer),
    user: types.union(types.undefined, types.null, types.late((): any => UserModel)),
    user_id: types.union(types.undefined, types.null, types.integer),
    updated_at: types.union(types.undefined, types.null, types.frozen()),
    created_date: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class UserSessionModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get time_spent_on_page() { return this.__attr(`time_spent_on_page`) }
  get user_id() { return this.__attr(`user_id`) }
  get updated_at() { return this.__attr(`updated_at`) }
  get created_date() { return this.__attr(`created_date`) }
  user(builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector)) { return this.__child(`user`, UserModelSelector, builder) }
}
export function selectFromUserSession() {
  return new UserSessionModelSelector()
}

export const userSessionModelPrimitives = selectFromUserSession().time_spent_on_page.user_id.updated_at.created_date
