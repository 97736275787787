import React, { useEffect, FunctionComponent, useState, useContext } from "react";
import styled from "styled-components";
import { Wrapper, AppSelect } from "../UI";
import { observer } from "mobx-react";
import { mobileSize } from "../../utils/breakpoints";
import { PRIMARY_FONT, OFF_WHITE_BACKGROUND, PRIMARY_COLOR } from "../../utils/theme";
import { ContactUs, Footer } from "../Segments";
import { prismicClient, Prismic, RichText } from "../../services";
import { Redirect, RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { StoreContext, useQuery } from "../../models/reactUtils";
import { UserContext } from "../../context";
import ReactGA from "react-ga";
import { trackUpdateLettersDropdown } from "../../services/mixpanel";

function useQueryParam() {
  return new URLSearchParams(useLocation().search);
}

type Props = RouteComponentProps<{
  nonauth: string;
}>;

const QuarterlyUpdateComponent: FunctionComponent<Props> = (props) => {
  const [blog, setBlog] = useState(undefined);
  const [fullBlogList, setFullBlogList] = useState([]);
  const [blogIndex, setBlogIndex] = useState(0);
  const [blogList, setBlogList] = useState([{ value: 0, label: "" }]);
  const store = useContext(StoreContext);
  const [dummy, setDummy] = useState(0);

  const {
    user,
    user: { role, hbcu_data_room_access, accessible_letters, id },
    is_authenticated,
    logout,
    fetchUser
  } = store;

  const { data: userData, setQuery: setQueryFetch,  } = useQuery((store) =>
    store.queryUser(
      { where: { id: id } },
      (qb) =>
        qb.id.accessible_letters
    ),
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    console.log("user_id", id);
    const query = store.queryUser(
      { where: { id: id } },
      (qb) =>
        qb.id.accessible_letters
      , 
      {
        fetchPolicy: "network-only"
      }
    );
    //query.refetch();
    setQueryFetch(query);
    query.refetch().then((temp) => {
      console.log("refetch complete");
      console.log("temp", temp);
      setDummy(dummy + 1);
    });
  }, []);

  const history = useHistory();
  const query = useQueryParam();
  const letterID = query.get("letter");

  useEffect(() => {
    const index = fullBlogList
      .slice()
      .map((item) => item.id)
      .indexOf(letterID);

    if (index >= 0) {
      console.log(fullBlogList);
      setBlogIndex(index);
      console.log("letter-", letterID);
    }
  }, [letterID, fullBlogList]);

  const getPrismicFundTags = () => {
    console.log("user: ", user);
    if (!user) {
      return ["fund_i", "fund_ii"];
    }
    if (!user.accessible_funds) {
      return ["fund_i", "fund_ii"];
    }
    if (!user.accessible_funds.length) {
      return ["fund_i", "fund_ii"];
    }
    if (user.accessible_funds.includes(12) && user.accessible_funds.includes(13)) {
      return ["fund_i", "fund_ii"];
    }
    if (user.accessible_funds.includes(12)) {
      return ["fund_i"];
    }
    if (user.accessible_funds.includes(13)) {
      return ["fund_ii"];
    }
    return ["fund_i", "fund_ii"];
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (!blog) {
      return;
    }
    ReactGA.event({ action: blog.data.title1, category: "Update Letter View" });
  }, [blog]);

  useEffect(() => {
    const fetchData = async () => {
      // const response = await prismicClient.query([
      //   Prismic.Predicates.at("document.type", "quarterly_letter"),
      //   Prismic.Predicates.any("document.tags", getPrismicFundTags()),
      // ]);
      const response = await prismicClient.query([Prismic.Predicates.at("document.type", "quarterly_letter")]);
      console.log("prismic response: ", response);
      if (response) {
        console.log("accessible_letters: ", userData?.user?.accessible_letters);
        const blogs = response.results
          .filter((r) => userData?.user?.accessible_letters?.includes(r.id))
          .map((r) => ({ ...r, first_publication_date: new Date(r.first_publication_date) }))
          .sort((a, b) => b.first_publication_date.getTime() - a.first_publication_date.getTime());
        const selectFields = blogs.reduce((array, currentValue, index) => {
          if (props.match.params.nonauth === "may") {
            if (currentValue.data.title1[0].text.includes("may")) {
              array.push({
                label: currentValue.data.title1[0].text as string,
                value: currentValue.id,
              });
            }
          } else {
            console.log("blog id: ", currentValue.id);
            if (userData?.user?.accessible_letters?.includes(currentValue.id)) {
              array.push({
                label: currentValue.data.title1[0].text as string,
                value: currentValue.id,
              });
            }
          }
          return array;
        }, []);
        setBlog(blogs[blogIndex]);
        setBlogList(selectFields);
        setFullBlogList(blogs);
      }
    };
    fetchData();
  }, [userData?.user?.accessible_letters, dummy]);

  useEffect(() => {
    setBlog(fullBlogList[blogIndex]);
  }, [blogIndex]);

  const { quarterly_authenticated } = useContext(UserContext);

  const handleSelectQuarter = (event: any) => {
    event.preventDefault();
    const letterLabel = blogList.find((obj) => obj.value === event.target.value).label;
    trackUpdateLettersDropdown(letterLabel);
    // setBlogIndex(event.target.value);
    history.push(`/quarterly-update/?letter=${event.target.value}`);
  };

  if (!is_authenticated) {
    console.log("quarterly_authenticated", quarterly_authenticated);

    if (props.match.params.nonauth !== "success" || !quarterly_authenticated) {
      return <Redirect to="/login/quarterly-letter" />;
    }
  }

  return (
    <QuarterlyUpdatecontainer>
      <Wrapper>
        <SelectFieldContainer>
          <AppSelect
            value={letterID}
            options={blogList}
            onChange={handleSelectQuarter}
            placeholder={"Switch letter"}
            width={375}
            fontSize={18}
            color={PRIMARY_COLOR}
            ignorePlaceholderOpacity
          />
        </SelectFieldContainer>
        {blog && (
          <PrismicContainer>
            <RichText render={blog.data.title1} />
            <RichText render={blog.data.body} />
          </PrismicContainer>
        )}
      </Wrapper>
      <ContactUs />
      <Footer />
    </QuarterlyUpdatecontainer>
  );
};

const PrismicContainer = styled.div`
  font-family: ${PRIMARY_FONT};
  line-height: 1.6;
  font-size: 20px;
  img {
    max-width: 100%;
  }
`;

const DataContentSegment = styled.div`
  width: calc(83.33333% - 55px);
  float: left;
  margin-left: 30px;
  margin-left: calc(8.33333% - 33px + 60px);
  padding-top: 120px;
  padding-bottom: 30px;
  background: ${OFF_WHITE_BACKGROUND};
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const QuarterlyUpdatecontainer = styled.div`
  padding-top: 120px;
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  background-color: ${OFF_WHITE_BACKGROUND};
  ${DataContentSegment}+${DataContentSegment}{
    padding-top: 0;
  }
  ${DataContentSegment}:last-child{
    padding-bottom: 120px;
  }
`;

const SelectFieldContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
`;

const QuarterlyUpdate = observer(QuarterlyUpdateComponent);

export { QuarterlyUpdate };
