import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import PublicRoute from "./PublicRoute";
// import PrivateRoute from "./PrivateRoute";
import {
  Login,
  EditInfo,
  InvestorDashboard,
  ResetPassword,
  DataRoom,
  TermsComponent,
  Signup,
  NewPassword,
  HBCUDataRoom,
  Logout,
  RequestAccess,
} from "../Components/Pages";
import { QuarterlyUpdate } from "../Components/Pages/QuarterlyUpdate";
import PrivateRoute from "./PrivateRoute";
import NoAuthRoute from "./NoAuthRoute";
import { QuarterlyUpdateMay } from "../Components/Pages/QuarterlyUpdateMay";

const AppRouter = () => {
  return (
    <Router>
      <>
        <Switch>
          <PublicRoute path="/" exact component={Login} />
          <PublicRoute exact path="/login/" component={Login} />
          <Route path="/login/:type" component={Login} />
          <PublicRoute path="/reset-password" component={ResetPassword} />
          <PublicRoute path="/request-access" component={RequestAccess} />
          <PublicRoute path="/new-password/:username" component={NewPassword} />
          <PublicRoute path="/signup" component={Signup} />
          <PrivateRoute path="/editinfo" component={EditInfo} />
          <PrivateRoute path="/data-room/:id" component={DataRoom} />
          <PrivateRoute path="/data-room" component={DataRoom} />
          <PrivateRoute path="/advancement-initiative-data-room" component={HBCUDataRoom} />
          <PrivateRoute path="/logout" component={Logout} />
          <PublicRoute path="/terms-of-use" component={TermsComponent} />
          <NoAuthRoute exact path="/quarterly-update/" component={QuarterlyUpdate} />
          <NoAuthRoute path="/quarterly-update/:nonauth" component={QuarterlyUpdate} />
          <NoAuthRoute path="/quarterly-update-may/:nonauth" component={QuarterlyUpdateMay} />
          <PrivateRoute path="/investor-dashboard" component={InvestorDashboard} />
        </Switch>
      </>
    </Router>
  );
};

export default AppRouter;
