/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * FundraisingAndValuationHistoryGraphRoundBase
 * auto generated base class for the model FundraisingAndValuationHistoryGraphRoundModel.
 */
export const FundraisingAndValuationHistoryGraphRoundModelBase = ModelBase
  .named('FundraisingAndValuationHistoryGraphRound')
  .props({
    __typename: types.optional(types.literal("FundraisingAndValuationHistoryGraphRound"), "FundraisingAndValuationHistoryGraphRound"),
    month_year: types.union(types.undefined, types.null, types.string),
    stage: types.union(types.undefined, types.null, types.string),
    valuation: types.union(types.undefined, types.null, types.number),
    previous_valuation: types.union(types.undefined, types.null, types.number),
    total_investment: types.union(types.undefined, types.null, types.number),
    footnote: types.union(types.undefined, types.null, types.string),
    base10_involved: types.union(types.undefined, types.null, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FundraisingAndValuationHistoryGraphRoundModelSelector extends QueryBuilder {
  get month_year() { return this.__attr(`month_year`) }
  get stage() { return this.__attr(`stage`) }
  get valuation() { return this.__attr(`valuation`) }
  get previous_valuation() { return this.__attr(`previous_valuation`) }
  get total_investment() { return this.__attr(`total_investment`) }
  get footnote() { return this.__attr(`footnote`) }
  get base10_involved() { return this.__attr(`base10_involved`) }
}
export function selectFromFundraisingAndValuationHistoryGraphRound() {
  return new FundraisingAndValuationHistoryGraphRoundModelSelector()
}

export const fundraisingAndValuationHistoryGraphRoundModelPrimitives = selectFromFundraisingAndValuationHistoryGraphRound().month_year.stage.valuation.previous_valuation.total_investment.footnote.base10_involved
