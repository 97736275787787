import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { persistStore, persistCombineReducers, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AppActions } from "../types";
import { authReducer } from "../reducers";

// Redux Store
// -----------
// This file is dense main because of the use of Redux-persist
// Redux-persist saves the redux-store to disk, which allows
// the data to persist between browser reloads.
// Firebase is used to persist data between login/logout sessions.

// ! Don't update this persistence key. Instead update the MST key
const persistConfig = {
  key: "base10-2",
  storage,
  // Redux-persist converts Moment objects to strings when saving to disk
  // to convert the items back to Moment items upon launch, we use a transform
  // to convert them back to Moment objects.
  transforms: [
    createTransform(
      // The first parameter converts objects when writing to disk
      (state) => state,
      // The second parameter converts objects when loading into memory
      (state) => state,
      // (outboundState: any) => {
      //   // convert startDate and endDate back into Moment objects
      //   return {
      //     ...outboundState
      //     // startDate: moment(outboundState.startdate),
      //     // endDate: moment(outboundState.endDate)
      //   };
      // }
      // The third parameter denotes which reducers to apply the change to
      // { whitelist: ["filters"] }
    ),
    // Clear error state when persisting to disk
    createTransform(
      (state: Record<string, unknown>) => ({ ...state, error: "" }),
      (state) => state,
      {
        whitelist: ["auth"],
      },
    ),
  ],
};

// Used for Typescript
// -------------------
export const rootReducer = combineReducers({
  auth: authReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
// -------------------
// Combine reduces
const reducers = persistCombineReducers(persistConfig, {
  auth: authReducer,
});

// const composeEnhancers =
//   ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//     (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
//   compose;

// create redux store
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(reducers, applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>));
// Create redux-persist instance
const persistor = persistStore(store);

export { store, persistor };
