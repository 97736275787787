import * as React from "react";
import styled from "styled-components";
import { PRIMARY_FONT, PRIMARY_COLOR } from "../../../utils/theme";
import { AppPanelTitle, AppText, Loading } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper, PanelFooter, PanelContainer } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";
import { TiArrowSortedUp } from "react-icons/ti";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { formatDate, roundNumber } from "../../../utils/format";
import { returnIfNormalFund } from "../../Dumb/utils";

interface StatsProps {
  fund_id: number;
}

const StatsPanel: FunctionComponent<StatsProps> = (props) => {
  const { store } = useQuery();

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund(
      { where: { id: props.fund_id } },
      (qb) =>
        qb.moic.moic_excl_6_months.gross_moic_on_initial_checks.capital_deployed.capital_deployed_change.fmv.fmv_change
          .portfolio_companies.new_portfolio_companies.months_since_first_close.last_update.show_capital_deployed
          .show_capital_deployed_change.show_fmv.show_fmv_change.show_moic.show_moic_excl_6_months
          .show_gross_moic_on_initial_checks.show_months_since_first_close.show_new_portfolio_companies
          .show_portfolio_companies.capital_deployed_perc_deployed_and_reserved.fund_overview_footnote.tvpi.show_tvpi,
    ),
  );

  useEffect(() => {
    const query = store.queryFund(
      { where: { id: props.fund_id } },
      (qb) =>
        qb.moic.moic_excl_6_months.gross_moic_on_initial_checks.capital_deployed.capital_deployed_change.fmv.fmv_change
          .portfolio_companies.new_portfolio_companies.months_since_first_close.last_update.show_capital_deployed
          .show_capital_deployed_change.show_fmv.show_fmv_change.show_moic.show_moic_excl_6_months
          .show_gross_moic_on_initial_checks.show_months_since_first_close.show_new_portfolio_companies
          .show_portfolio_companies.capital_deployed_perc_deployed_and_reserved.fund_overview_footnote.tvpi.show_tvpi,
    );
    fundQuery(query);
  }, [props.fund_id]);

  if (fundLoading || !fundData) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (fundError || !fundData || !fundData.fund) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  const {
    fund: {
      moic,
      moic_excl_6_months,
      gross_moic_on_initial_checks,
      capital_deployed,
      capital_deployed_change,
      fmv,
      fmv_change,
      portfolio_companies,
      new_portfolio_companies,
      months_since_first_close,
      last_update,
      show_capital_deployed,
      show_capital_deployed_change,
      show_fmv,
      show_fmv_change,
      show_moic,
      show_moic_excl_6_months,
      show_gross_moic_on_initial_checks,
      show_months_since_first_close,
      show_portfolio_companies,
      show_new_portfolio_companies,
      capital_deployed_perc_deployed_and_reserved,
      fund_overview_footnote,
      tvpi,
      show_tvpi,
    },
  } = fundData;

  return returnIfNormalFund(props.fund_id) ? (
    <HalfPanelContainer>
      <PanelWrapper>
        <AppPanelTitle>STATS</AppPanelTitle>
        <PanelContent>
          {/* <PanelContentTitle>Overall and change from prior quarter*</PanelContentTitle> */}
          <StatListContainer>
            <StatListWrapper>
              <StatList>
                {show_moic && (
                  <StatListItem>
                    <StatListValue>{moic?.toFixed(1)}x</StatListValue>
                    <StatListLabel>Gross MOIC</StatListLabel>
                  </StatListItem>
                )}
                {show_gross_moic_on_initial_checks && (
                  <StatListItem>
                    <StatListValue>{gross_moic_on_initial_checks}x</StatListValue>
                    <StatListLabel>Gross MOIC on initial checks</StatListLabel>
                  </StatListItem>
                )}
                {show_moic_excl_6_months && (
                  <StatListItem>
                    <StatListValue>{moic_excl_6_months}x</StatListValue>
                    <StatListLabel>MOIC excl. investments made in last 6 months</StatListLabel>
                  </StatListItem>
                )}
                {show_capital_deployed && (
                  <StatListItem>
                    <StatListValue>${capital_deployed}M</StatListValue>
                    <StatListLabel>of capital deployed</StatListLabel>
                    {show_capital_deployed_change && (
                      <StatListSpanContainer>
                        <TiArrowSortedUp size={24} color={PRIMARY_COLOR} />
                        <StatListSpan>+${capital_deployed_change}M</StatListSpan>
                      </StatListSpanContainer>
                    )}
                  </StatListItem>
                )}
                {show_fmv && (
                  <StatListItem>
                    <StatListValue>${fmv}M</StatListValue>
                    <StatListLabel>FMV</StatListLabel>
                    {show_fmv_change && (
                      <StatListSpanContainer>
                        <TiArrowSortedUp size={24} color={PRIMARY_COLOR} />
                        <StatListSpan>+${fmv_change}M</StatListSpan>
                      </StatListSpanContainer>
                    )}
                  </StatListItem>
                )}
                {show_portfolio_companies && (
                  <StatListItem>
                    <StatListValue>{portfolio_companies}</StatListValue>
                    <StatListLabel>Portfolio Companies</StatListLabel>
                    {show_new_portfolio_companies && (
                      <StatListSpanContainer>
                        <TiArrowSortedUp size={24} color={PRIMARY_COLOR} />
                        <StatListSpan>+{new_portfolio_companies}</StatListSpan>
                      </StatListSpanContainer>
                    )}
                  </StatListItem>
                )}
                {show_months_since_first_close && (
                  <StatListItem>
                    <StatListValue>{months_since_first_close} months</StatListValue>
                    <StatListLabel>weighted average age of investment</StatListLabel>
                  </StatListItem>
                )}
                {show_tvpi && (
                  <StatListItem>
                    <StatListValue>{tvpi.toFixed(1)}x</StatListValue>
                    <StatListLabel>TVPI</StatListLabel>
                  </StatListItem>
                )}
              </StatList>
            </StatListWrapper>
          </StatListContainer>
          <PanelFooter>{fund_overview_footnote ?? `*Stats as of ${formatDate(last_update)}`}</PanelFooter>
        </PanelContent>
      </PanelWrapper>
    </HalfPanelContainer>
  ) : (
    <HalfPanelContainer>
      <PanelWrapper>
        <AppPanelTitle>Fund Overview</AppPanelTitle>
        <PanelContent>
          {/* <PanelContentTitle>Fund Summary and Portfolio Metrics Overview</PanelContentTitle> */}
          <StatListContainer>
            <StatListWrapper>
              <StatList>
                {/* <StatListItem>
                  <StatListValue>{capital_deployed_perc_deployed_and_reserved}%</StatListValue>
                  <StatListLabel>Deployed and reserved</StatListLabel>
                </StatListItem> */}
                <StatListItem>
                  <StatListValue>{portfolio_companies}</StatListValue>
                  <StatListLabel>Portfolio Companies</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>${capital_deployed}M</StatListValue>
                  <StatListLabel>Capital Deployed</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>{months_since_first_close} months</StatListValue>
                  <StatListLabel>Weighted average age of investment</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>{roundNumber(moic || 0).toFixed(1)}x</StatListValue>
                  <StatListLabel>MOIC</StatListLabel>
                </StatListItem>

                {/* <StatListItem>
                  <StatListValue>136%</StatListValue>
                  <StatListLabel>2020-2022E Revenue CAGR</StatListLabel>
                </StatListItem> */}

                {/* <StatListItem>
                  <StatListValue>130%</StatListValue>
                  <StatListLabel>Average 2022E Revenue CAGR</StatListLabel>
                </StatListItem>
                
                <StatListItem>
                  <StatListValue>${capital_deployed}M</StatListValue>
                  <StatListLabel>Capital Deployed</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>${fmv}M</StatListValue>
                  <StatListLabel>FMV</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>{moic}x</StatListValue>
                  <StatListLabel>Gross MOIC</StatListLabel>
                </StatListItem> */}

                {/* <StatListItem>
                  <StatListValue>70%</StatListValue>
                  <StatListLabel>Average Gross Margin</StatListLabel>
                </StatListItem>

                <StatListItem>
                  <StatListValue>40%</StatListValue>
                  <StatListLabel>Companies that are FCF positive</StatListLabel>
                </StatListItem> */}
              </StatList>
            </StatListWrapper>
          </StatListContainer>
          {fund_overview_footnote && <PanelFooter>{fund_overview_footnote}</PanelFooter>}
        </PanelContent>
      </PanelWrapper>
    </HalfPanelContainer>
  );
};

const StatListContainer = styled.div`
  display: block;
`;

const StatListWrapper = styled.div`
  overflow-x: hidden;
  margin-right: -20px;
  margin-left: -20px;
  :hover {
    overflow-x: auto;
  }
  /* @media ${mobileSize} {
    margin-right: 0px;
    margin-left: 0px;
  } */
`;

const StatList = styled.ul`
  overflow: hidden;
  display: table;
  border-spacing: 20px;
  width: 100%;
  max-width: 100%;
  list-style: none;
  margin-top: -20px;
  margin-bottom: -20px;
  padding-left: 0px;
`;

const StatListItem = styled.li`
  display: table-cell;
  /* width: 16.6666666667%; */
  min-width: 80px;
  max-width: 100px;
  vertical-align: top;
  border-bottom: 2px solid ${PRIMARY_COLOR};
  padding-bottom: 30px;
`;

const StatListValue = styled.h3`
  font-family: ${PRIMARY_FONT};
  color: ${PRIMARY_COLOR};
  font-weight: 700;
  white-space: nowrap;
  margin: 0;
  font-size: 24px;
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 1.41667;
    letter-spacing: 0;
  }
`;

const StatListLabel = styled.h4`
  font-family: ${PRIMARY_FONT};
  min-height: 52px;
  margin-top: 1px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: normal;
  margin: 0;
  @media ${mobileSize} {
    min-height: 52px;
  }
`;

const StatListSpanContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StatListSpan = styled.span`
  font-family: ${PRIMARY_FONT};
  font-size: 13px;
  line-height: 1.25;
  font-weight: normal;
  margin: 0;
`;

export const PanelContentTitle = styled.h3`
  font-family: ${PRIMARY_FONT};
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 18px;
`;

const StatsComponent = observer(StatsPanel);
export { StatsComponent };
