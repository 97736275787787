import styled from "styled-components";
import { BLACK_COLOR, GREY_COLOR, PRIMARY_FONT } from "../../utils/theme";

interface AppButtonProps {
  /**
   * Width of Input button. If not specified, button defaults to 100% of parent.
   */
  width?: number;
  /**
   * height of button. Default value is 50.
   */
  height?: number;
  secondary?: boolean;
}

export const AppButton = styled.button<AppButtonProps>`
  width: ${(props) => (!!props.width ? `${props.width}px` : `100%`)};
  height: ${(props) => props.height}px;
  background-color: ${(props) => (props.disabled ? GREY_COLOR : props.secondary ? "#fff" : BLACK_COLOR)};
  border-radius: 4px;
  color: ${(props) => (props.secondary ? BLACK_COLOR : "rgba(255, 255, 255, 0.6)")};
  font-family: ${PRIMARY_FONT};
  font-size: 18px;
  border: solid ${(props) => (props.secondary ? 1 : 0)}px rgba(26, 26, 26, 0.2);
  :hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

AppButton.defaultProps = {
  height: 50,
  secondary: false,
};
