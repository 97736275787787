import * as React from "react";
import styled from "styled-components";
import { useQuery } from "../../../models/reactUtils";
import { UrlType, RoleEnum, SortOrder } from "../../../models";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Loading, AnimatedText, AppFormTitle, AppButton, AppText, AppInput } from "../../UI";
import { Formik, FormikProps, FieldArray } from "formik";
import { FormInputField, FormSelectField, StaticTextField, CheckboxField } from "../../Forms";
import { TrashIcon } from "../../Dumb";
import { observer } from "mobx-react";
import { RED_COLOR, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR, OFF_WHITE_BACKGROUND } from "../../../utils/theme";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { roles } from "../../../data/roles";
import { FiEdit2, FiTrash2, FiRefreshCcw } from "react-icons/fi";
import { formatError } from "../../Dumb/utils";
import { Prismic, prismicClient } from "../../../services";

const userSchema = Yup.object().shape({
  username: Yup.string().required("* Username is required"),
  email: Yup.string().email("* Email is required").required(),
  role: Yup.string().required("* Role is required"),
  accessibleFunds: Yup.array().of(Yup.number().integer()),
  accessibleLetters: Yup.array().of(Yup.number().integer()),
  hbcu_data_room_access: Yup.boolean(),
});

interface IUsersProps {}

interface User {
  id: number;
  email: string;
  username: string;
  role: string;
  accessible_funds: number[];
  accessible_letters: string[];
  hbcu_data_room_access: boolean;
  password: string;
  edit: boolean;
  password_edit: boolean;
}

interface MyFormikProps {
  email: string;
  username: string;
  role: string;
  accessible_funds: number[];
  accessible_letters: string[];
  hbcu_data_room_access: boolean;
}

const UsersComponent: React.FunctionComponent<IUsersProps> = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [newPassword, setNewPassword] = useState("");
  const [letters, setLetters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [refetch, setRefetch] = useState(0);
  const refetchQuery = () => setRefetch(Math.round(Math.random() * 10000));
  const { loading: updateLoading, error: updateError, store, setQuery } = useQuery();
  const { data, loading, error, setQuery: usersSetQuery } = useQuery(
    (store) =>
      store.queryUsers(
        {
          first: 50,
          where: { email: { contains: searchText || undefined } },
          orderBy: [{ created_date: SortOrder.desc }],
        },
        (qb) => qb.id.email.username.role.accessible_funds.accessible_letters.hbcu_data_room_access,
      ),
    { fetchPolicy: "network-only" },
  );

  const { data: fundsData, loading: fundsLoading, error: fundsError } = useQuery(
    (store) => store.queryFunds({}, (qb) => qb.id.name),
    { fetchPolicy: "network-only" },
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query([Prismic.Predicates.at("document.type", "quarterly_letter")]);
      console.log("prismic response: ", response);
      if (response) {
        const resLetters = response.results
          .map((r) => ({ ...r, first_publication_date: new Date(r.first_publication_date) }))
          .sort((a, b) => b.first_publication_date.getTime() - a.first_publication_date.getTime());
        const selectFields = resLetters.map((letter) => ({
          label: letter.data.title1[0].text as string,
          id: letter.id,
        }));
        setLetters(selectFields);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const query = store.queryUsers(
      {
        first: 50,
        where: { email: { contains: searchText || undefined } },
        orderBy: [{ created_date: SortOrder.desc }],
      },
      (qb) => qb.id.email.username.role.accessible_funds.accessible_letters.hbcu_data_room_access,
      {
        fetchPolicy: "network-only",
      },
    );
    query.then((v) => {
      setUsers(
        v.users.map(
          ({ id, email, username, accessible_funds, accessible_letters, role, password, hbcu_data_room_access }) => ({
            id,
            email,
            username,
            accessible_funds,
            accessible_letters,
            role,
            password,
            edit: false,
            password_edit: false,
            hbcu_data_room_access,
          }),
        ),
      );
    });
    usersSetQuery(query);
  }, [refetch]);

  useEffect(() => {
    const query = store.queryUsers(
      {
        first: 50,
        where: { email: { contains: searchText || undefined } },
        orderBy: [{ created_date: SortOrder.desc }],
      },
      (qb) => qb.id.email.username.role.accessible_funds.accessible_letters.hbcu_data_room_access,
      {
        fetchPolicy: "network-only",
      },
    );
    query.then((v) => {
      setUsers(
        v.users.map(
          ({ id, email, username, accessible_funds, accessible_letters, role, password, hbcu_data_room_access }) => ({
            id,
            email,
            username,
            accessible_funds,
            accessible_letters,
            role,
            password,
            edit: false,
            password_edit: false,
            hbcu_data_room_access,
          }),
        ),
      );
    });
    usersSetQuery(query);
  }, [searchText]);

  // if (loading || fundsLoading) {
  //   return <Loading />;
  // }
  if (error || fundsError) {
    return <p>Error fetching Users</p>;
  }

  // const searchContains = (u: User) => {
  //   if (u.email && u.email.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true;
  //   }
  //   if (u.username && u.username.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true;
  //   }
  //   if (u.role && u.role.toLowerCase().includes(searchText.toLowerCase())) {
  //     return true;
  //   }
  //   return false;
  // };

  const toggleEdit = (id: number) => {
    if (!id) {
      setUsers(users.filter((user) => !!user?.id));
    } else {
      setUsers(
        users.map((user) => {
          if (user.id === id) {
            return { ...user, edit: !user.edit };
          }
          return user;
        }),
      );
    }
  };

  const togglePasswordEdit = (id: number) => {
    setUsers(
      users.map((user) => {
        if (user.id === id) {
          return { ...user, password_edit: !user.password_edit };
        }
        return user;
      }),
    );
  };

  /**
   * Changes user's password
   * @param email user's email
   */
  const changePassword = async (email: string) => {
    if (!newPassword) {
      toast("Password must be more then zero characters!");
      return;
    }
    const password = newPassword;
    const mutation = store.mutateChangePassword({
      email,
      password,
    });
    mutation.then(
      (v) => {
        toast(`Password changed for ${v.changePassword.email} changed to ${password}`, {
          autoClose: false,
          closeOnClick: false,
          hideProgressBar: true,
          style: { cursor: "pointer" },
        });
        setNewPassword("");
        setUsers(
          users.map((u) => {
            if (u.id === v.changePassword.id) {
              return {
                ...u,
                password_edit: false,
              };
            }
            return u;
          }),
        );
      },
      (e) => {
        toast(formatError(e?.message) || "Error changing password for user");
      },
    );
    setQuery(mutation);
  };

  const role_options = roles?.map((r) => ({ label: r, value: r }));
  const funds = fundsData?.funds?.map((f) => ({ name: f.name, id: f.id }));

  const addUser = () => {
    setUsers([
      {
        accessible_funds: [],
        accessible_letters: [],
        email: "",
        edit: true,
        password_edit: false,
        role: "0",
        username: "",
        password: "",
        id: 0,
        hbcu_data_room_access: false,
      },
      ...users,
    ]);
  };

  const deleteUser = (id: number) => {
    // delete user mutation
    const mutation = store.mutateDeleteOneUser({
      where: {
        id,
      },
    });
    mutation.then(
      (v) => {
        toast("User deleted!");
        setUsers(users.filter((u) => u.id !== id));
        refetchQuery();
      },
      (e) => {
        toast(formatError(e?.message) || "Error deleting user");
      },
    );
    setQuery(mutation);
  };

  const sendResetPasswordEmail = (email: string) => {
    // delete user mutation
    const mutation = store.mutateForgotPassword({
      email,
    });
    mutation.then(
      (_) => {
        toast("Reset Email Password");
      },
      (e) => {
        toast(formatError(e?.message) || "Error deleting user");
      },
    );
    setQuery(mutation);
  };

  const sendWelcomeEmail = (email: string) => {
    // delete user mutation
    const mutation = store.mutateWelcomeToBase10({
      email,
    });
    mutation.then(
      (_) => {
        console.log("Welcome email send.");
      },
      (e) => {
        console.log("Error sending welcome email.");
      },
    );
    setQuery(mutation);
  };

  return (
    <Div>
      <Horizontal>
        <SearchInput
          value={searchText}
          width={300}
          fontSize={20}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
          placeholder="Search email, username, or role"
        />
        <AddUserButton width={150} onClick={addUser}>
          + Add User
        </AddUserButton>
      </Horizontal>
      <Table>
        <Header>
          <HeaderText>General Info</HeaderText>
          <HeaderText>Password</HeaderText>
          <HeaderText>Fund</HeaderText>
          <HeaderText>Letters</HeaderText>
          <HeaderText>Edit</HeaderText>
        </Header>
        {loading || fundsLoading ? (
          <Loading />
        ) : (
          users.map((user) => (
            <div key={user.id}>
              {user.edit ? (
                <Formik
                  initialValues={{
                    email: user.email,
                    username: user.username,
                    role: user.role,
                    accessible_funds: user.accessible_funds,
                    accessible_letters: user.accessible_letters,
                    hbcu_data_room_access: !!user.hbcu_data_room_access,
                  }}
                  validationSchema={userSchema}
                  onSubmit={(values) => {
                    if (!user.id) {
                      const mutation = store.mutateRegister({
                        user: {
                          email: !!values.email ? values.email.toLowerCase() : values.email,
                          accessible_funds: values.accessible_funds,
                          accessible_letters: values.accessible_letters,
                          role: values.role as any,
                          username: values.username,
                          hbcu_data_room_access: values.hbcu_data_room_access,
                        },
                      });
                      mutation.then(
                        (v) => {
                          toast(`${v.register.email} created! Password: ${v.register.initial_password}`, {
                            autoClose: false,
                            closeOnClick: false,
                            hideProgressBar: true,
                            style: { cursor: "pointer" },
                          });
                          setUsers(
                            users.map((u) => {
                              if (u.id === user.id) {
                                return {
                                  ...u,
                                  accessible_funds: v.register.accessible_funds,
                                  id: v.register.id,
                                  username: v.register.username,
                                  email: !!v.register.email ? v.register.email.toLowerCase() : v.register.email,
                                  role: v.register.role,
                                  password: v.register.password,
                                  hbcu_data_room_access: v.register.hbcu_data_room_access,
                                  edit: false,
                                };
                              }
                              return u;
                            }),
                          );
                          refetchQuery();
                        },
                        (e) => {
                          console.log("e: ", e.message);
                          toast(formatError(e?.message) || "Error Creating User");
                        },
                      );
                      setQuery(mutation);
                    } else {
                      const mutation = store.mutateUpdateOneUser({
                        where: { id: user.id },
                        data: {
                          email: { set: !!values.email ? values.email.toLowerCase() : values.email },
                          accessible_funds: { set: values.accessible_funds },
                          accessible_letters: { set: values.accessible_letters },
                          hbcu_data_room_access: { set: values.hbcu_data_room_access },
                          role: { set: values.role as any },
                          username: { set: values.username },
                        },
                      });
                      mutation.then(
                        (v) => {
                          toast("User updated!");
                          setUsers(
                            users.map((u) => {
                              if (u.id === user.id) {
                                return {
                                  ...u,
                                  accessible_funds: v.updateOneUser.accessible_funds,
                                  accessible_letters: v.updateOneUser.accessible_letters,
                                  id: v.updateOneUser.id,
                                  username: v.updateOneUser.username,
                                  email: !!v.updateOneUser.email
                                    ? v.updateOneUser.email.toLowerCase()
                                    : v.updateOneUser.email,
                                  role: v.updateOneUser.role,
                                  password: v.updateOneUser.password,
                                  hbcu_data_room_access: v.updateOneUser.hbcu_data_room_access,
                                  edit: false,
                                };
                              }
                              return u;
                            }),
                          );
                        },
                        (e) => {
                          toast(formatError(e?.message) || "Error updating user");
                        },
                      );
                      setQuery(mutation);
                    }
                  }}
                >
                  {({ values, setFieldValue, submitForm, errors }: FormikProps<MyFormikProps>) => {
                    console.log("errors: ", errors);
                    return (
                      <Row>
                        <Vertical>
                          <div>
                            <RowText>
                              <b>Email</b>
                            </RowText>
                            <FormInputField noTopMargin style={{ marginRight: 5 }} name="email" fontSize={13} />
                          </div>
                          <div>
                            <RowText>
                              <b>Username</b>
                            </RowText>
                            <FormInputField noTopMargin style={{ marginRight: 5 }} name="username" fontSize={13} />
                          </div>
                          <div>
                            <RowText>
                              <b>Role</b>
                            </RowText>
                            <FormSelectField name="role" options={role_options} fontSize={13} />
                          </div>
                        </Vertical>
                        <RowText>*****</RowText>
                        <Vertical>
                          <>
                            {funds.map((fund) => {
                              const checked = values.accessible_funds.includes(fund.id);
                              return (
                                <CheckRow key={fund.id}>
                                  <RowText>{`${fund.name}: `}</RowText>
                                  <input
                                    name="accessible_funds"
                                    type="checkbox"
                                    checked={checked}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `accessible_funds`,
                                        checked
                                          ? values.accessible_funds.filter((f) => f !== fund.id)
                                          : [...values.accessible_funds, fund.id],
                                      )
                                    }
                                  />
                                </CheckRow>
                              );
                            })}
                            <CheckRow key="hbcu">
                              <RowText>{`Advancement Initiative DR: `}</RowText>
                              <input
                                name="hbcu_data_room_access"
                                type="checkbox"
                                checked={values.hbcu_data_room_access}
                                onChange={(e) => setFieldValue(`hbcu_data_room_access`, !values.hbcu_data_room_access)}
                              />
                            </CheckRow>
                          </>
                        </Vertical>
                        <Vertical>
                          <>
                            {letters.map((letter) => {
                              const checked = values.accessible_letters.includes(letter.id);
                              return (
                                <CheckRow key={letter.id}>
                                  <RowText>{`${letter.label}: `}</RowText>
                                  <input
                                    name="accessible_letters"
                                    type="checkbox"
                                    checked={checked}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `accessible_letters`,
                                        checked
                                          ? values.accessible_letters.filter((f) => f !== letter.id)
                                          : [...values.accessible_letters, letter.id],
                                      )
                                    }
                                  />
                                </CheckRow>
                              );
                            })}
                          </>
                        </Vertical>
                        {updateLoading ? (
                          <Loading />
                        ) : (
                          <Vertical>
                            <SaveButton onClick={submitForm}>Save</SaveButton>
                            <CancelButton onClick={() => toggleEdit(user.id)} secondary>
                              Cancel
                            </CancelButton>
                          </Vertical>
                        )}
                      </Row>
                    );
                  }}
                </Formik>
              ) : (
                <Row>
                  <Vertical>
                    <RowText>
                      <b>Email:</b> {user.email}
                    </RowText>
                    <RowText>
                      <b>Username:</b> {user.username}
                    </RowText>
                    <RowText>
                      <b>Role:</b> {user.role}
                    </RowText>
                  </Vertical>
                  {user.password_edit ? (
                    <Vertical>
                      <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                      <button style={{ backgroundColor: PRIMARY_COLOR }} onClick={() => changePassword(user.email)}>
                        Save
                      </button>
                      <button onClick={() => togglePasswordEdit(user.id)}>Cancel</button>
                    </Vertical>
                  ) : (
                    <VerticalCenter onClick={() => togglePasswordEdit(user.id)}>
                      <FiEdit2 />
                      <SmallText>Change PW</SmallText>
                    </VerticalCenter>
                  )}
                  <Vertical>
                    <>
                      {funds.map((fund) => (
                        <CheckRow key={fund.id}>
                          <RowText>{`${fund.name}: `}</RowText>
                          <input
                            name="accessible_funds"
                            disabled
                            type="checkbox"
                            checked={user.accessible_funds.includes(fund.id)}
                          />
                        </CheckRow>
                      ))}
                      <CheckRow key="hbcu">
                        <RowText>{`Advancement Initiative DR: `}</RowText>
                        <input name="accessible_funds" disabled type="checkbox" checked={user.hbcu_data_room_access} />
                      </CheckRow>
                    </>
                  </Vertical>
                  <Vertical>
                    <>
                      {letters.map((letter) => (
                        <CheckRow key={letter.id}>
                          <RowText>{`${letter.label}: `}</RowText>
                          <input
                            name="accessible_letters"
                            disabled
                            type="checkbox"
                            checked={user.accessible_letters.includes(letter.id)}
                          />
                        </CheckRow>
                      ))}
                    </>
                  </Vertical>
                  <Center>
                    <VerticalCenter onClick={() => (user.password_edit ? {} : toggleEdit(user.id))}>
                      <FiEdit2 opacity={user.password_edit ? 0.4 : 1} />
                      <SmallText>Edit</SmallText>
                    </VerticalCenter>
                    <VerticalCenter onClick={() => (user.password_edit ? {} : sendResetPasswordEmail(user.email))}>
                      <FiRefreshCcw opacity={user.password_edit ? 0.4 : 1} />
                      <SmallText>Reset PW</SmallText>
                    </VerticalCenter>
                    <VerticalCenter onClick={() => (user.password_edit ? {} : deleteUser(user.id))}>
                      <FiTrash2 opacity={user.password_edit ? 0.4 : 1} color={RED_COLOR} />
                      <SmallText style={{ color: RED_COLOR }}>Delete</SmallText>
                    </VerticalCenter>
                  </Center>
                </Row>
              )}
            </div>
          ))
        )}
      </Table>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  margin-bottom: 50px;
  border: 1px solid #000;
  border-radius: 4px;
`;

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerticalCenter = styled(Vertical)`
  align-items: center;
  padding: 4px;
  :hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 250px 80px 200px 200px auto;
  grid-gap: 12px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const CheckRow = styled.div`
  display: flex;
`;

const Header = styled(Row)`
  border-bottom: 2px solid #000;
  background-color: #000;
  color: #fff;
`;

const SaveButton = styled(AppButton)`
  height: 25px;
  font-size: 13px;
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  margin-bottom: 5px;
`;

const CancelButton = styled(AppButton)`
  height: 25px;
  font-size: 13px;
`;

const SearchInput = styled(AppInput)`
  margin-bottom: 15px;
  margin-left: 5px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
`;

const RowText = styled(AppText)`
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AddUserButton = styled(AppButton)`
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
`;

const SmallText = styled(AppText)`
  font-size: 8px;
`;

const Users = observer(UsersComponent);

export { Users };
