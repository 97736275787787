import React from "react";
import styled from "styled-components";
import { RED_COLOR, PRIMARY_FONT } from "../../utils/theme";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { AppInput } from ".";
import "react-day-picker/lib/style.css";
import { DayPickerInputProps } from "react-day-picker/types/Props";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/format";

interface AppDatePickerProps {
  date?: Date;
  title?: string;
  width?: number;
  error?: string;
}

const AppDatePicker = ({ title, width, error, ...props }: AppDatePickerProps & DayPickerInputProps) => {
  return (
    <Div>
      <DayPickerInput
        {...props}
        format={DATE_FORMAT}
        formatDate={(date, format) => {
          console.log('datepicker date: ', date, format);
          return date ? moment(date).format(format) : undefined;
        }}
        value={props.value ? moment(props.value).format(DATE_FORMAT) : undefined}
        component={(props) => <AppInput title={!!title ? title.toUpperCase() : undefined} width={width} {...props} />}
      />
      {!!error ? <InputErrorText>{error}</InputErrorText> : <InputErrorText>&nbsp;</InputErrorText>}
    </Div>
  );
};

const Div = styled.div`
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const InputErrorText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-size: 12px;
  letter-spacing: 2px;
  color: ${RED_COLOR};
  margin: 0;
`;

export { AppDatePicker };
