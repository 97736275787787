/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FundingRoundModel, FundingRoundModelType } from "./FundingRoundModel"
import { FundingRoundModelSelector } from "./FundingRoundModel.base"
import { RootStoreType } from "./index"


/**
 * FundingRoundCoinvestorBase
 * auto generated base class for the model FundingRoundCoinvestorModel.
 */
export const FundingRoundCoinvestorModelBase = ModelBase
  .named('FundingRoundCoinvestor')
  .props({
    __typename: types.optional(types.literal("FundingRoundCoinvestor"), "FundingRoundCoinvestor"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.null, types.string),
    url: types.union(types.undefined, types.null, types.string),
    funding_round: types.union(types.undefined, types.null, types.late((): any => FundingRoundModel)),
    funding_round_id: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class FundingRoundCoinvestorModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get url() { return this.__attr(`url`) }
  get funding_round_id() { return this.__attr(`funding_round_id`) }
  funding_round(builder?: string | FundingRoundModelSelector | ((selector: FundingRoundModelSelector) => FundingRoundModelSelector)) { return this.__child(`funding_round`, FundingRoundModelSelector, builder) }
}
export function selectFromFundingRoundCoinvestor() {
  return new FundingRoundCoinvestorModelSelector()
}

export const fundingRoundCoinvestorModelPrimitives = selectFromFundingRoundCoinvestor().name.url.funding_round_id
