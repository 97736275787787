import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Modal, CloseModal, ModalWrapper, AppTitle, AppButton, AnimatedText, Loading, AppErrorText } from "../UI";
import { AiOutlineClose } from "react-icons/ai";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { FormInputField, FormSelectField, FormDatePicker, FormAreaInputField, ImageUploadField } from "../Forms";
import { TrashIcon } from "../Dumb";
import { Coinvestors } from "../../types";
import { observer } from "mobx-react";
import { useQuery } from "../../models/reactUtils";
import { toast } from "react-toastify";

interface RoundModalProps {
  showModal: boolean;
  toggleModal: () => void;
  /**
   * Company id for the new fund
   */
  id: number;
}

interface MyFormikProps {
  round_number: number;
  stage: string;
  total_investment: number;
  base_10_investment: number;
  date?: Date | null;
  ownership: number;
  valuation: number;
  coinvestors: Coinvestors[];
  footnote: string;
}

const RoundSchema = Yup.object().shape({
  stage: Yup.string().required("* Please select a stage").notOneOf(["0"], "* Please select a Stage"),
  round_number: Yup.number()
    .integer("* Please choose a whole number")
    .required("* Please select a round number")
    .min(0, "* Please choose a positive integer")
    .notOneOf(["0"], "* Please choose a positive integer"),
});

const RoundModalComponent: FunctionComponent<RoundModalProps> = ({ showModal, toggleModal, id }) => {
  const { setQuery, loading, error, store } = useQuery();
  const { data: stageData, loading: stageLoading, error: stageError } = useQuery((store) =>
    store.queryStages({}, (qb) => qb.id.name),
  );
  if (!showModal) {
    return null;
  }
  if (stageLoading) {
    return <Loading />;
  }
  if (stageError) {
    return <p>Error fetching Stages</p>;
  }
  const stage_list = stageData.stages.map((s) => ({
    label: s.name,
    value: s.name,
  }));
  return (
    <Modal width="fit-content" showModal={showModal} toggleModal={toggleModal}>
      <CloseModal onClick={toggleModal}>
        <AiOutlineClose size={20} color={"black"} />
      </CloseModal>
      <RoundContainer>
        <ModalWrapper>
          <Formik
            initialValues={{
              stage: "0",
              round_number: undefined,
              total_investment: undefined,
              base_10_investment: undefined,
              ownership: undefined,
              valuation: undefined,
              date: null,
              coinvestors: [],
              footnote: "",
            }}
            validationSchema={RoundSchema}
            onSubmit={async (values) => {
              console.log("values: ", values);
              const mutation = store.mutateCreateOneFundingRound(
                {
                  data: {
                    base10_invest: values.base_10_investment,
                    footnote: values.footnote,
                    funding_date: values.date,
                    ownership: values.ownership,
                    valuation: values.valuation,
                    stage: values.stage,
                    total_investment: values.total_investment,
                    round_number: values.round_number,
                    coinvestors: {
                      create: values.coinvestors.filter((c) => !!c.name).map((c) => ({ name: c.name, url: c.url })),
                    },
                    company: {
                      connect: {
                        id,
                      },
                    },
                  },
                },
                (qb) =>
                  qb.base10_invest.round_number.footnote.funding_date.id.ownership.valuation.stage.total_investment.company_id.coinvestors(
                    (b) => b.id.name.url,
                  ),
              );
              mutation.then(
                (v) => {
                  console.log("return params from add fund", v);
                  store.companies
                    .filter((c) => c.id === id)[0]
                    .localAddFund({
                      ...v.createOneFundingRound,
                    });
                  toast("Round created!");
                  toggleModal();
                },
                (e) => {
                  toast("Could not add sector!");
                },
              );
              setQuery(mutation);
            }}
          >
            {({ submitForm, values }: FormikProps<MyFormikProps>) => {
              console.log("values", values);
              return (
                <FormContainer>
                  <TitleContainer>
                    <Title>Add round</Title>
                  </TitleContainer>
                  <div>
                    <Split>
                      <FormSelectField
                        name={`stage`}
                        title="STAGE"
                        placeholder="Choose stage"
                        width={330}
                        fontSize={20}
                        options={stage_list}
                        type="number"
                      />
                      <FormInputField
                        width={170}
                        title="TOTAL INVEST ($M)"
                        name={`total_investment`}
                        fontSize={20}
                        placeholder="0.0"
                        type="number"
                        noTopMargin
                      />
                      <FormInputField
                        width={170}
                        title="BASE10 INVEST ($M)"
                        name={`base_10_investment`}
                        fontSize={20}
                        placeholder="0.0"
                        noTopMargin
                        type="number"
                      />
                    </Split>
                    <Split
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      <FormDatePicker name={`date`} title="FUNDING DATE" width={210}  />
                      <FormInputField
                        width={80}
                        title="ROUND #"
                        name={`round_number`}
                        fontSize={20}
                        style={{ marginRight: 30 }}
                        placeholder="1"
                        noTopMargin
                        type="number"
                      />
                      <Split>
                        <FormInputField
                          style={{
                            gridColumn: "1 / span 1",
                            gridRow: "1 / span 1",
                            marginRight: 30,
                          }}
                          width={170}
                          title="OWNERSHIP (%)"
                          value={values.ownership}
                          step={0.1}
                          name={`ownership`}
                          fontSize={20}
                          placeholder="0"
                          type="number"
                          noTopMargin
                        />
                        <FormInputField
                          style={{
                            gridColumn: "2 / span 1",
                            gridRow: "1 / span 1",
                            margin: 0,
                          }}
                          width={170}
                          title="VALUATION ($M)"
                          name={`valuation`}
                          fontSize={20}
                          placeholder="0.0"
                          type="number"
                          noTopMargin
                        />
                      </Split>
                    </Split>
                    <FieldArray name={`coinvestors`}>
                      {({ insert, remove, name }) => (
                        <FieldArrayContainer>
                          {values.coinvestors.map((co, index) => {
                            return (
                              <Split key={co.id}>
                                <SplitContainer>
                                  <Split>
                                    <ImageUploadField
                                      name={`coinvestors[${index}].url`}
                                      title="LOGO"
                                      style={{
                                        marginRight: 30,
                                      }}
                                    />

                                    <FormInputField
                                      width={330}
                                      name={`coinvestors[${index}].name`}
                                      fontSize={20}
                                      placeholder="Co-Investor Name"
                                    />
                                  </Split>
                                </SplitContainer>
                                <TrashIcon onClick={() => remove(index)} />
                              </Split>
                            );
                          })}
                          <AddTextContainer>
                            <AnimatedText
                              onClick={() =>
                                insert(values.coinvestors.length, {
                                  name: "",
                                  logo: "",
                                  id: 0,
                                })
                              }
                            >
                              Add co-investor
                            </AnimatedText>
                          </AddTextContainer>
                        </FieldArrayContainer>
                      )}
                    </FieldArray>
                    <FormAreaInputField name={"footnote"} title="FOOTNOTE" fontSize={20} placeholder="Description" />
                  </div>
                  <SubmitContainer>
                    {loading ? <Loading /> : <StyledAppButton onClick={submitForm}>Save Changes</StyledAppButton>}
                    {error && <AppErrorText>{error}</AppErrorText>}
                  </SubmitContainer>
                </FormContainer>
              );
            }}
          </Formik>
        </ModalWrapper>
      </RoundContainer>
    </Modal>
  );
};

const RoundContainer = styled.div`
  width: 940px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled(AppTitle)`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 5px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 510px;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RoundModal = observer(RoundModalComponent);

export { RoundModal };
