/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * RealEconomySectorBase
 * auto generated base class for the model RealEconomySectorModel.
 */
export const RealEconomySectorModelBase = ModelBase
  .named('RealEconomySector')
  .props({
    __typename: types.optional(types.literal("RealEconomySector"), "RealEconomySector"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    selectable: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class RealEconomySectorModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get selectable() { return this.__attr(`selectable`) }
}
export function selectFromRealEconomySector() {
  return new RealEconomySectorModelSelector()
}

export const realEconomySectorModelPrimitives = selectFromRealEconomySector().name.selectable
