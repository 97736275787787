/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CompanyModel, CompanyModelType } from "./CompanyModel"
import { CompanyModelSelector } from "./CompanyModel.base"
import { RootStoreType } from "./index"


/**
 * CompetitorBase
 * auto generated base class for the model CompetitorModel.
 */
export const CompetitorModelBase = ModelBase
  .named('Competitor')
  .props({
    __typename: types.optional(types.literal("Competitor"), "Competitor"),
    id: types.union(types.undefined, types.integer),
    name: types.union(types.undefined, types.string),
    company_id: types.union(types.undefined, types.null, types.integer),
    company: types.union(types.undefined, types.null, types.late((): any => CompanyModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CompetitorModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get company_id() { return this.__attr(`company_id`) }
  company(builder?: string | CompanyModelSelector | ((selector: CompanyModelSelector) => CompanyModelSelector)) { return this.__child(`company`, CompanyModelSelector, builder) }
}
export function selectFromCompetitor() {
  return new CompetitorModelSelector()
}

export const competitorModelPrimitives = selectFromCompetitor().name.company_id
