import styled from "styled-components";
import { mobileSize, laptopSize } from "../../utils/breakpoints";
import { PRIMARY_FONT } from "../../utils/theme";

export const PanelContainer = styled.div`
  position: relative;
  width: calc(100% - 60px);
  float: left;
  margin-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 30px;
  @media ${mobileSize} {
    width: calc(100% - 60px);
    float: left;
    margin-left: 20px;
  }
`;

export const HalfPanelContainer = styled.div<{
  halfWidth?: boolean;
}>`
  position: relative;
  width: ${(props) => (props.halfWidth ? "calc(50% - 45px)" : "100%")};
  height: 100%;
  float: left;
  // margin-left: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // margin-top: 30px;
  @media ${laptopSize} {
    width: calc(100% - 60px);
    float: left;
    // margin-left: 30px;
  }
  @media ${mobileSize} {
    width: calc(100% - 60px);
    float: left;
    // margin-left: 20px;
    // margin-top: 20px;
  }
`;

export const PanelWrapper = styled.div`
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  @media ${mobileSize} {
    padding: 20px;
  }
`;

export const PanelContent = styled.div`
  margin-top: 20px;
  display: block;
`;

export const PanelContentTitle = styled.h3`
  font-family: ${PRIMARY_FONT};
  border-bottom: 2px solid rgba(26, 26, 26, 0.1);
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 7px;
  font-weight: 400;
  font-size: 18px;
`;

export const PanelFooter = styled.div`
  font-family: ${PRIMARY_FONT};
  margin-top: 28px;
  margin-bottom: -3px;
  font-size: 12px;
  line-height: 1.16667;
  letter-spacing: 2px;
  line-height: 1.5;
  letter-spacing: 0;
  color: rgba(26, 26, 26, 0.4);
  text-align: right;
  @media ${mobileSize} {
    font-size: 12px;
    line-height: 1.16667;
    letter-spacing: 1px;
  }
`;
