import * as React from "react";
import * as FontIcon from "react-icons/fa";

interface IconPickerItemProps {
  icon: string;
  size?: number;
  color?: string;
  onClick?: (icon: string) => void;
}

const IconPickerItem: React.SFC<IconPickerItemProps> = ({ icon, size, color, onClick }) => {
  const iconDiv = !!icon ? React.createElement(FontIcon[icon]) : <div />;
  return (
    <div onClick={() => onClick(icon)} style={{ fontSize: size, color: color, padding: 2 }}>
      {iconDiv}
    </div>
  );
};

IconPickerItem.defaultProps = {
  color: "#000",
  size: 24,
  onClick: (_: string) => null,
};

export { IconPickerItem };
