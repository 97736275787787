import React from "react";
import { useField, useFormikContext } from "formik";
import { AppDatePicker } from "../UI";

interface FormDatePickerProps {
  name: string;
  style?: React.CSSProperties;
  title?: string;
  type?: string;
  width?: number;
}

const FormDatePicker = ({ style, ...props }: FormDatePickerProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      <AppDatePicker
        {...field}
        {...props}
        date={field.value}
        error={meta.touched && meta.error}
        onDayChange={(day) => setFieldValue(field.name, day)}
        
      />
    </div>
  );
};

export { FormDatePicker };
