import React, { useState } from "react";
import styled from "styled-components";
import { AppButton, Loading, AppErrorText } from "../UI";
import { Formik, FormikValues, FormikHelpers, Form } from "formik";

interface FormikChildrenProps {
  onSubmit: () => void;
  validationSchema: Record<string, unknown>;
}

const MultiStepFormContainer = ({
  children,
  initialValues,
  onSubmit,
  loading,
  error,
  formikError,
}: {
  // children: FormikChildrenProps;
  children: any & FormikChildrenProps;
  initialValues: FormikValues;
  onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>;
  loading?: boolean;
  error?: string;
  formikError?: string;
}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const pages = React.Children.toArray(children) as any; //Need proper typescript definition
  const [snapshot, setSnapshot] = useState(initialValues);

  const page = pages[pageNumber];
  const totalPages = pages.length;
  const isLastPage = pageNumber === totalPages - 1;

  const next = (values: FormikValues) => {
    setSnapshot(values);
    setPageNumber(Math.min(pageNumber + 1, totalPages - 1));
  };

  // const previous = (values: FormikValues) => {
  //   setSnapshot(values);
  //   setPageNumber(Math.max(pageNumber - 1, 0));
  // };

  const handleSubmit = async (values, bag) => {
    if (page.props.onSubmit) {
      await page.props.onSubmit(values, bag);
    }
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik initialValues={snapshot} onSubmit={handleSubmit} validationSchema={page.props.validationSchema}>
      {({ errors }) => {
        return (
          <Form>
            {page}
            <SubmitContainer>
              {loading ? (
                <Loading />
              ) : (
                <StyledAppButton type="submit">{isLastPage ? "Save Changes" : "Next Step"}</StyledAppButton>
              )}
              {!!Object.keys(errors).length && <AppErrorText>Check Validation errors above</AppErrorText>}
              {error && <AppErrorText>{error}</AppErrorText>}
            </SubmitContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export { MultiStepFormContainer };

const StyledAppButton = styled(AppButton)`
  width: 100%;
  height: 70px;
  color: #ffffff;
`;

const SubmitContainer = styled.div`
  width: 100%;
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
