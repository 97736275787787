import * as React from "react";
import styled from "styled-components";
import { AppPanelTitle, Loading, AppText } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelFooter, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { mobileSize } from "../../../utils/breakpoints";

import { PRIMARY_FONT, PRIMARY_COLOR, DARK_BLUE_COLOR } from "../../../utils/theme";
import { useQuery } from "../../../models/reactUtils";
import { observer } from "mobx-react";
import { IconPickerItem } from "../../Dumb/IconPicker";

interface CapitalDeployedByIndustryProps {
  fund_id: number;
}

const CapitalDeployedByIndustryPanel: FunctionComponent<CapitalDeployedByIndustryProps> = ({ fund_id }) => {
  const { store } = useQuery();

  const { data: companyData, loading: companyLoading, error: companyError, setQuery: companySetQuery } = useQuery(
    (store) =>
      store.queryFetchCapitalDeployedByIndustryGraph(
        { fundId: fund_id },

        (qb) => qb.industry((i) => i.amount.icon.industry_string),
      ),
  );

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund({ where: { id: fund_id } }, (qb) => qb.capital_deployed_by_industry_footnote),
  );

  useEffect(() => {
    const query = store.queryFetchCapitalDeployedByIndustryGraph(
      { fundId: fund_id },

      (qb) => qb.industry((i) => i.amount.icon.industry_string),
    );
    companySetQuery(query);

    const query2 = store.queryFund({ where: { id: fund_id } }, (qb) => qb.capital_deployed_by_industry_footnote);
    fundQuery(query2);
  }, [fund_id]);

  if (companyLoading || fundLoading) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (
    companyError ||
    fundError ||
    !fundData ||
    !fundData?.fund ||
    !companyData ||
    !companyData.fetchCapitalDeployedByIndustryGraph
  ) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <p>Error fetching data...</p>
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  const {
    fund: { capital_deployed_by_industry_footnote },
  } = fundData;

  if (companyData?.fetchCapitalDeployedByIndustryGraph?.industry?.length === 0) {
    return null;
  }

  return (
    <HalfPanelContainer>
      <PanelWrapper>
        <AppPanelTitle>Capital Deployed by Trend ($M)</AppPanelTitle>
        <ThisPanelContent>
          <IndustriesContainer>
            <IndustriesList>
              {companyData.fetchCapitalDeployedByIndustryGraph.industry
                .filter((i: any) => !!i.amount)
                .map((i: any, index) => (
                  <IndustriesListItem key={i.industry_string}>
                    <IndustriesListItemContainer>
                      <IndustriesListItemIconContainer>
                        <IconPickerItem
                          icon={i.icon}
                          size={25}
                          color={index % 3 === 1 ? PRIMARY_COLOR : index % 3 === 2 ? DARK_BLUE_COLOR : "black"}
                        />
                      </IndustriesListItemIconContainer>
                      <IndustriesListItemContentValue>
                        {`$${Math.round(i.amount)}`}
                        <IndustriesListItemContentLabel>{i.industry_string}</IndustriesListItemContentLabel>
                      </IndustriesListItemContentValue>
                    </IndustriesListItemContainer>
                  </IndustriesListItem>
                ))}
            </IndustriesList>
          </IndustriesContainer>
        </ThisPanelContent>
        {Boolean(capital_deployed_by_industry_footnote) && (
          <PanelFooter>{capital_deployed_by_industry_footnote}</PanelFooter>
        )}
      </PanelWrapper>
    </HalfPanelContainer>
  );
};

const ThisPanelContent = styled(PanelContent)`
  max-height: 500px;
  overflow-y: auto;
`;

const IndustriesContainer = styled.div`
  /* display: block; */
  margin-bottom: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  :hover {
    overflow-y: auto;
  }
  @media ${mobileSize} {
    margin-top: 20px;
    max-height: 250px;
  }
`;

const IndustriesList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  column-count: 2;
  min-height: 400px;
  @media ${mobileSize} {
    width: 100%;
  }
`;

const IndustriesListItem = styled.li`
  margin-top: 13px;
  width: 50%;
  overflow-x: auto;
  @media ${mobileSize} {
  }
`;

const IndustriesListItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;

const IndustriesListItemIconContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  padding-right: 15px;
  font-size: 30px;
  text-align: center;
  padding-top: 6px;
  padding-right: 10px;
  @media screen {
    padding-top: 6px;
    padding-right: 10px;
    font-size: 20px;
  }
`;

const IndustriesListItemContentValue = styled(AppText)`
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  font-size: 20px;
  line-height: 1.28571;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  flex-direction: column;
  align-items: flex-start;
  @media ${mobileSize} {
    font-size: 20px;
    line-height: 1.41667;
    letter-spacing: 0;
    width: 200px;
  }
`;

const IndustriesListItemContentLabel = styled(AppText)`
  font-family: ${PRIMARY_FONT};
  margin-top: 1px;
  display: inline-block;
  max-width: 100%;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 14px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: rgba(26, 26, 26, 0.6);
  margin-left: 10px;
  margin-left: 0;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    margin-left: 0;
  }
`;

const CapitalDeployedByIndustryComponent = observer(CapitalDeployedByIndustryPanel);

export { CapitalDeployedByIndustryComponent };
