import * as React from "react";
import styled from "styled-components";
import { useQuery } from "../../../models/reactUtils";
import { UrlType } from "../../../models";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Loading, AnimatedText, AppFormTitle, AppButton } from "../../UI";
import { Formik, FormikProps, FieldArray } from "formik";
import { FormInputField, FormSelectField, StaticTextField } from "../../Forms";
import { TrashIcon } from "../../Dumb";
import { observer } from "mobx-react";
import { RED_COLOR, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR, OFF_WHITE_BACKGROUND } from "../../../utils/theme";
import { toast } from "react-toastify";
import { DataRoomItemModal, DataRoomTopicModal } from "../../Modals";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface IDataRoomProps {
  fund_id: number;
}

interface DataRoomItem {
  id: number;
  ordinal: number;
  label: string;
  url: string;
  type: UrlType;
  modal: boolean;
}

interface DataRoomTopic {
  id: number;
  ordinal: number;
  topic_name: string;
  items: DataRoomItem[];
  show: boolean;
  modal: boolean;
  addItemModal: boolean;
}

interface MyFormikProps {
  topics: DataRoomTopic[];
}

const DataRoomHBCUComponent: React.FunctionComponent<IDataRoomProps> = ({ fund_id }) => {
  const [createTopic, setCreateTopic] = useState(false);
  const { store, setQuery } = useQuery();
  const { data, loading, error, setQuery: drSetQuery } = useQuery((store) =>
    store.queryDataRoomTopics({ where: { hbcu: { equals: true } } }, (qb) =>
      qb.id.topic_name.ordinal.items((b) => b.id.ordinal.label.type.url),
    ),
  );
  useEffect(() => {
    const query = store.queryDataRoomTopics({ where: { hbcu: { equals: true } } }, (qb) =>
      qb.id.ordinal.topic_name.items((b) => b.id.ordinal.label.type.url),
    );
    drSetQuery(query);
  }, [fund_id]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error fetching Data Room</p>;
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? PRIMARY_COLOR : "transparent",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <Div>
      <Formik
        initialValues={{
          topics: data.dataRoomTopics.map(({ __typename, fund, fund_id, ...d }) => ({
            ...d,
            show: false,
            modal: false,
            addItemModal: false,
            items: d.items.map(({ __typename, topic, topic_id, ...i }) => ({ ...i, modal: false })),
          })),
        }}
        onSubmit={async (values) => {
          console.log("form submitted: ", values);
        }}
      >
        {({ values, setFieldValue }: FormikProps<MyFormikProps>) => {
          return (
            <FormContainer>
              <FieldArray name="topics">
                {({ insert, remove, name }) => (
                  <DragDropContext
                    onDragEnd={(result) => {
                      console.log("result: ", result);
                      // dropped outside the list
                      if (!result.destination) {
                        return;
                      }

                      const topics = reorder(values.topics, result.source.index, result.destination.index);

                      setFieldValue("topics", topics);
                      // Update backend with new values
                      // I need to send new order of topics to the backend
                      const new_topic_order = topics.map((t: any) => t.id);
                      const mutation = store.mutateSortDataroomTopicOrdinal({ objectIds: new_topic_order });
                      mutation.then((v) => {
                        toast("New Topic order saved!");
                      });
                      setQuery(mutation);
                    }}
                  >
                    <Droppable droppableId={`topics`}>
                      {(provided, snapshot) => (
                        <FieldArrayContainer
                          {...provided.droppableProps}
                          style={getListStyle(snapshot.isDraggingOver)}
                          ref={provided.innerRef}
                        >
                          {values.topics
                            .sort((a, b) => a.ordinal - b.ordinal)
                            .map((topic, i) => (
                              <div key={topic.id}>
                                <Draggable key={topic.id} draggableId={`${topic.id}`} index={i}>
                                  {(provided, snapshot) => (
                                    <Split
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <StaticTextField name={`topics[${i}].topic_name`} title="Topic Name" />
                                      {values.topics[i].modal && (
                                        <DataRoomTopicModal
                                          hbcu
                                          fund_id={fund_id}
                                          modal_type="edit"
                                          showModal={values.topics[i].modal}
                                          toggleModal={() => setFieldValue(`topics[${i}].modal`, false)}
                                          onComplete={({ id, topic_name }) => {
                                            setFieldValue(`.topics[${i}].topic_name`, topic_name);
                                          }}
                                          onDelete={(id) => remove(i)}
                                          {...values.topics[i]}
                                        />
                                      )}
                                      <div>
                                        <AppButton
                                          secondary
                                          width={150}
                                          onClick={() => setFieldValue(`topics[${i}].show`, !values.topics[i].show)}
                                        >
                                          {values.topics[i].show ? "Hide Items" : "Show Items"}{" "}
                                        </AppButton>
                                        <AppButton
                                          width={150}
                                          style={{ marginLeft: 15 }}
                                          onClick={() => setFieldValue(`topics[${i}].modal`, true)}
                                        >
                                          Edit Topic
                                        </AppButton>
                                      </div>
                                    </Split>
                                  )}
                                </Draggable>
                                <FieldArray name={`topics[${i}].items`}>
                                  {({ insert, remove, name }) => (
                                    <DragDropContext
                                      onDragEnd={(result) => {
                                        console.log("result: ", result);
                                        // dropped outside the list
                                        if (!result.destination) {
                                          return;
                                        }

                                        const items = reorder(
                                          values.topics[i].items,
                                          result.source.index,
                                          result.destination.index,
                                        );

                                        setFieldValue(`topics[${i}].items`, items);

                                        const new_item_order = items.map((i: any) => i.id);
                                        const mutation = store.mutateSortDataroomItemOrdinal({
                                          objectIds: new_item_order,
                                        });
                                        mutation.then((v) => {
                                          toast("New Item order saved!");
                                        });
                                        setQuery(mutation);
                                      }}
                                    >
                                      <Droppable droppableId={`topic_${i}`}>
                                        {(provided, snapshot) => (
                                          <FieldArrayContainer
                                            {...provided.droppableProps}
                                            style={{
                                              ...getListStyle(snapshot.isDraggingOver),
                                              display: values.topics[i].show ? "initial" : "none",
                                            }}
                                            ref={provided.innerRef}
                                          >
                                            {values.topics[i].items
                                              .sort((a, b) => a.ordinal - b.ordinal)
                                              .map((item, itemIndex) => {
                                                return (
                                                  <div
                                                    key={item.id}
                                                    style={{
                                                      backgroundColor:
                                                        itemIndex % 2 === 0 ? OFF_WHITE_BACKGROUND : "#fff",
                                                      marginLeft: 25,
                                                      marginTop: 10,
                                                      padding: 5,
                                                    }}
                                                  >
                                                    <Draggable
                                                      key={item.id}
                                                      draggableId={`${item.id}`}
                                                      index={itemIndex}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <Split
                                                          ref={provided.innerRef}
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style,
                                                          )}
                                                        >
                                                          <MembersContainer>
                                                            <Split>
                                                              <StaticTextField
                                                                fontSize={20}
                                                                name={`topics[${i}].items[${itemIndex}].label`}
                                                                title="Label"
                                                              />
                                                              <StaticTextField
                                                                fontSize={20}
                                                                name={`topics[${i}].items[${itemIndex}].type`}
                                                                title="Item Type"
                                                              />
                                                            </Split>
                                                            <StaticTextField
                                                              fontSize={20}
                                                              name={`topics[${i}].items[${itemIndex}].url`}
                                                              title="Item URL"
                                                            />
                                                          </MembersContainer>
                                                          <AppButton
                                                            width={150}
                                                            style={{ marginLeft: 15 }}
                                                            onClick={() =>
                                                              setFieldValue(
                                                                `topics[${i}].items[${itemIndex}].modal`,
                                                                true,
                                                              )
                                                            }
                                                          >
                                                            Edit Item
                                                          </AppButton>
                                                        </Split>
                                                      )}
                                                    </Draggable>
                                                    {values.topics[i].items[itemIndex].modal && (
                                                      <DataRoomItemModal
                                                        modal_type="edit"
                                                        hbcu
                                                        showModal={values.topics[i].items[itemIndex].modal}
                                                        toggleModal={() =>
                                                          setFieldValue(`topics[${i}].items[${itemIndex}].modal`, false)
                                                        }
                                                        topic_id={values.topics[i].id}
                                                        onComplete={({ id, label, type, url }) => {
                                                          setFieldValue(
                                                            `topics[${i}].items[${itemIndex}].label`,
                                                            label,
                                                          );
                                                          setFieldValue(`topics[${i}].items[${itemIndex}].type`, type);
                                                          setFieldValue(`topics[${i}].items[${itemIndex}].url`, url);
                                                        }}
                                                        onDelete={(id) => {
                                                          console.log("id: ", id);
                                                          console.log("itemIndex: ", itemIndex);
                                                          remove(itemIndex);
                                                        }}
                                                        {...values.topics[i].items[itemIndex]}
                                                      />
                                                    )}
                                                  </div>
                                                );
                                              })}
                                            {values.topics[i].addItemModal && (
                                              <DataRoomItemModal
                                                modal_type="add"
                                                hbcu
                                                showModal={values.topics[i].addItemModal}
                                                toggleModal={() => setFieldValue(`topics[${i}].addItemModal`, false)}
                                                topic_id={values.topics[i].id}
                                                onComplete={({ id, label, type, url }) => {
                                                  insert(values.topics[i].items.length, {
                                                    id,
                                                    label,
                                                    type,
                                                    url,
                                                  });
                                                }}
                                              />
                                            )}
                                            <AddTextContainer>
                                              <AnimatedText
                                                onClick={() => setFieldValue(`topics[${i}].addItemModal`, true)}
                                              >
                                                Add Item
                                              </AnimatedText>
                                            </AddTextContainer>
                                          </FieldArrayContainer>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  )}
                                </FieldArray>
                              </div>
                            ))}
                          {createTopic && (
                            <DataRoomTopicModal
                              modal_type="add"
                              fund_id={fund_id}
                              hbcu
                              showModal={createTopic}
                              toggleModal={() => setCreateTopic(false)}
                              onComplete={({ id, topic_name }) => {
                                insert(values.topics.length, {
                                  id,
                                  topic_name,
                                  items: [],
                                  show: true,
                                });
                              }}
                            />
                          )}
                          <AddTextContainer>
                            <AnimatedText onClick={() => setCreateTopic(true)}>Add Topic</AnimatedText>
                          </AddTextContainer>
                        </FieldArrayContainer>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </FieldArray>
            </FormContainer>
          );
        }}
      </Formik>
    </Div>
  );
};

const Div = styled.div`
  margin-bottom: 50px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
`;

const FieldArrayContainer = styled.div`
  width: 100%;
`;

const AddTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: flex-end;
`;

const DataRoomHBCU = observer(DataRoomHBCUComponent);

export { DataRoomHBCU };
